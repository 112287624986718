import { systemThemes } from '@/config/constants/themesConstant';
import themes from 'devextreme/ui/themes';
import { Theme } from '@/model/system/themes/theme';
import { ContextsState } from '../store';
import { ModuleTypeEnum } from '@/model';

const DARK_THEME_ID = 0;
const LIGHT_THEME_ID = 1;

export function getCurrentTheme() {
  let themeId = ContextsState.currentContext?.themeId;
  if (themeId == undefined || themeId > 1) {
    themeId = LIGHT_THEME_ID;
  }
  return systemThemes.find((t) => t.id === themeId) ?? systemThemes[LIGHT_THEME_ID];
}

export function getCurrentThemeIsDark() {
  return getCurrentTheme().id === DARK_THEME_ID;
}

export function getThemeColor(name: string) {
  return getCurrentTheme().colors?.find((color) => color.name === name)?.color;
}

export function setTheme() {
  const currentTheme = getCurrentTheme();

  if (currentTheme) {
    themes.current(currentTheme.systemName);
  } else {
    themes.current(systemThemes[DARK_THEME_ID].systemName);
  }
}

export async function saveContextTheme(theme: Theme | null) {
  if (theme) {
    await ContextsState.changeContextTheme({ id: ContextsState.currentContext!.id!, themeId: theme.id });

    setTheme();
  }
}

/**
 * Объект, содержащий импортированное изображение, null или undefined.
 * Добавляем через require('путь-к-изображению').
 */
export function getBackgroundImage(moduleType: ModuleTypeEnum | null) {
  const theme = getCurrentTheme();

  return theme.blockBackgrounds?.find((background) => background.types.some((type) => type === moduleType!))?.image;
}

export function getBlockStyle(moduleType: ModuleTypeEnum | null): string | undefined {
  let background = '';
  const theme = getCurrentTheme();
  if (!moduleType) {
    return;
  }

  const image = getBackgroundImage(moduleType);

  if (image) {
    background = `background-image: url(${image});
                    background-size: cover;`;
  }
  // else {
  //   if (image === null) {
  //     background = `background-color: #ffffff00; padding: 0px !important;`;
  //   } else {
  //     background = `background-color: ${getThemeColor('defaultBlockBackgroundColor')};`;
  //   }
  // }
  const textColor = `color: ${getThemeColor('textColor')};`;
  const corners = theme.blockRoundedCorners ? 'border-radius: 20px;' : '';
  const style = [background, textColor, corners].join('\n');

  return style;
}

import { DataRound } from '@/model';

/**
 * Precisoin for any float number to avoid javascript float number error,
 * like 556 * 1.7 -> 945.1999999999999 -> float round -> 945.2
 */
export const PRECISION_FLOAT = 12;

/**
 * Rounds float number to the specified precision.
 * Rounds to float avoiding EPSILON-error by default.
 */
export function roundNumber(value: number, precision = PRECISION_FLOAT): number {
  // NOTE: See https://stackoverflow.com/a/41716722. lodash/round can be used instead.
  const multiplier = Math.pow(10, precision);
  const result = Math.round((value + Number.EPSILON) * multiplier) / multiplier;
  return result;
}

export function isNumber(value: number | null | undefined): boolean {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function isNoZeroNumber(value: number | null | undefined): boolean {
  return value !== 0 && typeof value === 'number' && !Number.isNaN(value);
}

export function doIfNumber<TOut1, TOut2>(
  value: number | null | undefined,
  doThen: (value: number) => TOut1,
  elseValue: TOut2
): TOut1 | TOut2 {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return doThen(value);
  }
  return elseValue;
}

export function getSumOrNull(values: (number | null | undefined)[]): number | null {
  const filtered = values.filter(isNumber);
  if (filtered.length > 0) {
    const sum = filtered.reduce((a, b) => a! + b!, 0);
    return sum!;
  }

  return null;
}

export function isEven(value: number) {
  return value % 2 === 0;
}

/**
 * Calculates degrees measure from radians
 * @param value Measure in radians
 * @returns Measure in degrees
 */
export function toDeg(value: number) {
  return value * (180 / Math.PI);
}

/**
 * Calculates radians measure from degrees
 * @param value Measure in degrees
 * @returns Measure in radians
 */
export function toRad(value: number) {
  return value * (Math.PI / 180);
}

/**
 * Функция для форматирования числового значения в строку по разрядам.
 * @param {number | null} value Число для форматирования.
 * @param {DataRound | undefined} round Способ округления.
 * @param {string | undefined} locale Локаль - по умолчанию undefined = пользовательская.
 * @returns {string | undefined} Строка с отформатированным числом.
 * @example formatNumber(1000000.56) => // "1 000 000.56"
 */
export function formatNumber(value: number | null = null, round?: DataRound, locale = 'ru-RU') {
  if (!isNumber(value)) {
    return undefined;
  }

  if (round && !Number.isInteger(value)) {
    let minDigits = 0;

    switch (round) {
      case DataRound.whole: {
        // До целых
        minDigits = 0;
        break;
      }
      case DataRound.dozens: {
        // До десятых
        minDigits = 1;
        break;
      }
      case DataRound.hundreds: {
        // До сотых
        minDigits = 2;
        break;
      }
      case DataRound.thousands: {
        // До тысячных
        minDigits = 3;
        break;
      }
    }

    return value!.toLocaleString(locale, { minimumFractionDigits: minDigits });
  }

  // Неокруглённое число знаков после запятой.
  const minimumFractionDigits = value!.toString().split('.')[1]?.length;
  return value!.toLocaleString(locale, { minimumFractionDigits });
}

import { ThemeColorsBase } from '@/plugins/types';

export const base: ThemeColorsBase = {
  background: '#161616',
  surface: '#212121',
  error: '#F44336',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FB8C00',
  'secondary-text': '#858585',
  'secondary-sheet': '#2E2E2E',
  'secondary-sheet-darken': '#282929',
  'card-darken': '#272727',
  'input-background': '#414141',
  'secondary-background-transparent': '#FFFFFF',
  'secondary-divider-transparent': '#000',
  'primary-text-transparent': '#212121',
};

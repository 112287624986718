import { EventToLog, IModuleConfig, LogEventActionType, LogEventDataKind, Panel, PanelDto, PanelLayout } from '@/model';
import {
  checkPanelsSort,
  dashboardsStateToDashboards,
  dashboardToDashboardsState,
  getInitialLastPanelId,
  getLastUsedPanelId,
  panelFromDto,
  panelToDto,
  setLastUsedPanelId,
} from '@/helpers';
import api from '@/services/api';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ContextsState, UserState } from '.';

interface ItemConfig {
  id: string;
  config: IModuleConfig;
}

@Module({ name: 'panels', namespaced: true })
export default class PanelsModule extends VuexModule {
  private _loading = true;
  private _panels: Panel[] = [];
  private _currentPanel: Panel | null = null;
  private _currentLayout: PanelLayout | null = null;
  private _editMode = false;
  private _isMobile = false;
  /** Панели, которые должны быть дополнительно видны на мобильном, т.к. переход по прямой ссылке */
  private _directPanelIds: number[] = [];

  public get panels(): Panel[] {
    return (
      this._isMobile
        ? this._panels.filter((panel) => dashboardsStateToDashboards(panel).isMobile || this._directPanelIds.some(id => id === panel.id))
        : this._panels
    ) ?? [];
  }

  @Action({ rawError: true })
  public tryUseDirectPanel(panelId: number): Panel | null {
    const panel = this._panels.find(x => x.id === panelId);
    if (panel) {
      this.setDirectPanelIds([...this._directPanelIds, panelId]);
    }
    return panel ?? null;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public get currentPanel(): Panel | null {
    return this._currentPanel;
  }

  public get currentLayout(): PanelLayout | null {
    return this._currentLayout;
  }

  public get editMode() {
    return this._editMode;
  }

  public get hasEditingRigths() {
    return !!this.currentPanel && (UserState.isAdministrator || this.currentPanel.userId === UserState.user?.id);
  }

  @Action({ rawError: true })
  public async loadPanels(mobileBreakpoint = false) {
    this.setIsMobile(mobileBreakpoint);
    this.setLoading(true);
    this.setPanels([]);
    this.setDirectPanelIds([]);

    try {
      const contextId = ContextsState.currentContextId;
      if (contextId) {
        // Получение своих и общедоступных панелей, без личных панелей других пользователей.
        // Личная панель может быть получена администратором по запросу GET `.../${contextId}/panels/${panelId}`
        const panelDtos = await api.get<PanelDto[]>(`/api/contexts/${contextId}/panels`);
        const panels: Panel[] = panelDtos.map(x => panelFromDto(x));
        this.setPanels(panels);
        // Загружаем последнюю используемую панель из LocalStorage
        const lastPanelLocalId = getLastUsedPanelId(contextId);
        if (lastPanelLocalId && this.panels.find((panel) => panel.id === lastPanelLocalId)) {
          this.changeCurrentPanel(lastPanelLocalId);
        } else {
          this.changeCurrentPanel(getInitialLastPanelId(this.panels, mobileBreakpoint));
        }
      } else {
        this.changeCurrentPanel(null);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  async addPanel({ name, settings }: Partial<Panel>) {
    name = name?.trim();
    if (name) {
      const contextId = ContextsState.currentContextId;
      const panelDto: PanelDto = panelToDto({ name, settings } as Panel);
      const newPanelDto = await api.post<PanelDto>(`/api/contexts/${contextId}/panels`, panelDto);
      const newPanel: Panel = panelFromDto(newPanelDto);
      if (newPanel.id) {
        this.setPanels([newPanel, ...this._panels]);
        this.changeCurrentPanel(newPanel.id);
        UserState.updatePanelsOrder(checkPanelsSort(this.panels, UserState.settings?.panels));
      }
    }
  }

  @Action({ rawError: true })
  async changePanelName({ id: panelId, name }: Partial<Panel>) {
    name = name?.trim();
    if (name) {
      const contextId = ContextsState.currentContextId;
      await api.post(`/api/contexts/${contextId}/panels/${panelId}/name`, { name } as Partial<PanelDto>);
      this.setPanelName({ id: panelId, name });
    }
  }

  @Action({ rawError: true })
  async replacePanelLayout(layoutData: PanelLayout) {
    if (layoutData && this.canUpdatePanel) {
      const panel = dashboardToDashboardsState(layoutData);
      await this.changePanelSettings(panel);
    }
  }

  @Action({ rawError: true })
  async changePanelSettings({ id: panelId, settings }: Partial<Panel>) {
    const contextId = ContextsState.currentContextId;
    const panelDto: PanelDto = panelToDto({ settings } as Panel);
    await api.post(`/api/contexts/${contextId}/panels/${panelId}/settings`, panelDto);
    this.setPanelSettings({ id: panelId, settings });
  }

  @Action({ rawError: true })
  async changePanelTags({ id: panelId, tags }: Partial<Panel>) {
    const contextId = ContextsState.currentContextId;
    await api.post(`/api/contexts/${contextId}/panels/${panelId}/tags`, { tags } as Partial<PanelDto>);
    this.setPanelTags({ id: panelId, tags });
  }

  @Action({ rawError: true })
  public async changeCurrentPanel(panelId: number | null) {
    if (this.currentPanel?.id === panelId) {
      return;
    }
    const panel = this._panels.find((x) => x.id === panelId);
    if (panel) {
      this.setCurrentPanel(panel);
      // Логирование перехода пользователя на панель
      const logEvent: EventToLog = {
        action: LogEventActionType.Navigation,
        dataKind: LogEventDataKind.Panel,
        objectId: String(panel.id),
        data: { name: panel.name },
      };
      await UserState.trySaveUserLogEvent({ logEvent, contextId: ContextsState.currentContextId });
    } else {
      this.setCurrentPanel(null);
    }
  }

  @Action({ rawError: true })
  async removePanel(panelId: number) {
    const contextId = ContextsState.currentContextId;
    await api.delete(`/api/contexts/${contextId}/panels/${panelId}`);
    await this.loadPanels();
    UserState.updatePanelsOrder(checkPanelsSort(this.panels, UserState.settings?.panels));
  }

  @Action({ rawError: true })
  deleteBlockById(id: string) {
    const removedItemIndex = this._currentLayout!.items.findIndex((s) => s.id === id);
    this.deleteBlock(removedItemIndex);
  }

  @Action({ rawError: true })
  setPanelItemConfig(payload: ItemConfig) {
    if (!this.currentLayout) {
      return;
    }

    const item = this.currentLayout.items.find((item) => item.id === payload.id);
    if (item) {
      item.config = payload.config;
    }
  }

  @Mutation
  public setIsMobile(payload: boolean) {
    this._isMobile = payload;
  }

  @Mutation
  public setEditMode(payload: boolean) {
    this._editMode = payload;
  }

  @Mutation
  private deleteBlock(index: number) {
    this._currentLayout!.items.splice(index, 1);
  }

  @Mutation
  private setLoading(value: boolean) {
    this._loading = value;
  }

  @Mutation
  private setPanels(items: Panel[]) {
    this._panels = items || [];
  }

  @Mutation
  private setDirectPanelIds(ids: number[]) {
    this._directPanelIds = ids || [];
  }

  @Mutation
  private setCurrentPanel(item: Panel | null) {
    this._currentPanel = item;

    if (item) {
      this._currentLayout = dashboardsStateToDashboards(item);
    } else {
      this._currentLayout = null;
    }

    // Сохраняем последнюю выбранную панель в Local Storage
    setLastUsedPanelId(ContextsState.currentContextId, this._currentPanel?.id ?? null);
  }

  @Mutation
  private setPanelName({ id: panelId, name }: Partial<Panel>) {
    if (name) {
      const panel = this._panels.find((x) => x.id === panelId);
      if (panel) {
        panel.name = name;
      }
    }
  }

  @Mutation
  private setPanelSettings({ id: panelId, settings }: Partial<Panel>) {
    const panel = this._panels.find((x) => x.id === panelId);
    if (panel) {
      panel.settings = settings!;
    }
  }

  @Mutation
  private setPanelTags({ id: panelId, tags }: Partial<Panel>) {
    const panel = this._panels.find((x) => x.id === panelId);
    if (panel) {
      panel.tags = tags!;
    }
  }

  private get canUpdatePanel(): boolean {
    return (
      UserState.isAdministrator ||
      (!!this.currentPanel &&
        !!(
          (this.currentPanel!.userId && this.currentPanel!.userId === UserState.user!.id) ||
          (this.currentPanel!.roleId && this.currentPanel!.roleId === UserState.role!.id)
        ))
    );
  }
}

import {
  AppGroupByEnum,
  ChartColumnEnum,
  ChartPeriodEnum,
  ClassifierTypesFilterOption,
  ClassifierTypeEnum,
  DurationPickerData,
  GraphGroupByDataItem,
  IBasedOnDataModule,
  InputTableChildrenSumEnum,
  InputTableChildrenSumOption,
  InputTableClassificationEnum,
  InputTableClassificationOption,
  ModuleDataTypeEnum,
  ModuleTypeEnum,
  MtrItemTypeEnum,
  PanelLayout,
} from '@/model';

export const mobilePanelSettings = {
  numberOfCols: 4,
  basicItemsCount: 6,
  datePickerWidth: '5em',
  tooltipWidth: '350px',
};

export const commonDashboardSettings = {
  datePickerWidth: '5.3em',
  tooltipWidth: '500px',
};

export const durationPickerValues: DurationPickerData[] = [
  {
    text: 'Год',
    chartPeriod: ChartPeriodEnum.Year,
    groupBy: ChartPeriodEnum.Month,
    defaultPicker: true,
  },
  {
    text: 'Месяц',
    chartPeriod: ChartPeriodEnum.Month,
    groupBy: ChartPeriodEnum.Day,
    defaultPicker: true,
  },
  {
    text: 'День',
    chartPeriod: ChartPeriodEnum.Day,
    groupBy: ChartPeriodEnum.Day,
  },
];

/**
 * @deprecated Будет удалено.
 * Список опций для фильтра по типам классификаторов
 * */
export const classifierTypesFilterOptions: ClassifierTypesFilterOption[] = [
  {
    text: 'База',
    type: ClassifierTypeEnum.Base,
  },
  {
    text: 'Маршрут',
    type: ClassifierTypeEnum.Route,
  },
  {
    text: 'Навигация',
    type: ClassifierTypeEnum.Navigation,
  },
];

/** TODO: Проверить, можно ли Удалить по переезду на классификаторы */
export const graphGroupByValues: GraphGroupByDataItem[] = [];

export const inputTableClassificationOptions: InputTableClassificationOption[] = [
  {
    text: 'Задействованная',
    description: 'выводятся только комбинации с наличием введенных данных по узлу',
    classificationType: InputTableClassificationEnum.Involved,
  },
  {
    text: 'Задействованная по дочерним',
    description: 'выводятся только комбинации с наличием введенных данных по узлу или дочерним узлам',
    classificationType: InputTableClassificationEnum.InvolvedByChildren,
    defaultPicker: true,
  },
  {
    text: 'Полная',
    description: 'выводятся все комбинации независимо от наличия введенных данных, возможна медленная работа на большой комбинации узлов',
    classificationType: InputTableClassificationEnum.Full,
  },
];

export const inputTableChildrenSumOptions: InputTableChildrenSumOption[] = [
  {
    text: 'Отключено',
    sumType: InputTableChildrenSumEnum.None,
  },
  {
    text: 'Полная',
    description: 'по всем дочерним вершинам вглубь до листьев графа, фильтры не учитываются',
    sumType: InputTableChildrenSumEnum.Full,
    defaultPicker: true,
  },
];

export const defaultDashboardLayouts: PanelLayout[] = [
  {
    id: 0,
    name: 'Основная',
    breakpoint: 'custom',
    numberOfCols: 12,
    breakpointWidth: 500,
    margin: { x: 5, y: 5 },
    isMobile: false,
    items: [],
  },
  // в дальнейшем можно использовать под разные разрешения экрана как разные модули, так и их положение
  // {
  //     breakpoint: "lg",
  //     breakpointWidth: 1200,
  //     numberOfCols: 12,
  //     items: defaultDashboardItems
  // },
  // {
  //     breakpoint: "md",
  //     breakpointWidth: 996,
  //     numberOfCols: 10,
  //     items: defaultDashboardItems,
  // },
  // {
  //     breakpoint: "sm",
  //     breakpointWidth: 768,
  //     numberOfCols: 6,
  //     items: [],
  // },
  // {
  //     breakpoint: "xs",
  //     breakpointWidth: 480,
  //     numberOfCols: 2,
  //     items: [],
  // }
];

/** Are used to auto-refresh modules on server data updates */
export const basedOnDataModules: IBasedOnDataModule[] = [
  {
    moduleType: ModuleTypeEnum.WeatherParamsList,
    moduleDataType: ModuleDataTypeEnum.WeatherParam,
    systemTypes: null,
  },
  {
    moduleType: ModuleTypeEnum.WeatherRestrictionsList,
    moduleDataType: ModuleDataTypeEnum.WeatherRestriction,
    systemTypes: null,
  },
  {
    moduleType: ModuleTypeEnum.AccountingMtr,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      MtrItemTypeEnum.AccountingMtrTotalSupplies,
      MtrItemTypeEnum.AccountingMtrUnusedPreviousPeriod,
      MtrItemTypeEnum.AccountingMtrRegistered,
      MtrItemTypeEnum.AccountingMtrProcessing,
      MtrItemTypeEnum.AccountingMtrUncapitalize0_14,
      MtrItemTypeEnum.AccountingMtrUncapitalize15_30,
      MtrItemTypeEnum.AccountingMtrUncapitalize31_60,
      MtrItemTypeEnum.AccountingMtrUncapitalize61More,
    ],
  },
  {
    moduleType: ModuleTypeEnum.ArrivalChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      MtrItemTypeEnum.ArrivalAdjustedPlan,
      MtrItemTypeEnum.ArrivalDailyPlan,
      MtrItemTypeEnum.ArrivalFact,
      MtrItemTypeEnum.ArrivalPlan,
    ],
  },
  {
    moduleType: ModuleTypeEnum.ArrivalChartRotated,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      MtrItemTypeEnum.ArrivalAdjustedPlan,
      MtrItemTypeEnum.ArrivalDailyPlan,
      MtrItemTypeEnum.ArrivalFact,
      MtrItemTypeEnum.ArrivalPlan,
    ],
  },
  {
    moduleType: ModuleTypeEnum.CargoChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      MtrItemTypeEnum.CargoHandlingAdjustedPlan,
      MtrItemTypeEnum.CargoHandlingDailyPlan,
      MtrItemTypeEnum.CargoHandlingFact,
      MtrItemTypeEnum.CargoHandlingPlan,
      MtrItemTypeEnum.FreightTurnoverAdjustedPlan,
      MtrItemTypeEnum.FreightTurnoverDailyPlan,
      MtrItemTypeEnum.FreightTurnoverFact,
      MtrItemTypeEnum.FreightTurnoverPlan,
    ],
  },
  {
    moduleType: ModuleTypeEnum.ExecutionOfOrdersChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.BidPlan, MtrItemTypeEnum.BidForecast, MtrItemTypeEnum.BidTotalFact],
  },
  {
    moduleType: ModuleTypeEnum.ExpenditureChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      MtrItemTypeEnum.ExpenditureAdjustedPlan,
      MtrItemTypeEnum.ExpenditureDailyPlan,
      MtrItemTypeEnum.ExpenditurePlan,
      MtrItemTypeEnum.ExpenditureFact,
    ],
  },
  {
    moduleType: ModuleTypeEnum.FullnessBasesByCurrencyChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.FullnessBasesLoadedRubles],
  },
  {
    moduleType: ModuleTypeEnum.FullnessBasesByWeightChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.FullnessBasesLoadedWeight],
  },
  {
    moduleType: ModuleTypeEnum.FullnessBasesBySquareChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.FullnessBasesLoaded, MtrItemTypeEnum.FullnessBasesTotalSquare],
  },
  {
    moduleType: ModuleTypeEnum.GPMResourcesChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.GPMFact, MtrItemTypeEnum.GPMPlan],
  },
  {
    moduleType: ModuleTypeEnum.HSEChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      MtrItemTypeEnum.HSEIndicatorDangerWarning,
      MtrItemTypeEnum.HSEIndicatorDisability,
      MtrItemTypeEnum.HSEIndicatorDeath,
      MtrItemTypeEnum.HSEIndicatorMedicalCare,
      MtrItemTypeEnum.HSEIndicatorNearMiss,
    ],
  },
  {
    moduleType: ModuleTypeEnum.LaborResourcesChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.LaborResourcesFact, MtrItemTypeEnum.LaborResourcesPlan],
  },
  {
    moduleType: ModuleTypeEnum.OTIFValues,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.BidPlan, MtrItemTypeEnum.BidTotalFact, MtrItemTypeEnum.BidScheduledFact],
  },
  {
    moduleType: ModuleTypeEnum.SeasonalDeliveryChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      MtrItemTypeEnum.SeasonalDeliveryPlanForMonth,
      MtrItemTypeEnum.SeasonalDeliveryDailyPlan,
      MtrItemTypeEnum.SeasonalDeliveryFact,
      MtrItemTypeEnum.SeasonalDeliveryInitialPlanForYear,
    ],
  },
  {
    moduleType: ModuleTypeEnum.SeasonalDeliveryAnalyticsChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      // Так задумано, что здесь 3 показателя вместо 4-х. Если будет ошибкой, исправим
      MtrItemTypeEnum.SeasonalDeliveryPlanForMonth,
      MtrItemTypeEnum.SeasonalDeliveryFact,
      MtrItemTypeEnum.SeasonalDeliveryInitialPlanForYear,
    ],
  },
  {
    moduleType: ModuleTypeEnum.TransportResourcesChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [
      MtrItemTypeEnum.TransportFact,
      MtrItemTypeEnum.TransportFactHours,
      MtrItemTypeEnum.TransportFactTrunk,
      MtrItemTypeEnum.TransportPlan,
      MtrItemTypeEnum.TransportPlanHours,
      MtrItemTypeEnum.TransportPlanTrunk,
    ],
  },
  {
    moduleType: ModuleTypeEnum.ProjectIndicatorComponent,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.ProjectIndicatorPlan, MtrItemTypeEnum.ProjectIndicatorFact],
  },
  {
    moduleType: ModuleTypeEnum.ProjectIndicatorGroupProjects,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.ProductPlan, MtrItemTypeEnum.ProductFact],
  },
  {
    moduleType: ModuleTypeEnum.ProjectIndicatorGroupProjectsTotal,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.ProductPlan, MtrItemTypeEnum.ProductFact],
  },
  {
    moduleType: ModuleTypeEnum.ProjectIndicatorGroupUsers,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.ProjectIndicatorPlan, MtrItemTypeEnum.ProjectIndicatorFact],
  },
  {
    moduleType: ModuleTypeEnum.ProjectIndicatorGroupUsersTotal,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.ProjectIndicatorPlan, MtrItemTypeEnum.ProjectIndicatorFact],
  },
  {
    moduleType: ModuleTypeEnum.ProjectDynamicsUsersChart,
    moduleDataType: ModuleDataTypeEnum.IndicatorData,
    systemTypes: [MtrItemTypeEnum.ProjectIndicatorPlan, MtrItemTypeEnum.ProjectIndicatorFact],
  },
];

export const DefaultSort = [
  { location: 'СЕВЕР', order: 1 },
  { location: 'ЮГ', order: 2 },
  { location: 'ЦЕНТР', order: 3 },
  { location: 'ВОСТОК', order: 4 },
  { location: 'ЗАПАД', order: 5 },
];

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { ClassifierLight, IndicatorFull, IndicatorWithClassifiers, MtrItemTypeEnum } from '@/model';
import { cloneDeep, distinctBy, getIndicators, getIndicatorsInfoWithClassifiers } from '@/helpers';
import { ContextsState } from '.';
import { IndicatorsTreeStructureConfig } from '@/config';
import { IIndicatorsTreeNode } from '@/components/InputData/common/models';
import { IndicTreeDynamicRootId } from '@/config/constants/commonConstants';

@Module({ name: 'indicators', namespaced: true })
export default class IndicatorsModule extends VuexModule {
  private _loading = true;
  private _indicatorsTreeItems: IIndicatorsTreeNode[] = [];
  private _indicators: IndicatorFull[] = [];
  private _indicatorsWithClassifiers: IndicatorWithClassifiers[] = [];
  private _indicatorClassifiers: ClassifierLight[] = [];
  private _indicatorsLastUpdated: string | null = null;

  public get loading(): boolean {
    return this._loading;
  }

  /** Весь список показателей из БД */
  public get indicators(): IndicatorFull[] {
    return this._indicators || [];
  }

  /** Последнее обновление списка показателей контекста для $watch */
  public get indicatorsLastUpdated(): string | null {
    return this._indicatorsLastUpdated;
  }

  /** Всё дерево показателей - статические и динамические */
  public get indicatorsTreeItems(): IIndicatorsTreeNode[] {
    return this._indicatorsTreeItems || [];
  }

  /** Весь список показателей с привязанными к ним классификаторами */
  public get indicatorsWithClassifiers(): IndicatorWithClassifiers[] {
    return this._indicatorsWithClassifiers || [];
  }

  /** Весь список классификаторов (корней), к которым привязаны какие-либо показатели, distinct */
  public get indicatorClassifiers(): ClassifierLight[] {
    return this._indicatorClassifiers || [];
  }

  @Action({ rawError: true })
  public async loadIndicators() {
    this.setLoading(true);
    let indicators: IndicatorFull[] = [];

    try {
      const contextId = ContextsState.currentContextId;
      if (contextId) {
        indicators = await getIndicators();
      }
    } finally {
      this.setIndicators(indicators);
      this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async loadIndicatorsWithClassifiers() {
    this.setLoading(true);
    let indicatorsWithClassifiers: IndicatorWithClassifiers[] = [];
    let indicatorClassifiers: ClassifierLight[] = [];

    try {
      const contextId = ContextsState.currentContextId;
      if (contextId) {
        indicatorsWithClassifiers = await getIndicatorsInfoWithClassifiers();

        const allIndicatorClassifiers: ClassifierLight[] = [];
        for (const indicator of indicatorsWithClassifiers) {
          for (const indicatorClassifier of indicator.indicatorClassifiers) {
            allIndicatorClassifiers.push(indicatorClassifier.classifier);
          }
        }
        indicatorClassifiers = distinctBy(allIndicatorClassifiers, item => item.innerId);
      }
    } finally {
      this.setIndicatorsWithClassifiers(indicatorsWithClassifiers);
      this.setIndicatorClassifiers(indicatorClassifiers);
      this.setLoading(false);
    }
  }

  @Mutation
  private setIndicators(indicators: IndicatorFull[]) {
    this._indicators = indicators || [];
    const groups: IIndicatorsTreeNode[] = [];

    const systemIndicators = indicators.filter(x => x.systemType != null);
    const userIndicators = indicators.filter(x => x.systemType == null);
    const resultTreeItems: IIndicatorsTreeNode[] = [];

    // TODO: Позже предусмотреть точную фильтрацию по реальным системным показателям контекста.
    // А пока просто проверяем, есть ли в контексте хоть какие-то системыне показатели в БД или нет.
    if (systemIndicators.length > 0) {
      // Наполнение узлов дерева из структуры-конфига. Если показатель найден в БД, то дописываем узлу indicatorId
      for (const treeStructureConfigItem of IndicatorsTreeStructureConfig) {
        const typeAsEnum = treeStructureConfigItem.type;
        if (typeAsEnum == undefined) {
          resultTreeItems.push(treeStructureConfigItem);
        } else {
          const typeAsString = MtrItemTypeEnum[typeAsEnum];
          const found = typeAsString ? systemIndicators.find(x => x.systemType === typeAsString) : undefined;
          if (found) {
            const cloned = cloneDeep(treeStructureConfigItem);
            // Дописываем indicatorId из БД
            cloned.indicatorId = found.id;
            resultTreeItems.push(cloned);
          } else {
            resultTreeItems.push(treeStructureConfigItem);
          }
        }
      }
    } else {
      // Добавляем только корень для пользовательских показателей
      const dynamicIndicatorsRootNode = IndicatorsTreeStructureConfig.find(x => x.id === IndicTreeDynamicRootId);
      if (dynamicIndicatorsRootNode) {
        resultTreeItems.push(dynamicIndicatorsRootNode);
      }
    }

    // Наполнение узлов дерева пользовательскими показателями
    for (const indicator of userIndicators) {
      let parentId = IndicTreeDynamicRootId;

      const grouping = indicator.settings.grouping ?? [];
      if (grouping.length > 0) {
        let groupId = 'group:';
        for (let i = 0; i < grouping.length; i++) {
          const group = grouping[i];
          groupId += `|${group}`;
          const existingGroupItem = groups.find(x => x.id === groupId);
          if (!existingGroupItem) {
            const newGroupItem: IIndicatorsTreeNode = {
              id: groupId,
              parentId: i === 0 ? IndicTreeDynamicRootId : parentId,
              name: group,
              isDynamic: true,
            };
            groups.push(newGroupItem);
            // Добавляем группирующий узел-папку
            resultTreeItems.push(newGroupItem);
          }
          parentId = groupId;
        }
      }

      const treeItem: IIndicatorsTreeNode = {
        id: indicator.id!,
        parentId: parentId,
        indicatorId: indicator.id!,
        name: indicator.title,
        isDynamic: true,
      };
      // Добавляем реальный узел - пользовательский показатель
      resultTreeItems.push(treeItem);
    }

    this._indicatorsTreeItems = resultTreeItems;
    this._indicatorsLastUpdated = (new Date()).toISOString();
  }

  @Mutation
  private setIndicatorsWithClassifiers(indicatorsWithClassifiers: IndicatorWithClassifiers[]) {
    this._indicatorsWithClassifiers = indicatorsWithClassifiers || [];
  }

  @Mutation
  private setIndicatorClassifiers(indicatorClassifiers: ClassifierLight[]) {
    this._indicatorClassifiers = indicatorClassifiers || [];
  }

  @Mutation
  private setLoading(value: boolean) {
    this._loading = value;
  }
}

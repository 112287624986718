import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-020b8eb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block-settings-container" }
const _hoisted_2 = { class: "block-settings" }
const _hoisted_3 = { class: "block-settings-controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxToolbarItem = _resolveComponent("DxToolbarItem")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxPopup = _resolveComponent("DxPopup")!

  return (_ctx.settingsVisible)
    ? (_openBlock(), _createBlock(_component_DxPopup, {
        key: 0,
        name: "settingsPopup",
        visible: _ctx.settingsVisible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settingsVisible) = $event)),
        "drag-enabled": true,
        "close-on-outside-click": false,
        "show-close-button": true,
        "show-title": true,
        width: _ctx.width,
        height: _ctx.height,
        fullScreen: _ctx.isFullscreen,
        title: _ctx.title
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxToolbarItem, {
            widget: "dxButton",
            options: {
        hint: 'Переключение полноэкранного отображения',
        icon: 'fullscreen',
        onClick: _ctx.setFullscreen,
      },
            location: "after"
          }, null, 8, ["options"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_DxButton, {
                icon: "save",
                hint: "Сохранить",
                text: "Сохранить",
                onClick: _ctx.save
              }, null, 8, ["onClick"]),
              _createVNode(_component_DxButton, {
                hint: "Отмена",
                text: "Отмена",
                onClick: _ctx.cancel
              }, null, 8, ["onClick"])
            ])
          ])
        ]),
        _: 3
      }, 8, ["visible", "width", "height", "fullScreen", "title"]))
    : _createCommentVNode("", true)
}
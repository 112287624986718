import { events, remoteHost } from '@/config';
import { ConnectionStatus } from '@/model';
import auth from '@/services/auth';
import { ConnectionState, ContextsState } from '@/store';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';

type Handler = (args: any[]) => void;

class Events {
  private _connection?: HubConnection;
  private _handlers: { [id: string]: Handler[] } = {};
  private _countTryConnection = 0;
  private _temporaryDisconnected = false;

  public async startupAsync(): Promise<void> {
    if (this._connection === undefined) {
      this._connection = new HubConnectionBuilder()
        .withUrl(`${remoteHost}/eventsHub`, {
          transport: HttpTransportType.LongPolling,
          accessTokenFactory: () => auth.getToken(),
        })
        .configureLogging(LogLevel.Information)
        .build();

      this._connection.onclose(() => {
        ConnectionState.changeStatus(ConnectionStatus.TempDisconnect);
        this.reconnect();
      });

      await this.connect();
    }
  }

  public on(event: string, handler: Handler): void {
    this.storeHandler(event, handler);
    this._connection!.on(event, handler);
  }

  public off(event: string, handler: Handler): void {
    this.deleteHandler(event, handler);
    this._connection!.off(event, handler);
  }

  public raise(event: string, args: any): void {
    const handlers = this.getHandlers(event);
    for (const handler of handlers) {
      handler(args);
    }
  }

  private connect(): Promise<any> {
    return this._connection!.start()
      .catch((error) => {
        if (error && (error.statusCode === 403 || error.statusCode === 401)) {
          auth!.login();
        }
      })
      .finally(() => {
        if (this._connection!.state !== HubConnectionState.Connected) {
          if (this._countTryConnection < events.countTryConnection) {
            this._countTryConnection++;
            ConnectionState.changeStatus(ConnectionStatus.TempDisconnect);
          } else {
            ConnectionState.changeStatus(ConnectionStatus.Disconnected);
            this._temporaryDisconnected = true;
          }
          this.reconnect();
        }
        if (this._connection!.state === HubConnectionState.Connected) {
          this._countTryConnection = 0;
          this._temporaryDisconnected = false;
          ConnectionState.changeStatus(ConnectionStatus.Connected);
          setTimeout(() => {
            ConnectionState.changeStatus(ConnectionStatus.NotSet); // удаляем индикатор соединения
          }, 5000);
          this.onReconnected(); // переподписка на контекст
        }
      });
  }

  public reconnect(): void {
    const timeout = !this._temporaryDisconnected
      ? events.signalRReconnectTimeout
      : events.signalRestoreConnectionTimeout;
    this._temporaryDisconnected = false;
    setTimeout(() => {
      ConnectionState.changeStatus(ConnectionStatus.Reconnecting);
      this.connect();
    }, timeout);
  }

  public async resubscribeToContext(newContextId: number | null, prevContextId: number | null): Promise<void> {
    if (prevContextId) {
      await this.runServerMethodSafe('UnsubscribeContextAsync', String(prevContextId));
    }

    if (newContextId) {
      await this.runServerMethodSafe('SubscribeContextAsync', String(newContextId));
    }
  }

  private async onReconnected(): Promise<void> {
    const contextId = ContextsState.currentContext?.id;
    if (contextId && contextId !== null) {
      await this.resubscribeToContext(contextId, null);
    }
  }

  private storeHandler(event: string, handler: Handler): void {
    let handlers = this._handlers[event];

    if (handlers === undefined) {
      handlers = [];
      this._handlers[event] = handlers;
    }

    handlers.push(handler);
  }

  private deleteHandler(event: string, handler: Handler): void {
    const handlers = this._handlers[event];

    if (handlers !== undefined) {
      this._handlers[event] = handlers.filter((x) => x !== handler);
    }
  }

  private getHandlers(event: string): Handler[] {
    return this._handlers[event] || [];
  }

  private async runServerMethodSafe(name: string, ...args: any[]) {
    if (this._connection && this._connection.state === HubConnectionState.Connected) {
      try {
        await this._connection.invoke(name, ...args);
      } catch (err) {
        return;
      }
    }
  }
}

export default new Events();

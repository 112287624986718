import { Theme } from '@/plugins/types';
import { base } from './base';

export const darkYellow: Theme = {
  dark: true,
  light: false,
  colors: {
    ...base,
    primary: '#FFEB3B',
    'primary-darken-1': '#FFEB3B',
    secondary: '#FFEB3B',
    'secondary-darken-1': '#FFEB3B',
  },
};

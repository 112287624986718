export enum PanelLevelEnum {
  Context = 'context',
  Role = 'role',
  User = 'user',
}

export interface PanelLevel {
  id: string;
  title: string;
  type: PanelLevelEnum;
}

export const panelLevels: PanelLevel[] = [
  {
    id: 'User',
    title: 'Только мне',
    type: PanelLevelEnum.User,
  },
  {
    id: 'Context',
    title: 'Всем в данном контексте',
    type: PanelLevelEnum.Context,
  },
  {
    id: 'Role',
    title: 'Выбранной роли',
    type: PanelLevelEnum.Role,
  },
];

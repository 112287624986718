import { ConnectionSettings } from '@/model';

export interface Config {
  Auth?: ConnectionSettings;
}

export enum FetchMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

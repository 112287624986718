
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    text: [String, Number],
  },
})
export default class Label extends Vue {
  public text?: string | number;
}

import { remoteHost } from '@/config';
import auth from '@/services/auth';
import { stringifyUrl } from 'query-string';
import { FetchMethod } from './types';

class API {
  public async fetch(url: string, object?: any, method: FetchMethod = FetchMethod.GET): Promise<Response> {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
    });

    const token = await auth.getToken();

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (url.indexOf(remoteHost || '') < 0) {
      url = this.buildAbsoluteUrl(url);
    }

    let body = undefined;
    if (object) {
      if (method == FetchMethod.GET) {
        url = stringifyUrl({ url: url, query: object });
      } else {
        body = JSON.stringify(object);
      }
    }

    return fetch(url, {
      body,
      headers,
      method,
    }).then((response) => {
      if (response.status === 401 || response.status === 403) {
        auth.login();
      }
      return response;
    });
  }

  public get<T>(url: string, queryData?: any): Promise<T> {
    return this.getJsonFromApi<T>(url, queryData, FetchMethod.GET);
  }

  public post<T>(url: string, object?: any): Promise<T> {
    return this.getJsonFromApi<T>(url, object, FetchMethod.POST);
  }

  public delete<T>(url: string, body?: any): Promise<T> {
    return this.getJsonFromApi<T>(url, body, FetchMethod.DELETE);
  }

  public put<T>(url: string, object?: any): Promise<T> {
    return this.getJsonFromApi<T>(url, object, FetchMethod.PUT);
  }

  public buildAbsoluteUrl(url: string): string {
    return remoteHost + url;
  }

  private async getJsonFromApi<T>(url: string, object?: any, method?: FetchMethod): Promise<T> {
    const response = await this.fetch(url, object, method);
    const respObj: any = await (() => {
      return response.text().then((text) => (text ? JSON.parse(text) : null));
    })();
    if (response.ok) {
      return respObj;
    } else {
      throw new Error(`${respObj[0].errorCode}: (${respObj[0].errorMessage})`);
    }
  }
}

export default new API();

export enum LogEventActionType {
  Add = 'Add',
  Update = 'Update',
  Delete = 'Delete',
  Migrate = 'Migrate',
  Archive = 'Archive',
  Unarchive = 'Unarchive',
  Login = 'Login',
  Logout = 'Logout',
  Navigation = 'Navigation',
  Export = 'Export',
  GrantAccess = 'GrantAccess',
  RevokeAccess = 'RevokeAccess',
  Other = 'Other',
}

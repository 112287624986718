import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c064d6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar" }
const _hoisted_2 = {
  key: 0,
  class: "toolbar-title"
}
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            title: _ctx.title,
            style: _normalizeStyle(_ctx.style),
            class: "toolbar-title-text wrap"
          }, _toDisplayString(_ctx.title), 13, _hoisted_3),
          (_ctx.subtitle)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                title: _ctx.subtitle,
                class: "toolbar-subtitle-text"
              }, _toDisplayString(_ctx.subtitle), 9, _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}

import { Options, Vue } from 'vue-class-component';
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import DxButton from 'devextreme-vue/button';
import { DxTooltip } from 'devextreme-vue/tooltip';

import Settings from './Settings/Settings.vue';
import { getCurrentThemeIsDark, copyOnPanel } from '@/helpers';
import Toolbar from './Settings/Components/Toolbar.vue';
import { BlockSettingsState, PanelsState, UserState } from '@/store';
import { getBlockStyle, getCurrentTheme } from '@/helpers/themes';
import { SettingsType } from '@/store/blockSettings';
import { BlockDropDownItem } from './types';
import { BackgroundType, IModuleSeries, ModuleTypeEnum, PanelBlock } from '@/model';
import { throttle } from '@/helpers/utils';
import { Guid } from '@/helpers/guid';

const RESIZE_THROTTLE_MS: number = 1000 / 30; // 30 fps

@Options({
  components: {
    DxDropDownButton,
    DxButton,
    DxTooltip,
    Settings,
    Toolbar,
  },
  props: {
    inFolder: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    dynamicSeries: {
      type: Array,
      required: false,
      default: null,
    },
    noDataText: {
      type: String,
      required: false,
      default: 'Нет данных',
    },
    noData: {
      type: Boolean,
      required: false,
      default: false,
    },
    noDataDetails: {
      type: String,
      required: false,
      default: null,
    },
    dataIsLoading: {
      type: Boolean,
      required: false,
      default: null,
    },
    minBlickHeight: {
      type: Number,
      required: false,
      default: 150,
    },
    minBlickWidth: {
      type: Number,
      required: false,
      default: 220,
    },
    ignoreMinSizes: {
      type: Boolean,
      required: false,
      default: false,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    block: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    moving: {
      type: Boolean,
      required: false,
      default: false,
    },
    showToolbar: {
      type: Boolean,
      required: false,
      default: true,
    },
    dropDownButtons: {
      type: Array,
      required: false,
      default: null,
    },
    showSettingsButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCustomSettings: {
      type: Boolean,
      required: false,
      default: false,
    },
    settingsTitle: {
      type: String,
      required: false,
      default: 'Настройки',
    },
    hideSettingsIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    settingsHeight: {
      type: Number,
      required: false,
      default: 600,
    },
    settingsWidth: {
      type: Number,
      required: false,
      default: 500,
    },
    settingsFullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    overridePadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: null,
    },
    backgroundType: {
      type: String,
      required: false,
    },
    smallOffset: {
      type: Boolean,
      reqired: false,
      default: false,
    },
  },
})
export default class Block extends Vue {
  public title!: string;
  public subtitle!: string;
  public backgroundColor?: string;

  // Позволяет управлять логикой панели (Если данных нет - выводим уведомление)
  public noDataText!: string;
  public noData!: boolean;
  public noDataDetails!: string | null;
  public inFolder!: boolean;
  public backgroundType?: BackgroundType;
  public smallOffset!: boolean;

  // Позволяет отображать загрузку данных панели (Если данные загружаются - показываем спиннер)
  public dataIsLoading!: boolean | null;

  // Минимальные предельные размеры блока для отобажения всего его содержимого
  public minBlickHeight!: number;
  public minBlickWidth!: number;
  // Указываем блоку не скрывать контент при размерах блока меньших, чем минимальные
  public ignoreMinSizes!: boolean;

  // Режим редактирования компонента
  public editMode!: boolean;
  public block!: PanelBlock | null;
  public type!: ModuleTypeEnum | null;
  public moving!: boolean;

  // Блок настроек
  public showToolbar!: boolean;
  public dropDownButtons!: BlockDropDownItem[] | null;
  public showSettingsButton!: boolean;
  public settingsTitle!: string;
  public showCustomSettings!: boolean;
  public hideSettingsIcon!: boolean;
  // Размеры окна настроек
  public settingsHeight!: number;
  public settingsWidth!: number;
  public settingsFullscreen!: boolean;
  public overridePadding!: boolean;

  public showSettingsItem: BlockDropDownItem = {
    id: -1,
    name: 'Настройки',
    function: this.showSettings,
  };

  public dynamicSeries!: IModuleSeries[];

  public resizeCallback = throttle(this.resizeCallbackThrottled.bind(this), RESIZE_THROTTLE_MS);
  // Используем Resize Observer API
  // MDN: https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API
  public resizeObserver: ResizeObserver = new ResizeObserver(this.resizeCallback);
  public blockContainer: Element | null = null;
  public displayBlock = true;

  public noDataDetailsTooltipId = `block_${Guid.newGuid().toString()}`;
  public isNoDataDetailsVisible = false;
  public areSettingsVisible = false;

  public get getColor() {
    if (this.backgroundType) {
      switch (this.backgroundType) {
        case BackgroundType.None: {
          if (!this.inFolder) {
            return getCurrentThemeIsDark() ? '#161616' : '#F8F8F8';
          }
          return getCurrentThemeIsDark() ? '#212121' : '#EFF2F5';
        }
        case BackgroundType.Header: {
          return getCurrentThemeIsDark() ? '#363636' : '#FFFFFF';
        }
        default: {
          if (!this.inFolder) {
            return this.backgroundColor ?? 'surface';
          }
          return getCurrentThemeIsDark() ? '#363636' : '#FFFFFF';
        }
      }
    }

    if (this.type === 'Separator') {
      return 'background';
    }

    if (!this.inFolder) {
      return this.backgroundColor ?? 'surface';
    }

    return getCurrentThemeIsDark() ? '#363636' : '#FFFFFF';
  }

  public get noBordersStyle() {
    return this.backgroundType === BackgroundType.None || this.backgroundType === BackgroundType.Header;
  }

  public get panelsToCopy() {
    return PanelsState.panels.filter((panel) => {
      return UserState.isAdministrator || panel.userId === UserState.user?.id;
    });
  }

  public mounted() {
    this.blockContainer = this.$refs['block'] as Element;

    if (this.blockContainer) {
      this.resizeObserver.observe(this.blockContainer);
    }
  }

  public beforeDestroy() {
    if (this.blockContainer) {
      this.resizeObserver.unobserve(this.blockContainer);
      this.resizeObserver.disconnect();
    }
  }

  public onSettingsSave() {
    this.$emit('onSettingsSave');
    this.areSettingsVisible = false;
  }

  public onSettingsCancel() {
    this.$emit('onSettingsCancel');
    this.areSettingsVisible = false;
  }

  public async showSettings() {
    await this.$nextTick(() => {
      this.areSettingsVisible = true;
    });
    (this.$refs['settings-popup'] as Settings).show();
  }

  public async copyBlock(id: number) {
    await copyOnPanel(id, this.block);
  }

  public changeNoDataDetailsVisibility(visible: boolean) {
    this.isNoDataDetailsVisible = visible;
  }

  public currentPanelSubtitle(id: number) {
    return PanelsState.currentPanel?.id === id ? 'Текущая' : undefined;
  }

  private resizeCallbackThrottled() {
    if (!this.blockContainer) {
      return;
    }

    const contentHeight = this.blockContainer.clientHeight;
    const contentWidth = this.blockContainer.clientWidth;

    this.displayBlock =
      (contentHeight >= this.minBlickHeight && contentWidth >= this.minBlickWidth) || this.ignoreMinSizes;

    this.$emit('resized', contentHeight, contentWidth);
  }

  public get dropDownItems() {
    const items = this.dropDownButtons ?? [];

    if (this.showSettingsButton) {
      items.push(this.showSettingsItem);
    }

    return items;
  }

  public get showDropDownButton() {
    return this.dropDownItems.length > 0 && PanelsState.hasEditingRigths;
  }

  public get showContent() {
    return !this.dataIsLoading && !this.noData;
  }

  public get style() {
    return getBlockStyle(this.type);
  }

  public dropDownItemClick(e: any) {
    const item = this.dropDownItems.find((item) => item.id === e.itemData.id);

    if (item && typeof item.function === 'function') {
      if (item.id === -1) {
        this.showSettings();
      } else {
        item.function();
      }
    }
  }

  public showChartSettings() {
    if (this.showSettingsButton) {
      this.showSettings();
    } else {
      if (this.showCustomSettings) {
        this.$emit('open-settings-window');
      } else {
        this.openSettingsLegacy();
      }
    }
  }

  public get borders() {
    return `border: 1px solid ${getCurrentTheme().id === 0 ? '#ffffff0d' : '#C2C2C2'}; border-radius: ${
      this.inFolder ? '0px' : '4px'
    };`;
  }

  public openSettingsLegacy() {
    this.pickModule();
    BlockSettingsState.setSettingMode(SettingsType.Chart);
  }

  public removePanelBlock() {
    if (this.block) {
      PanelsState.deleteBlockById(this.block.id);
    }
  }

  public pickModule() {
    if (this.block) {
      BlockSettingsState.setConfiguringItem(this.block);
      if (this.dynamicSeries) {
        BlockSettingsState.setSeries(this.dynamicSeries);
      }
    }
  }
}

import { Theme } from '@/plugins/types';
import { base } from './base';

export const darkGrey: Theme = {
  dark: true,
  light: false,
  colors: {
    ...base,
    primary: '#607D8B',
    'primary-darken-1': '#607D8B',
    secondary: '#607D8B',
    'secondary-darken-1': '#607D8B',
  },
};

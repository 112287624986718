import { allPanelModules } from '@/config';
import {
  IModuleConfig,
  IModuleItem,
  IModuleSeries,
  Panel,
  PanelBlock,
  PanelDto,
  PanelItemSavedState,
  PanelLayout,
  PanelLayoutDto,
  PanelLayoutSavedState,
} from '@/model';
import { cloneDeep } from '.';
import { Guid } from './guid';
import { getLocalSettings, setLocalSettings } from '@/helpers';
import { PanelsState } from '@/store';

/**
 * Gets dashboard module by ID
 * @param id Module ID
 * @returns Module item object or undefined
 */
export function getModule(id: string): IModuleItem | undefined {
  return allPanelModules.find((m) => m.id === id)!;
}

/**
 * Converts saved dashboard object for use on frontend.
 * @param dashboardState Dashboard object loaded from server
 * @returns {IDashboardConfig}
 */
export function dashboardsStateToDashboards(panel: Panel): PanelLayout {
  const settings: PanelLayout = panel.settings;

  const layout: PanelLayout = {
    id: panel.id,
    name: panel.name,
    roleId: panel.roleId,
    userId: panel.userId,
    margin: settings.margin,
    numberOfCols: settings.numberOfCols,
    breakpoint: settings.breakpoint,
    rowHeight: settings.rowHeight,
    isMobile: Boolean(settings.isMobile),
    items: [],
    themeId: settings.themeId,
  };

  settings.items.forEach((panelItem) => {
    const module = allPanelModules.find((s) => s.id === panelItem.moduleId);
    if (module) {
      const dashboardItem: PanelBlock = createDashboardItem(module, panelItem, Boolean(settings.isMobile));
      layout.items.push(dashboardItem);
    }
  });

  return layout;
}

/**
 * Convert frontend dashboard object to backend stored object
 * @returns {IDashboardConfigSavedState}
 */
export function dashboardToDashboardsState(panel: PanelLayout): Panel {
  const savedLayout: PanelLayoutSavedState = {
    id: panel.id,
    name: panel.name,
    numberOfCols: panel.numberOfCols,
    breakpoint: panel.breakpoint,
    margin: panel.margin,
    rowHeight: panel.rowHeight,
    isMobile: Boolean(panel.isMobile),
    themeId: panel.themeId,
    items: [],
  };

  panel.items.forEach((item) => {
    const savedDashboardItem = createSavedDashboardItem(item);
    savedLayout.items.push(savedDashboardItem);
  });

  const savedPanel: Panel = {
    id: panel.id,
    name: panel.name,
    roleId: panel.roleId,
    userId: panel.userId,
    settings: savedLayout,
  };

  return savedPanel;
}

export function panelFromDto(panel: PanelDto): Panel {
  const panelModel: Panel = {
    id: panel.id,
    name: panel.name,
    userId: panel.userId,
    roleId: panel.roleId,
    settings: panelLayoutFromDto(panel),
    tags: panel.tags,
  };
  return panelModel;
}

export function panelLayoutFromDto(panel: PanelDto): PanelLayout {
  const layoutDto = panel.layout;
  const layoutModel: PanelLayout = {
    margin: layoutDto.margin ?? undefined,
    themeId: layoutDto.themeId ?? undefined,
    isMobile: layoutDto.isMobile,
    numberOfCols: layoutDto.colCount ?? undefined,
    rowHeight: typeof layoutDto.rowHeight === 'number' ? layoutDto.rowHeight : false,
    breakpoint: layoutDto.breakpoint,
    // блоки:
    items: JSON.parse(panel.blocks) as PanelItemSavedState[],
    // вспомогательные поля, которых в этом месте нет на бэке:
    id: panel.id,
    name: panel.name,
    userId: panel.userId,
    roleId: panel.roleId,
  };
  return layoutModel;
}

export function panelToDto(panel: Panel): PanelDto {
  const panelDto: PanelDto = {
    id: panel.id,
    name: panel.name,
    userId: panel.userId,
    roleId: panel.roleId,
    layout: panelLayoutToDto(panel.settings),
    blocks: JSON.stringify(panel.settings.items),
    tags: panel.tags,
  };
  return panelDto;
}

export function panelLayoutToDto(layout: PanelLayout): PanelLayoutDto {
  const layoutDto: PanelLayoutDto = {
    margin: layout.margin,
    themeId: layout.themeId,
    isMobile: layout.isMobile,
    colCount: layout.numberOfCols,
    rowHeight: typeof layout.rowHeight === 'number' ? layout.rowHeight : undefined,
    breakpoint: layout.breakpoint,
  };
  return layoutDto;
}

/**
 * Clear dashboard item data to store on backend.
 * @param item Dashboard item
 * @returns {PanelItemSavedState} Clean dashboard item object
 */
export function createSavedDashboardItem(item: PanelBlock): PanelItemSavedState {
  return {
    id: item.id!,
    x: item.x,
    y: item.y,
    width: item.width,
    height: item.height,
    locked: item.locked,
    moduleId: item.moduleId,
    config: saveItemConfig(item.config),
    series: saveItemSeries(item.series),
  };
}

/**
 * Clear item series data to store on backend.
 * @param itemSeries Item series
 * @returns {any | undefined} Clean series object
 */
export function saveItemSeries(itemSeries?: IModuleSeries[]): any | undefined {
  if (!itemSeries) {
    return undefined;
  }

  const serieses = cloneDeep(itemSeries);

  return serieses.map((series) => {
    return {
      id: series.id,
      value: series.value,
    };
  });
}

/**
 * Clear item config data to store on backend.
 * @param itemConfig Item config
 * @returns {any | undefined} Clean config object
 */
export function saveItemConfig(itemConfig?: IModuleConfig): Partial<IModuleConfig> | undefined {
  if (!itemConfig) {
    return undefined;
  }

  const config = cloneDeep(itemConfig);

  return {
    columns: config.columns,
    type: config.type,
    period: config.period,
    folderSettings: config.folderSettings,
    projectIndicatorConfig: config.projectIndicatorConfig,
    selectedTimeSpans: config.selectedTimeSpans,
    indicatorsSelection: config.storeIndicatorsSelection ? config.indicatorsSelection : undefined,
    inputTableConfig: config.inputTableConfig,
    customParams: config.customParams,
    byClassifiers: config.byClassifiers,
    unifiedConfig: config.unifiedConfig,
    dividerConfig: config.dividerConfig,
  };
}

/**
 * Create dashboard item object.
 * @param module Item module
 * @param dashboardItem Item
 * @param isMobile Item is for mobile dashboard
 * @returns {PanelBlock} Dashboard item
 */
export function createDashboardItem(module: IModuleItem, dashboardItem: PanelBlock, isMobile = false): PanelBlock {
  const width: number = isMobile ? module.defaultMobileWidth : module.defaultWidth;
  const height: number = isMobile ? module.defaultMobileHeight : module.defaultHeight;

  return {
    id: dashboardItem.id === '' ? Guid.newGuid() : dashboardItem.id,
    x: dashboardItem.x,
    y: dashboardItem.y,
    width: dashboardItem.width ?? width,
    minWidth: module.defaultMinWidth ?? 1,
    defaultMobileWidth: module.defaultMobileWidth,
    height: dashboardItem.height ?? height,
    minHeight: module.defaultMinHeight ?? 1,
    defaultMobileHeight: module.defaultMobileHeight,
    config: loadItemConfig(module.defaultConfig ?? {}, dashboardItem.config),
    series: loadItemSeries(module.defaultSeries, dashboardItem.series),
    maxHeight: module.defaultMaxHeight,
    maxWidth: module.defaultMaxWidth,
    moduleId: dashboardItem.moduleId,
  };
}

/**
 * Merge default item config with loaded item config.
 * @param moduleConfig Default module config
 * @param itemConfig Item module config
 * @returns {IModuleConfig | undefined} Merged with item config
 */
export function loadItemConfig(moduleConfig?: IModuleConfig, itemConfig?: IModuleConfig): IModuleConfig | undefined {
  if (!moduleConfig) {
    return undefined;
  }

  if (!itemConfig) {
    return moduleConfig;
  }

  const config = cloneDeep(moduleConfig);

  config.columns = itemConfig.columns ?? config.columns;
  config.type = itemConfig.type ?? config.type;
  config.period = itemConfig.period ?? config.period;
  config.selectedTimeSpans = itemConfig.selectedTimeSpans ?? config.selectedTimeSpans;
  if (config.storeIndicatorsSelection) {
    config.indicatorsSelection = itemConfig.indicatorsSelection ?? config.indicatorsSelection;
  }
  config.folderSettings = itemConfig.folderSettings;
  config.inputTableConfig = itemConfig.inputTableConfig ?? config.inputTableConfig;
  config.projectIndicatorConfig = itemConfig.projectIndicatorConfig ?? config.projectIndicatorConfig;
  config.customParams = itemConfig.customParams;
  config.byClassifiers = itemConfig.byClassifiers;
  config.unifiedConfig = itemConfig.unifiedConfig;
  config.dividerConfig = itemConfig.dividerConfig;

  return config;
}

/**
 * Merge default config item series with loaded item series.
 * @param moduleSeries Default module series
 * @param itemSeries Item module series
 * @returns {IModuleSeries[] | undefined} Merged with config item series
 */
export function loadItemSeries(
  moduleSeries?: IModuleSeries[],
  itemSeries?: IModuleSeries[]
): IModuleSeries[] | undefined {
  if (!moduleSeries) {
    return undefined;
  }

  if (!itemSeries) {
    return cloneDeep(moduleSeries);
  }

  const serieses = cloneDeep(moduleSeries);
  serieses.forEach((series) => {
    const value = itemSeries.find((s) => s.id === series.id)?.value;
    series.value = value ?? series.value;
  });

  return serieses;
}

export function getLastUsedPanelId(contextId: number): number | null {
  const panels = getLocalSettings().lastPanelsList ?? [];

  return panels.find((panel) => panel.contextId === contextId)?.panelId ?? null;
}

export function setLastUsedPanelId(contextId: number | null, panelId: number | null): void {
  if (!contextId || !panelId) {
    return;
  }

  const settings = getLocalSettings();
  const panels = settings.lastPanelsList ?? [];
  const index = panels.findIndex((panel) => panel.contextId === contextId);

  if (index >= 0) {
    panels.splice(index, 1, { contextId, panelId });
  } else {
    panels.push({ contextId, panelId });
  }

  settings.lastPanelsList = panels;
  setLocalSettings(settings);
}

export function getInitialLastPanelId(panels: Panel[], mobileBreakpoint: boolean): number | null {
  if (mobileBreakpoint) {
    const parsedPanels = panels.map((panel) => {
      return dashboardsStateToDashboards(panel);
    });
    return (
      parsedPanels.sort((a, b) => {
        return (a.numberOfCols ?? 0) - (b.numberOfCols ?? 0);
      })[0]?.id ?? null
    );
  } else {
    return panels[0]?.id ?? null;
  }
}

export enum PanelLevelEnum {
  Context = 'Context',
  Role = 'Role',
  User = 'User',
}

export function getPanelLevel(panel: Panel) {
  if (panel) {
    if (!panel.roleId && !panel.userId) {
      return PanelLevelEnum.Context;
    }
    if (panel.roleId && !panel.userId) {
      return PanelLevelEnum.Role;
    }
    if (!panel.roleId && panel.userId) {
      return PanelLevelEnum.User;
    }
  }
}

/**
 * Функция актуализации сортировки панелей.
 * @param {number[]} oldOrder Старый порядок панелей.
 * @param {number[]} newOrder Новый порядок панелей.
 * @returns {number[]} Актуальный список сортировки панелей.
 */
export function checkPanelsSort(oldOrder: number[], newOrder?: number[]): number[];
/**
 * Функция актуализации сортировки панелей.
 * @param {Panel[]} oldOrder Панели в старом порядке.
 * @param {number[]} newOrder Новый порядок панелей.
 * @returns {number[]} Актуальный список сортировки панелей.
 */
export function checkPanelsSort(oldOrder: Panel[], newOrder?: number[]): number[];
export function checkPanelsSort(oldOrder: number[] | Panel[], newOrder: number[] = []): number[] {
  const safeNewOrder = newOrder ?? [];
  const oldOrderParsed =
    oldOrder.length > 0 && typeof oldOrder[0] !== 'number'
      ? (oldOrder as Panel[]).map((order) => order.id)
      : (oldOrder as number[]);

  const newItems = oldOrderParsed.filter((item) => !safeNewOrder.some((newOrderItem) => newOrderItem === item));
  const actualItems = safeNewOrder.filter((item) => oldOrderParsed.some((oldOrderItem) => oldOrderItem === item));

  return [...actualItems, ...newItems];
}

/**
 * Функция проверки наличия изменений в составе или порядке панелей.
 * @param {number[]} oldOrder Старый порядок панелей.
 * @param {number[]} newOrder Новый порядок панелей.
 * @returns {boolean} Был-ли изменён состав или порядок панелей.
 */
export function haveChanges(oldOrder: number[], newOrder: number[]): boolean {
  if (oldOrder.length !== newOrder.length) {
    return true;
  }

  let haveChanges = false;

  oldOrder.forEach((value, index) => {
    const newValue = newOrder[index];

    if (value !== newValue) {
      haveChanges = true;
    }
  });

  return haveChanges;
}

export async function copyOnPanel(id: number, blockObject: PanelBlock | null) {
  let panel = null as Panel | null;
  if (!blockObject) {
    return;
  }

  const block = cloneDeep(blockObject);
  block.id = Guid.newGuid().toString();

  if (PanelsState.currentPanel?.id === id) {
    panel = PanelsState.currentPanel;

    const { x, y } = getBlockLastPosition(PanelsState.currentLayout!);
    block.x = x;
    block.y = y;
    PanelsState.currentLayout!.items.push(block);
    PanelsState.replacePanelLayout(PanelsState.currentLayout!);
  } else {
    panel = PanelsState.panels.find((panel) => panel.id === id) ?? null;

    if (!panel) {
      return;
    }

    const layout = dashboardsStateToDashboards(panel);
    const { x, y } = getBlockLastPosition(layout);
    block.x = x;
    block.y = y;
    layout.items.push(block);
    await PanelsState.changePanelSettings({ id, settings: layout });
  }
}

export function getBlockLastPosition(layout: PanelLayout) {
  let items = layout.items ?? [];
  const maxRow = Math.max(...items.map((item) => item.y));
  items = items.filter((item) => item.y === maxRow);
  const maxHeight = Math.max(...items.map((item) => item.height ?? 1));

  return {
    x: 0,
    y: maxRow + maxHeight,
  };
}

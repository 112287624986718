import { IWithGraphsItemIds, MtrItemTypeEnum, StatusTypeEnum } from '@/model';
import { DateTime } from 'luxon';

export interface IGraphRootForGrid {
  /** Guid (InnerId) корня (себя же) распределения по графу графа */
  selfRootId: string;
  title: string;
  /** используемые в таблице классификаторы по этому графу */
  classifiers: IClassifierForGrid[];
}

export interface IClassifierForGrid {
  /** Guid (InnerId) корня распределения по графу графа */
  rootId: string;
  /** Guid (InnerId) узла при распределении по графу */
  id: string;
  title: string;
}

export interface IClassifierCombinationForGrid {
  nodes: IClassifierForGrid[];
  /** Полный ключ для сравнения комбинации узлов в графах, без коллизий */
  hashKey: string;
}

export enum InputDataTypeEnum {
  CommonTableNavigatable = 'CommonTableNavigatable',
  HSE = 'HSE',
  WeatherRestrictions = 'WeatherRestrictions',
}

export enum ZoomCalendar {
  decade = 'decade',
  year = 'year',
  month = 'month',
}

export interface IInputDataType {
  id: InputDataTypeEnum;
  name: string;
}

/** Узел дерева показателей */
export interface IIndicatorsTreeNode {
  /** Ключ узла в дереве показателей */
  id: string;
  /** Ключ предка-узла в дереве показателей */
  parentId: string;
  /** Числовой енум системного показателя */
  type?: MtrItemTypeEnum;
  /** Guid показателя в БД */
  indicatorId?: string;
  /** Название узла в дереве показателей */
  name: string;
  selected?: boolean;
  isDynamic?: boolean;
  /** Тип, вычислимый на фронте и не существующий на бэке - например, % Выполнено, BidOTIF */
  frontendOnly?: boolean;
  status?: StatusTypeEnum;
}

export interface IMtrItemTransformed extends IWithGraphsItemIds {
  startPeriod: DateTime;
  dateType: DateTypeEnum;
  day?: number;
  indicatorId: string;
  value?: number | null;
  /** Is there one original value in cell and it has a whole cell period */
  oneWholeValue?: boolean | null;
  /** graphsItemIds hashKey - полный ключ для сравнения комбинации узлов в графах, без коллизий */
  hashKey: string;
}

export enum DateTypeEnum {
  /** Year or custom period */
  Year,
  Month,
  Day,
}

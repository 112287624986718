import { ClassifierLight, Context } from '@/model';
import api from '@/services/api';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IndicatorsState, PanelsState, UserState } from '.';
import events from '@/services/events';
import { checkPanelsSort, setTheme } from '@/helpers';

@Module({ name: 'contexts', namespaced: true })
export default class ContextsModule extends VuexModule {
  private _loading = true;
  private _contexts: Context[] = [];
  private _currentContext: Context | null = null;
  private _rootLocation: ClassifierLight | null = null;

  public get loading(): boolean {
    return this._loading;
  }

  public get contexts(): Context[] {
    return this._contexts || [];
  }

  public get currentContext(): Context | null {
    return this._currentContext;
  }

  public get currentContextId(): number | null {
    return this.currentContext?.id || null;
  }

  public get currentContextRootLocation(): ClassifierLight | null {
    return (this.currentContext && this._rootLocation) ?? null;
  }

  @Action({ rawError: true })
  async addContext({ title }: Partial<Context>, asCurrent = true) {
    title = title?.trim();
    if (title) {
      const newContext = await api.post<Context>(`/api/contexts`, { title });
      if (newContext.id) {
        this.setContexts([newContext, ...this._contexts]);
        if (asCurrent) {
          await this.changeCurrentContext({ contextId: newContext.id });
        }
      }
    }
  }

  @Action({ rawError: true })
  async renameContext({ id: contextId, title }: Partial<Context>) {
    title = title?.trim();
    if (title) {
      await api.post(`/api/contexts/${contextId}/title`, { title } as Partial<Context>);
      this.setContextName({ id: contextId, title });
    }
  }

  @Action({ rawError: true })
  async saveContextRootLocation({ contextId, innerId }: { contextId: number; innerId: string | null }) {
    const rootLocation = await api.post<ClassifierLight | null>(
      `/api/contexts/${contextId}/root-location`,
      innerId ?? null
    );
    this.setContextRootLocation(rootLocation);
  }

  @Action({ rawError: true })
  async leaveFromContext(contextId: number) {
    const userId = UserState.user?.id;

    if (userId) {
      await api.delete(`/api/contexts/${contextId}/users/${userId}`);

      // Чтобы не выяснять, на какой контекст переключаться,
      // отдаем это на откуп серверу перезагружая контексты.
      await this.loadContexts();
    }
  }

  @Action({ rawError: true })
  async loadContexts(mobileBreakpoint = false) {
    this.setLoading(true);
    this.setContexts([]);

    try {
      const contexts = await api.get<Context[]>(`/api/contexts`);
      this.setContexts(contexts);
      await this.changeCurrentContext({ contextId: contexts[0]?.id || null, mobileBreakpoint });
    } finally {
      this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  public async loadContextRootLocation() {
    const contextId = this.currentContextId;
    let rootLocation: ClassifierLight | null = null;
    if (contextId) {
      rootLocation = await api.get<ClassifierLight | null>(`/api/contexts/${contextId}/root-location`);
    }
    this.setContextRootLocation(rootLocation ?? null);
  }

  @Action({ rawError: true })
  public async changeCurrentContext({
    contextId,
    mobileBreakpoint = false,
  }: {
    contextId: number | null;
    mobileBreakpoint?: boolean;
  }) {
    const context = this._contexts.find((x) => x.id === contextId);
    const prevContextId = this.currentContextId;
    if (context) {
      await api.post(`/api/contexts/${context.id}/current`); // Логирование входа пользователя в контекст (на сервере, автоматически)
      this.setCurrentContext(context);
      const newContextId = this.currentContextId;
      if (newContextId) {
        events.resubscribeToContext(newContextId, prevContextId);
      }
      this.updateTheme();
    } else {
      this.setCurrentContext(null);
      events.resubscribeToContext(null, prevContextId);
    }

    await UserState.loadContextRole(contextId);
    await UserState.loadContextSettings(contextId);
    await PanelsState.loadPanels(mobileBreakpoint);
    if (!mobileBreakpoint) {
      const panelsOrder = checkPanelsSort(PanelsState.panels, UserState.settings?.panels);
      UserState.updatePanelsOrder(panelsOrder);
    }
    await IndicatorsState.loadIndicators();
    await IndicatorsState.loadIndicatorsWithClassifiers();
    await this.loadContextRootLocation();
  }

  @Mutation
  private setCurrentContext(context: Context | null) {
    this._currentContext = context;
  }

  @Mutation
  private setContexts(contexts: Context[]) {
    this._contexts = contexts || [];
  }

  @Mutation
  private setLoading(value: boolean) {
    this._loading = value;
  }

  @Mutation
  private setContextName({ id: contextId, title }: Partial<Context>) {
    if (title) {
      const context = this._contexts.find((x) => x.id === contextId);
      if (context) {
        context.title = title;
      }
    }
  }

  @Mutation
  private setContextRootLocation(classifier: ClassifierLight | null) {
    this._rootLocation = classifier;
  }

  // Перенести темы в UserStore, когда тема станет привязана к пользователю.
  // Блок темы приложения.
  private _themeAccentColor = 'Blue';

  public get currentThemeId(): number {
    return this.currentContext?.themeId ?? 1;
  }

  public get currentThemeStyle(): string {
    return this.currentContext && this.currentContext.themeId === 0 ? 'dark' : 'light';
  }

  public get themeName() {
    return `${this.currentThemeStyle}${this._themeAccentColor}`;
  }

  @Action
  private updateTheme() {
    setTheme();
  }

  @Action({ rawError: true })
  async changeContextTheme({ id: contextId, themeId }: Partial<Context>) {
    if (themeId !== undefined) {
      await api.post(`/api/contexts/${contextId}/theme`, { themeId } as Partial<Context>);
      this.setContextTheme({ id: contextId, themeId });
    }
  }

  @Mutation
  private setContextTheme({ id: contextId, themeId }: Partial<Context>) {
    if (themeId !== undefined) {
      const context = this._contexts.find((x) => x.id === contextId);
      if (context) {
        context.themeId = themeId;
      }
      setTheme();
    }
  }
}

// TODO: Позже переименовать файл в что-то типа indicatorsStructure.ts

import { IIndicatorsTreeNode } from '@/components/InputData/common/models';
import { MtrItemTypeEnum } from '@/model';
import {
  IndicTreeDynamicRootId,
  IndicTreeMasterRootId,
  IndicTreeSystemRootId,
} from '@/config/constants/commonConstants';

/** Заготовка структуры дерева показателей */
export const IndicatorsTreeStructureConfig: IIndicatorsTreeNode[] = [
  {
    id: IndicTreeSystemRootId,
    parentId: IndicTreeMasterRootId,
    name: 'Системные',
  },
  {
    id: IndicTreeDynamicRootId,
    parentId: IndicTreeMasterRootId,
    name: 'Пользовательские',
  },
  // --------
  {
    id: 'Arrival',
    parentId: IndicTreeSystemRootId,
    name: 'Приход',
    selected: true,
  },
  {
    id: 'ArrivalPlan',
    parentId: 'Arrival',
    name: 'План',
    type: MtrItemTypeEnum.ArrivalPlan,
  },
  {
    id: 'ArrivalAdjustedPlan',
    parentId: 'Arrival',
    name: 'Скорректированный план',
    type: MtrItemTypeEnum.ArrivalAdjustedPlan,
  },
  {
    id: 'ArrivalDailyPlan',
    parentId: 'Arrival',
    name: 'Суточный план',
    type: MtrItemTypeEnum.ArrivalDailyPlan,
  },
  {
    id: 'ArrivalFact',
    parentId: 'Arrival',
    name: 'Факт',
    type: MtrItemTypeEnum.ArrivalFact,
  },
  // --------
  {
    id: 'Expenditure',
    parentId: IndicTreeSystemRootId,
    name: 'Расход',
    selected: true,
  },
  {
    id: 'ExpenditurePlan',
    parentId: 'Expenditure',
    name: 'План',
    type: MtrItemTypeEnum.ExpenditurePlan,
  },
  {
    id: 'ExpenditureAdjustedPlan',
    parentId: 'Expenditure',
    name: 'Скорректированный план',
    type: MtrItemTypeEnum.ExpenditureAdjustedPlan,
  },
  {
    id: 'ExpenditureDailyPlan',
    parentId: 'Expenditure',
    name: 'Суточный план',
    type: MtrItemTypeEnum.ExpenditureDailyPlan,
  },
  {
    id: 'ExpenditureFact',
    parentId: 'Expenditure',
    name: 'Факт',
    type: MtrItemTypeEnum.ExpenditureFact,
  },
  // --------
  {
    id: 'FreightTurnover',
    parentId: IndicTreeSystemRootId,
    name: 'Грузооборот',
  },
  {
    id: 'FreightTurnoverPlan',
    parentId: 'FreightTurnover',
    name: 'План',
    type: MtrItemTypeEnum.FreightTurnoverPlan,
  },
  {
    id: 'FreightTurnoverAdjustedPlan',
    parentId: 'FreightTurnover',
    name: 'Скорректированный план',
    type: MtrItemTypeEnum.FreightTurnoverAdjustedPlan,
  },
  {
    id: 'FreightTurnoverDailyPlan',
    parentId: 'FreightTurnover',
    name: 'Суточный план',
    type: MtrItemTypeEnum.FreightTurnoverDailyPlan,
  },
  {
    id: 'FreightTurnoverFact',
    parentId: 'FreightTurnover',
    name: 'Факт',
    type: MtrItemTypeEnum.FreightTurnoverFact,
  },
  {
    id: 'FreightTurnoverPerformed',
    parentId: 'FreightTurnover',
    name: 'Выполнено, %',
    type: MtrItemTypeEnum.FreightTurnoverPerformed,
    frontendOnly: true,
  },
  // --------
  {
    id: 'CargoHandling',
    parentId: IndicTreeSystemRootId,
    name: 'Грузопереработка',
  },
  {
    id: 'CargoHandlingPlan',
    parentId: 'CargoHandling',
    name: 'План',
    type: MtrItemTypeEnum.CargoHandlingPlan,
  },
  {
    id: 'CargoHandlingAdjustedPlan',
    parentId: 'CargoHandling',
    name: 'Скорректированный план',
    type: MtrItemTypeEnum.CargoHandlingAdjustedPlan,
  },
  {
    id: 'CargoHandlingDailyPlan',
    parentId: 'CargoHandling',
    name: 'Суточный план',
    type: MtrItemTypeEnum.CargoHandlingDailyPlan,
  },
  {
    id: 'CargoHandlingFact',
    parentId: 'CargoHandling',
    name: 'Факт',
    type: MtrItemTypeEnum.CargoHandlingFact,
  },
  {
    id: 'CargoHandlingPerformed',
    parentId: 'CargoHandling',
    name: 'Выполнено, %',
    type: MtrItemTypeEnum.CargoHandlingPerformed,
    frontendOnly: true,
  },
  // --------
  {
    id: 'SeasonalDelivery',
    parentId: IndicTreeSystemRootId,
    name: 'Сезонный завоз',
  },
  {
    id: 'SeasonalDeliveryInitialPlanForYear',
    parentId: 'SeasonalDelivery',
    name: 'Первоначальный план',
    type: MtrItemTypeEnum.SeasonalDeliveryInitialPlanForYear,
  },
  {
    id: 'SeasonalDeliveryPlanForMonth',
    parentId: 'SeasonalDelivery',
    name: 'План завоза',
    type: MtrItemTypeEnum.SeasonalDeliveryPlanForMonth,
  },
  {
    id: 'SeasonalDeliveryDailyPlan',
    parentId: 'SeasonalDelivery',
    name: 'Суточный план',
    type: MtrItemTypeEnum.SeasonalDeliveryDailyPlan,
  },
  {
    id: 'SeasonalDeliveryFact',
    parentId: 'SeasonalDelivery',
    name: 'Факт',
    type: MtrItemTypeEnum.SeasonalDeliveryFact,
  },
  {
    id: 'SeasonalDeliveryPerformed',
    parentId: 'SeasonalDelivery',
    name: 'Выполнено, %',
    type: MtrItemTypeEnum.SeasonalDeliveryPerformed,
    frontendOnly: true,
  },
  // --------
  {
    id: 'AccountingMtr',
    parentId: IndicTreeSystemRootId,
    name: 'Учет МТР',
  },
  {
    id: 'TotalMTR',
    parentId: 'AccountingMtr',
    name: 'Всего МТР',
  },
  {
    id: 'AccountingMtrUnusedPreviousPeriod',
    parentId: 'TotalMTR',
    name: 'Неотработанные предыдущего периода',
    type: MtrItemTypeEnum.AccountingMtrUnusedPreviousPeriod,
  },
  {
    id: 'AccountingMtrTotalSupplies',
    parentId: 'TotalMTR',
    name: 'Итого поставок',
    type: MtrItemTypeEnum.AccountingMtrTotalSupplies,
  },
  {
    id: 'AccountingMtrUncapitalize',
    parentId: 'AccountingMtr',
    name: 'Не оприходовано',
  },
  {
    id: 'AccountingMtrRegistered',
    parentId: 'AccountingMtrUncapitalize',
    name: 'Поставлено на учет',
    type: MtrItemTypeEnum.AccountingMtrRegistered,
  },
  {
    id: 'AccountingMtrProcessing',
    parentId: 'AccountingMtrUncapitalize',
    name: 'В работе',
    type: MtrItemTypeEnum.AccountingMtrProcessing,
  },
  {
    id: 'AccountingMtrUncapitalize0_14',
    parentId: 'AccountingMtrUncapitalize',
    name: '0-14',
    type: MtrItemTypeEnum.AccountingMtrUncapitalize0_14,
  },
  {
    id: 'AccountingMtrUncapitalize15_30',
    parentId: 'AccountingMtrUncapitalize',
    name: '15-30',
    type: MtrItemTypeEnum.AccountingMtrUncapitalize15_30,
  },
  {
    id: 'AccountingMtrUncapitalize31_60',
    parentId: 'AccountingMtrUncapitalize',
    name: '31-60',
    type: MtrItemTypeEnum.AccountingMtrUncapitalize31_60,
  },
  {
    id: 'AccountingMtrUncapitalize61More',
    parentId: 'AccountingMtrUncapitalize',
    name: 'Более 60',
    type: MtrItemTypeEnum.AccountingMtrUncapitalize61More,
  },
  // --------
  {
    id: 'Bid',
    parentId: IndicTreeSystemRootId,
    name: 'Заявки',
  },
  {
    id: 'BidPlan',
    parentId: 'Bid',
    name: 'План',
    type: MtrItemTypeEnum.BidPlan,
  },
  {
    id: 'BidForecast',
    parentId: 'Bid',
    name: 'Прогноз',
    type: MtrItemTypeEnum.BidForecast,
  },
  {
    id: 'BidTotalFact',
    parentId: 'Bid',
    name: 'Факт всего',
    type: MtrItemTypeEnum.BidTotalFact,
  },
  {
    id: 'BidScheduledFact',
    parentId: 'Bid',
    name: 'Факт в срок',
    type: MtrItemTypeEnum.BidScheduledFact,
  },
  {
    id: 'BidUnplanned',
    parentId: 'Bid',
    name: 'Не запланированные',
    type: MtrItemTypeEnum.BidUnplanned,
  },
  {
    id: 'BidOTIF',
    parentId: 'Bid',
    name: 'OTIF',
    type: MtrItemTypeEnum.BidOTIF,
    frontendOnly: true,
  },
  // --------
  {
    id: 'Resources',
    parentId: IndicTreeSystemRootId,
    name: 'Ресурсы',
  },
  {
    id: 'Transport',
    parentId: 'Resources',
    name: 'Транспорт',
  },
  {
    id: 'TransportPlan',
    parentId: 'Transport',
    name: 'План',
    type: MtrItemTypeEnum.TransportPlan,
  },
  {
    id: 'TransportPlanHours',
    parentId: 'Transport',
    name: 'План почасовая',
    type: MtrItemTypeEnum.TransportPlanHours,
  },
  {
    id: 'TransportPlanTrunk',
    parentId: 'Transport',
    name: 'План магистральная',
    type: MtrItemTypeEnum.TransportPlanTrunk,
  },
  {
    id: 'TransportFact',
    parentId: 'Transport',
    name: 'Факт',
    type: MtrItemTypeEnum.TransportFact,
  },
  {
    id: 'TransportFactHours',
    parentId: 'Transport',
    name: 'Факт почасовая',
    type: MtrItemTypeEnum.TransportFactHours,
  },
  {
    id: 'TransportFactTrunk',
    parentId: 'Transport',
    name: 'Факт магистральная',
    type: MtrItemTypeEnum.TransportFactTrunk,
  },
  {
    id: 'GPMP',
    parentId: 'Resources',
    name: 'ГПМ',
  },
  {
    id: 'GPMPlan',
    parentId: 'GPMP',
    name: 'План',
    type: MtrItemTypeEnum.GPMPlan,
  },
  {
    id: 'GPMFact',
    parentId: 'GPMP',
    name: 'Факт',
    type: MtrItemTypeEnum.GPMFact,
  },
  {
    id: 'LaborResources',
    parentId: 'Resources',
    name: 'Трудовые ресурсы',
  },
  {
    id: 'LaborResourcesPlan',
    parentId: 'LaborResources',
    name: 'План',
    type: MtrItemTypeEnum.LaborResourcesPlan,
  },
  {
    id: 'LaborResourcesFact',
    parentId: 'LaborResources',
    name: 'Факт',
    type: MtrItemTypeEnum.LaborResourcesFact,
  },
  // --------
  {
    id: 'FullnessBases',
    parentId: IndicTreeSystemRootId,
    name: 'Хранение на базах',
  },
  {
    id: 'FullnessBasesTotalSquare',
    parentId: 'FullnessBases',
    name: 'Общая площадь, Га',
    type: MtrItemTypeEnum.FullnessBasesTotalSquare,
  },
  {
    id: 'FullnessBasesLoaded',
    parentId: 'FullnessBases',
    name: 'Загружено, Га',
    type: MtrItemTypeEnum.FullnessBasesLoaded,
  },
  {
    id: 'FullnessBasesLoadedRubles',
    parentId: 'FullnessBases',
    name: 'Загружено, млн. руб',
    type: MtrItemTypeEnum.FullnessBasesLoadedRubles,
  },
  {
    id: 'FullnessBasesLoadedWeight',
    parentId: 'FullnessBases',
    name: 'Загружено, тонн',
    type: MtrItemTypeEnum.FullnessBasesLoadedWeight,
  },
  // --------
  {
    id: 'HSEIndicator',
    parentId: IndicTreeSystemRootId,
    name: 'HSE происшествия',
  },
  {
    id: 'HSEIndicatorDeath',
    parentId: 'HSEIndicator',
    name: 'Смерть',
    type: MtrItemTypeEnum.HSEIndicatorDeath,
  },
  {
    id: 'HSEIndicatorDisability',
    parentId: 'HSEIndicator',
    name: 'НС с ВПТ',
    type: MtrItemTypeEnum.HSEIndicatorDisability,
  },
  {
    id: 'HSEIndicatorMedicalCare',
    parentId: 'HSEIndicator',
    name: 'МП на производстве + ПП',
    type: MtrItemTypeEnum.HSEIndicatorMedicalCare,
  },
  {
    id: 'HSEIndicatorNearMiss',
    parentId: 'HSEIndicator',
    name: 'ПбП + ДТП + Алко + Аварии',
    type: MtrItemTypeEnum.HSEIndicatorNearMiss,
  },
  {
    id: 'HSEIndicatorDangerWarning',
    parentId: 'HSEIndicator',
    name: 'Опасные случаи и действия',
    type: MtrItemTypeEnum.HSEIndicatorDangerWarning,
  },
  // --------
  {
    id: 'ProjectIndicators',
    parentId: IndicTreeSystemRootId,
    name: 'Проектные показатели',
  },
  {
    id: 'ProjectIndicatorPlan',
    parentId: 'ProjectIndicators',
    name: 'Плановое количество пользователей',
    type: MtrItemTypeEnum.ProjectIndicatorPlan,
  },
  {
    id: 'ProjectIndicatorFact',
    parentId: 'ProjectIndicators',
    name: 'Фактическое количество пользователей',
    type: MtrItemTypeEnum.ProjectIndicatorFact,
  },
  // --------
  {
    id: 'Product',
    parentId: IndicTreeSystemRootId,
    name: 'Продукты',
  },
  {
    id: 'ProductPlan',
    parentId: 'Product',
    name: 'План',
    type: MtrItemTypeEnum.ProductPlan,
  },
  {
    id: 'ProductFact',
    parentId: 'Product',
    name: 'Факт',
    type: MtrItemTypeEnum.ProductFact,
  },
  // --------
];

import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createBlock(_component_InlineSvg, {
    class: _normalizeClass({ 's-icon': _ctx.defaultStyling }),
    title: _ctx.title,
    src: require(`@/assets/${_ctx.name}.svg`)
  }, null, 8, ["class", "title", "src"]))
}
import { ITreeListSelectedItem } from '@/components/controls/filterTree/types';
import { InputDataTypeEnum, IIndicatorsTreeNode } from '@/components/InputData/common/models';
import {
  AppDataTypeEnum,
  AppGroupByEnum,
  BlockConfig,
  ChartColumnEnum,
  ClassifierLight,
  ClassifierTypeEnum,
  FolderSettings,
  GraphsFiltersModeEnum,
  MtrItemTypeEnum,
  RootClassifiersValidStateEnum,
} from '@/model';
import { DividerConfig } from '../divider';

//  Vue-Responsive-Dash Layout сomponent Interface, default property in component
//
// Name	            Type	            Required	Default	        Description
// breakpoint	    string	            yes		                    typically describing the breakpoint Size (i.e. xl, lg etc)
// numberOfCols	    number	            no	        12	            Number of columns allowed
// margin	        object	            no	        { x:10, y:10 }	Distance in pixels between DashItems
// breakpointWidth	number	            no		                    Width used to determine which layout is most appropriate for the screen size
// useCssTransforms	boolean	            no	        false	        Use translate3d instead of direct top left css properties
// compact	        boolean	            no	        true	        Automatically move items up if there is space available
// colWidth	        boolean | number	no	        false	        When set to a number the column width is statically set to this value
// maxColWidth	    boolean | number	no	        false	        When set to a number the colWidth will never be greater than this number
// minColWidth	    boolean | number	no	        false	        When set to a number the colWidth will never be less than this number
// rowHeight	    boolean | number	no	        false	        When set to a number the row height will be set to this number (as opposed to being set to the colWidth to keep the items square)
// maxRowHeight	    boolean | number	no	        false	        When set to a number the rowHeight will never be greater than this number
// minRowHeight	    boolean | number	no	        false	        When set to a number the rowHeight will never be less than this number
export interface PanelLayout {
  themeId?: number;
  /**
   * @deprecated
   * TODO: Устаревшая ролевая модель.
   * Будет удалено после перенастройки администраторами контекстов разрешений на панели.
   * Пока оставлено в режиме для чтения или для удаления, не для установки.
   */
  roleId?: number;
  userId?: number;
  id: number;
  name: string;
  breakpoint: string;
  numberOfCols?: number;
  margin?: PanelMargin;
  breakpointWidth?: number;
  useCssTransforms?: boolean;
  compact?: boolean;
  colWidth?: boolean | number;
  maxColWidth?: boolean | number;
  minColWidth?: boolean | number;
  rowHeight?: boolean | number;
  maxRowHeight?: boolean | number;
  minRowHeight?: boolean | number;
  isMobile: boolean;
  items: PanelBlock[];
}

export interface PanelLayoutDto {
  margin?: PanelMargin;
  themeId?: number;
  isMobile: boolean;
  colCount?: number;
  rowHeight?: number;
  breakpoint: string;
}

export interface PanelMargin {
  x: number;
  y: number;
}

export interface PanelLayoutSavedState {
  id: number;
  name: string;
  breakpoint: string;
  numberOfCols?: number;
  margin?: PanelMargin;
  rowHeight?: boolean | number;
  isMobile: boolean;
  items: PanelItemSavedState[];
  themeId?: number;
}

//  Vue-Responsive-Dash DashboardItem сomponent Interface, default property in component
// Name	            Type	            Required	Default	        Description
// id	            [Number, String]	true		                UUID for the item
// x	            Number	            false	    0
// y	            Number	            false	    0
// width	        Number	            false	    1	            Width (in col units)
// maxWidth	        [Number, Boolean]	false	    false	        Max Width (in col units). When not a number it is ignored
// minWidth	        [Number, Boolean]	false	    1	            Min Width (in col units). When not a number it is ignored
// height	        Number	            false	    1	            Height (in row units)
// maxHeight	    [Number, Boolean]	false	    false	        Max Height (in row units). When not a number it is ignored
// minHeight	    [Number, Boolean]	false	    1	            Min Height (in row units). When not a number it is ignored
// draggable	    Boolean	            false	    true	        If the item can be dragged
// resizable	    Boolean	            false	    true	        If the item can be resized
// resizeEdges	    String	            false	    "bottom right"	The edges it can be resized (experimental for all options apart from bottom right)
// resizeHandleSize	Number	            false	    8	            The area where resize can be selected on the item
// draggableZIndex	Number	            false	    1	            The zIndex applied to the draggable area to make sure it is above the items in the slot
// resizableZIndex	Number          	false	    1	            The zIndex applied to each resizeable area to make sure it is above the items in the main slot
// moveHold	        Number	            false	    0	            The amount of time in ms required to hold the item before it can be moved
// resizeHold	    Number	            false	    0	            The amount of time in ms required to hold the item before it can be resized
// dragAllowFrom	String	            false	    null	        Custom Selector which the drag/move event can start from
// dragIgnoreFrom	String	            false	    null	        Custom Selector to prevent actions from starting if the pointer went down on an element matching the given selector or HTMLElement.
// locked	        Boolean	            false	    false	        A Locked item will remain in position and will not move up when there is space above. It is also not possible to move or resize a locked item
export interface PanelBlock {
  id: string;
  x: number;
  y: number;
  width?: number;
  maxWidth?: boolean | number;
  minWidth?: boolean | number;
  defaultMobileWidth?: number;
  height?: number;
  maxHeight?: boolean | number;
  minHeight?: boolean | number;
  defaultMobileHeight?: number;
  draggable?: boolean;
  resizable?: boolean;
  resizeEdges?: string;
  resizeHandleSize?: number;
  draggableZIndex?: number;
  resizableZIndex?: number;
  moveHold?: number;
  resizeHold?: number;
  dragAllowFrom?: string;
  dragIgnoreFrom?: string;
  locked?: boolean;
  moduleId: string;
  config?: IModuleConfig;
  series?: IModuleSeries[];
}

export interface PanelItemSavedState {
  id: string;
  x: number;
  y: number;
  width?: number;
  defaultMobileWidth?: number;
  height?: number;
  defaultMobileHeight?: number;
  locked?: boolean;
  moduleId: string;
  config?: IModuleConfig;
  series?: IModuleSeries[];
}

export interface PanelBlockConfig {
  panelBlockId: string;
  config: IModuleConfig;
  series?: IModuleSeries[];
  dynamicSeries?: IModuleSeries[];
}

export interface IItemSize {
  width: number;
  height: number;
}

export interface IModuleItem {
  id: string;
  name: string;
  defaultMinHeight?: number;
  defaultMinWidth?: number;
  defaultMaxHeight?: number;
  defaultMaxWidth?: number;
  defaultHeight: number;
  defaultWidth: number;
  defaultMobileHeight: number;
  defaultMobileWidth: number;
  /** TODO: оставить только ModuleTypeEnum и поправить все места до использования енума */
  type: ModuleTypeEnum | string;
  defaultConfig?: IModuleConfig;
  defaultSeries?: IModuleSeries[];
  doNotRepaint?: boolean;
  noBackground?: boolean;
}

export interface ProjectIndicatorConfig {
  separatorName?: string;
}

/**
 * Что сохраняется в БД, а что нет, - смотреть в
 * panelUtils.ts: saveItemConfig() и loadItemConfig()
 */
export interface IModuleConfig {
  /** Унифицированный конфиг инфоблока (пока юзаем отсюда, потом заменим весь объект на него). */
  unifiedConfig?: BlockConfig;
  dividerConfig?: DividerConfig;
  folderSettings?: FolderSettings;
  columns?: ChartColumnEnum;
  allowGroupBy?: AppGroupByEnum[];
  /** TODO: оставить только AppDataTypeEnum и поправить все места до использования енума */
  type?: AppDataTypeEnum | string;
  period?: ChartPeriodEnum;
  startPeriod?: string;
  duration?: string;
  allowPeriods?: ChartPeriodEnum[];
  selectedTimeSpans?: string[];
  /** Состояние селекции показателей. Optionally saveable */
  indicatorsSelection?: IIndicatorsTreeNode[];
  /** Store indicatorsSelection in DB if is true. Static config, non-saveable */
  storeIndicatorsSelection?: boolean;
  inputTableConfig?: IInputTableConfig;
  projectIndicatorConfig?: ProjectIndicatorConfig;
  customParams?: any;
  /** Настройка фильтра по типу Локации/Классификатора. Static config, non-saveable */
  allowFilters?: ChartColumnEnum;
  /** TODO: Улучшить. По классификаторам: Статические показатели для модуля. Static config, non-saveable */
  systemIndicators?: MtrItemTypeEnum[];
  /** По классификаторам: Динамические показатели для модуля. Dynamic config, non-saveable */
  indicatorIds?: string[];
  /** По классификаторам: Static config, non-saveable */
  graphsFiltersMode?: GraphsFiltersModeEnum;
  /** конфиг по классификаторам. Saveable */
  byClassifiers?: IByClassifiersConfig;
  /** список корневых классификаторов. Dynamic config, non-saveable */
  rootClassifiers?: ClassifierLight[];
  /** Текущее состояние валидности корневых классификаторов. Dynamic config, non-saveable */
  rootClassifiersState?: IRootClassifiersState;
}

/** Текущее состояние валидности корневых классификаторов */
export interface IRootClassifiersState {
  isValid: boolean;
  state: RootClassifiersValidStateEnum;
  errorMessage: string | undefined;
}

/** Конфиг по классификаторам */
export interface IByClassifiersConfig {
  /** Guid - InnerId корня текущего активного графа */
  groupByRootId?: string;
  /** Конфиги по отдельным классификаторам-корням */
  byRoots: IByClassifierConfig[];
  /** узел для возврата домой */
  byHome?: IHomeReturned;
}

/** интерфейс для сохранения типа классификатора и его id */
export interface IHomeReturned {
  rootId?: string;
  column?: ChartColumnEnum;
}

/** Конфиг по одному классификатору-корню */
export interface IByClassifierConfig {
  /** Guid - InnerId корня графа; или null для полного графа */
  rootId: string | null;
  navigationHierarchy?: IParentOnGraph[];
  excludeItems?: ITreeListSelectedItem[];
  /** @deprecated Будет удалено */
  filterByType?: (ClassifierTypeEnum | string)[];
}

export interface IParentOnGraph {
  title: string;
  id: string;
}

export interface IModuleSeries {
  id: string;
  value?: boolean;
  name: string;
  color?: string;
  stack?: string;
  type?: ChartSerieType;
  hideLabel?: boolean;
  pane?: string;
  border?: boolean;
  point?: boolean;
}

export type ChartSerieType =
  | 'area'
  | 'bar'
  | 'bubble'
  | 'candlestick'
  | 'fullstackedarea'
  | 'fullstackedbar'
  | 'fullstackedline'
  | 'fullstackedspline'
  | 'fullstackedsplinearea'
  | 'line'
  | 'rangearea'
  | 'rangebar'
  | 'scatter'
  | 'spline'
  | 'splinearea'
  | 'stackedarea'
  | 'stackedbar'
  | 'stackedline'
  | 'stackedspline'
  | 'stackedsplinearea'
  | 'steparea'
  | 'stepline'
  | 'stock';

export interface IInputTableConfig {
  classificationType?: InputTableClassificationEnum;
  childrenSum?: InputTableChildrenSumEnum;
  periodType?: DatePeriodTypeEnum;
  period?: IPeriod;
  inputDataType?: InputDataTypeEnum;
}

export interface IPeriod {
  startDate: string;
  endDate: string;
}

export enum DatePeriodTypeEnum {
  Date,
  Period,
}

export enum ChartPeriodEnum {
  Day = 1,
  Week = 2,
  TwoWeeks = 3,
  Month = 4,
  Quarter = 5,
  HalfYear = 6,
  Year = 7,
}

export enum InputTableClassificationEnum {
  /** Задействованная - только комбинации с наличием введенных данных по узлу */
  Involved = 'Involved',
  /** Задействованная по дочерним - только комбинации с наличием введенных данных по узлу или дочерним узлам */
  InvolvedByChildren = 'InvolvedByChildren',
  /** Полная - все комбинации независимо от наличия введенных данных */
  Full = 'Full',
}

export enum InputTableChildrenSumEnum {
  /** Отключено, группировать на сервере, кастомные периоды */
  None = 'None',
  /** Полная, группировать на сервере, кастомные периоды - по всем дочерним вершинам вглубь до листьев графа, фильтры не учитываются */
  Full = 'Full',
}

export interface DurationPickerData {
  text: string;
  chartPeriod: ChartPeriodEnum;
  groupBy: ChartPeriodEnum;
  defaultPicker?: boolean;
}

/** TODO: Проверить, можно ли Удалить по переезду на классификаторы */
export interface GraphGroupByDataItem {
  text: string;
  column: ChartColumnEnum;
  groupBy: AppGroupByEnum;
  defaultPicker?: boolean;
}

/**
 * @deprecated Будет удалено.
 * интерфейс для фильтра классификаторов по типу классификатора
 */
export interface ClassifierTypesFilterOption {
  text: string;
  type: ClassifierTypeEnum | string;
}

export interface InputTableClassificationOption {
  text: string;
  description?: string;
  classificationType: InputTableClassificationEnum;
  defaultPicker?: boolean;
}

export interface InputTableChildrenSumOption {
  text: string;
  description?: string;
  sumType: InputTableChildrenSumEnum;
  defaultPicker?: boolean;
}

/** Используется для автообновления данных блока при событии обновления данных на сервере */
export interface IBasedOnDataModule {
  moduleType: ModuleTypeEnum;
  moduleDataType: ModuleDataTypeEnum;
  /** Системные показатели модуля */
  systemTypes: MtrItemTypeEnum[] | null;
}

export enum ModuleTypeEnum {
  // v1 -> v2:
  InputDataComponent = 'InputDataComponent',
  ArrivalChart = 'ArrivalChart',
  ArrivalChartRotated = 'ArrivalChartRotated',
  ExpenditureChart = 'ExpenditureChart',
  CargoChart = 'CargoChart',
  HSEChart = 'HSEChart',
  OTIFValues = 'OTIFValues',
  SeasonalDeliveryChart = 'SeasonalDeliveryChart',
  SeasonalDeliveryAnalyticsChart = 'SeasonalDeliveryAnalyticsChart',
  AccountingMtr = 'AccountingMtr',
  TransportResourcesChart = 'TransportResourcesChart',
  GPMResourcesChart = 'GPMResourcesChart',
  LaborResourcesChart = 'LaborResourcesChart',
  ExecutionOfOrdersChart = 'ExecutionOfOrdersChart',
  FullnessBasesBySquareChart = 'FullnessBasesBySquareChart',
  FullnessBasesByCurrencyChart = 'FullnessBasesByCurrencyChart',
  FullnessBasesByWeightChart = 'FullnessBasesByWeightChart',
  WeatherRestrictionsList = 'WeatherRestrictionsList',
  WeatherParamsList = 'WeatherParamsList',
  ProjectIndicatorComponent = 'ProjectIndicatorComponent',
  ProjectIndicatorGroupProjects = 'ProjectIndicatorGroupProjects',
  ProjectIndicatorGroupProjectsTotal = 'ProjectIndicatorGroupProjectsTotal',
  ProjectIndicatorGroupUsers = 'ProjectIndicatorGroupUsers',
  ProjectIndicatorGroupUsersTotal = 'ProjectIndicatorGroupUsersTotal',
  ProjectDynamicsUsersChart = 'ProjectDynamicsUsersChart',
  // same for v1 and v2:
  DirectoryGraph = 'DirectoryGraph',
  DirectoryGraphAsList = 'DirectoryGraphAsList',
  Separator = 'Separator',
  TextBlock = 'TextBlock',
  // new for v2:
  IndicatorClassifiersComponent = 'IndicatorClassifiersComponent',
  UnifiedBlock = 'UnifiedBlock',
  UnifiedBlockMini = 'UnifiedBlockMini',
  PanelView = 'PanelView',
  Divider = 'Divider',
  Comments = 'Comments',
}

export enum ModuleDataTypeEnum {
  IndicatorData = 'IndicatorData',
  WeatherParam = 'WeatherParam',
  WeatherRestriction = 'WeatherRestriction',
  // NOTE: Can be extended in the future:
  // Classifier = 'Classifier',
}

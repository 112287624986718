import { IPyramidalDiagramValue } from '@/components/Charts/PyramidalDiagram/types';
import { IWithGraphDataFilters } from '@/model';

export enum HseIncidentTypeEnum {
  Death = 1,
  Disability = 2,
  MedicalCare = 3,
  NearMiss = 4,
  DangerWarning = 5,
}

export interface IHSELastIncident {
  dateTime: string;
  type: string;
  text: string;
  locationName: string;
}

export interface IHSELastIncidentResponse {
  category: string;
  dateTime: string;
  text: string;
}

export interface IHSEUnit {
  type: string;
  count: number;
}

export interface IHSEInputDataRequest extends IWithGraphDataFilters {
  startDate: string;
  endDate: string;
}

export interface IHSEInputDataAddRequest {
  classifierId: string;
  dateTime: string;
  count: number;
  type: string;
  text: string;
}

export interface IHSEInputDataUpdateRequest {
  id: string;
  classifierId: string;
  dateTime: string;
  count: number;
  type: string;
  text: string;
}

export class IHSEChartData implements IPyramidalDiagramValue {
  description!: string;
  count!: number;
  color!: string;
}

export interface IHSEChartDataRequest extends IWithGraphDataFilters {
  startPeriod: string;
  endPeriod: string;
  lastIncidentTypes?: string[] | null;
}

export interface IHSEInputDataResponse {
  items: IHSEInputDataItemResponse[];
}

export interface IHSEInputDataItemResponse {
  id: string;
  classifierId: string;
  dateTime: Date;
  type: HseIncidentTypeEnum;
  text: string;
  count: number;
}

export interface IHSEChartDataResponse {
  daysWithoutIncident: number | null;
  lastIncident: IHSELastIncident;
  units: IHSEUnit[];
}

export interface IHSEInputDataItem {
  id: string;
  classifierId: string;
  dateTime: Date;
  type: HseIncidentTypeEnum;
  text: string;
  count: number;
}

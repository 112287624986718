import { ChartColumnEnum, IModuleConfig } from '@/model';
import { setModuleClassifiers } from './indicatorClassifierUtils';

export interface ChartStateChangeResult {
  isColumnChanged: boolean;
  isGroupByRootChanged: boolean;
  isChanged: boolean;
}

export class ChartSettingsTracker {
  private config: IModuleConfig;
  private prevColumn?: ChartColumnEnum;
  private prevGroupByRoot?: string;

  constructor(config: IModuleConfig) {
    this.config = config;
    this.trackChanges();
  }

  public trackChanges(): ChartStateChangeResult {
    const config = this.config;
    const newColumn = config.columns;
    const newGroupByRoot = config.byClassifiers?.groupByRootId;

    const isColumnChanged = Boolean(this.prevColumn !== newColumn);
    const isGroupByRootChanged = Boolean(this.prevGroupByRoot !== newGroupByRoot);

    this.prevColumn = newColumn;
    this.prevGroupByRoot = newGroupByRoot;

    setModuleClassifiers(this.config);

    return {
      isColumnChanged,
      isGroupByRootChanged,
      isChanged: isColumnChanged || isGroupByRootChanged,
    };
  }
}

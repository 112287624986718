export enum LogEventDataKind {
  /** Пользователь */
  User = 'User',
  /** Контекст данных */
  Context = 'Context',
  /** Роль в контексте */
  Role = 'Role',
  Panel = 'Panel',
  /** Введенные данные по показателям */
  IndicatorData = 'IndicatorData',
  Other = 'Other',
}

import { ThemeColorsBase } from '@/plugins/types';

export const base: ThemeColorsBase = {
  background: '#F8F8F8',
  surface: '#FFFFFF',
  error: '#F44336',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FB8C00',
  'secondary-text': '#858585',
  'secondary-sheet': '#696969',
  'secondary-sheet-darken': '#F1F1F1',
  'card-darken': '#E9E9E9',
  'input-background': '#E5E5E5',
  'secondary-background-transparent': '#000000',
  'secondary-divider-transparent': '#FFF',
  'primary-text-transparent': '#e2e2e2',
};

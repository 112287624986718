import { Theme } from '@/plugins/types';
import { base } from './base';

export const darkViolet: Theme = {
  dark: true,
  light: false,
  colors: {
    ...base,
    primary: '#3F51B5',
    'primary-darken-1': '#3F51B5',
    secondary: '#3F51B5',
    'secondary-darken-1': '#3F51B5',
  },
};

import { ref } from 'vue';

export default function useAppUpdate() {
  const refreshing = ref(false);
  const registration = ref(null as null | ServiceWorkerRegistration);
  const updateExists = ref(false);

  // Store the SW registration so we can send it a message
  // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
  // To alert the user there is an update they need to refresh for
  const updateAvailable = (event: any) => {
    registration.value = event.detail;
    updateExists.value = true;
  };

  // Listen for our custom event from the SW registration
  document.addEventListener('swUpdated', updateAvailable, { once: true });

  if (navigator?.serviceWorker) {
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing.value) return;
      refreshing.value = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  // Called when the user accepts the update
  const refreshApp = () => {
    updateExists.value = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!registration.value || !registration.value.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
  };

  return {
    refreshing,
    updateExists,
    refreshApp,
  };
}

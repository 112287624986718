import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ name: 'device', namespaced: true })
export default class DeviceModule extends VuexModule {
  private _compactView = false;

  public get compactView(): boolean {
    return this._compactView;
  }

  @Mutation
  public setCompactView(mobile: boolean) {
    this._compactView = mobile;
  }
}

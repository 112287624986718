
import { Options, Vue } from 'vue-class-component';
import Icon from './Icon.vue';
import Label from './Label.vue';

@Options({
  components: {
    Icon,
    Label,
  },
  props: {
    icon: String,
    text: [String, Number],
    title: String,
    isActive: Boolean,
  },
})
export default class Button extends Vue {
  public icon?: string;
  public text?: string | number;
  public title?: string;
  public isActive?: boolean;

  public get haveListeners(): boolean {
    return Object.keys(this.$attrs).length > 0;
  }
}

import { Theme } from '@/plugins/types';
import { base } from './base';

export const darkGreen: Theme = {
  dark: true,
  light: false,
  colors: {
    ...base,
    primary: '#4CAF50',
    'primary-darken-1': '#4CAF50',
    secondary: '#4CAF50',
    'secondary-darken-1': '#4CAF50',
  },
};

import { Theme } from '@/plugins/types';
import { base } from './base';

export const darkPurple: Theme = {
  dark: true,
  light: false,
  colors: {
    ...base,
    primary: '#9C27B0',
    'primary-darken-1': '#9C27B0',
    secondary: '#9C27B0',
    'secondary-darken-1': '#9C27B0',
  },
};

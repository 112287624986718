/**
 * NOTE: Можно переименовать енум иначе. Например, Ability. На бэке называется AbilityEnum.
 * Вид возможности (разрешения) для роли в контексте на конкретное действие в системе.
 * Не хранится в БД, используется для проверки разрешений в конкретных местах и случаях.
 */
export enum RequiredPermission {
  /** Мочь всё и везде (администратор контекста) */
  DoEverything = 'DoEverything',

  /** Классификаторы. Видеть */
  ViewClassifiers = 'ViewClassifiers',
  /** Классификаторы. Создание, редактирование, архивирование, восстановление, смена связей внутри доступного графа, но без права редактировать корни графов. */
  EditClassifiers = 'EditClassifiers',

  /** Чтение данных по показателям (+ Погода, Погодные ограничения, HSE происшествия) на классификаторах */
  ViewValues = 'ViewValues',
  /** Ввод (редактирование, удаление) данных по показателям (+ Погода, Погодные ограничения, HSE происшествия) на классификаторах */
  EditValues = 'EditValues',

  /** Создание панелей (личных) */
  CreatePanels = 'CreatePanels',
  /** Просмотр панелей на классификаторах */
  ViewPanels = 'ViewPanels',
  /** Редактирование (удаление) панелей на классификаторах */
  EditPanels = 'EditPanels',
  /** Редактирование тегов панелей на классификаторах (для настройки общего доступа, шэринга) */
  EditPanelTags = 'EditPanelTags',

  /** Создание личных оповещений */
  CreateOwnNotifications = 'CreateOwnNotifications',
}

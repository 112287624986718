
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    titleTextSize: {
      type: Number,
      requred: true,
    },
  },
})
export default class Toolbar extends Vue {
  public title!: string | null;
  public subtitle!: string | null;
  public titleTextSize!: number;

  public get style() {
    return `font-size: ${this.titleTextSize}px;`;
  }
}

import { remoteHost } from '@/config';
import { ConnectionSettings } from '@/model';
import { Config } from './types';

class Endpoint {
  private _config: Config = {};

  public async startupAsync(): Promise<void> {
    const authConfig = await this.getAuthConfig();
    this._config.Auth = authConfig;
  }

  public get config(): Config {
    return this._config;
  }

  private async getAuthConfig(): Promise<ConnectionSettings> {
    const response = await fetch(`${remoteHost}/supply-auth/config`);
    const settings = await response.text();
    const result = JSON.parse(settings) as ConnectionSettings;
    return result;
  }
}

export default new Endpoint();

import { BlockConfig, TypeEnum, DynamicType, ChartTypeEnum, DataRound } from '@/model';
import { DateTime } from 'luxon';

export const defaultUnifiedConfig = {
  name: 'Новый инфоблок',
  type: ChartTypeEnum.card,
  dataRound: DataRound.hundreds,
  canDrillDown: false,
  filters: {
    period: {
      weeks: 1,
      endDate: DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).plus({ days: 1 }).toUTC(),
    },
    classifierIds: [],
    indicators: [],
  },
  height: 3,
  width: 3,
  description: '',
  showClassifier: false,
  showDelta: false,
  showDescription: false,
  showIndicator: false,
  showPercent: false,
  showSparkline: false,
  showTargetDynamics: false,
  showTarget: false,
  showUnit: false,
  aggregateChildren: false,
  indicatorSettings: {
    visible: false,
    type: TypeEnum.dynamics,
    positiveDynamicType: DynamicType.green,
  },
  visuals: {
    mainIndicatorType: 'value',
    mainIndicatorFontSize: 40,
  },
} as BlockConfig;

/**
 * Вид возможности (разрешения) для роли в контексте. Для хранения в БД. На бэке называется PermissionEnum.
 * Разные разрешения для случаев:
 * 1) Действие_X доступно без ограничений;
 * 2) Действие_X доступно от таких-то узлов-родителей;
 * 3) Действие_X доступно с такой-то спецификой (пока такого нет, но может добавиться позже).
 */
export enum Permission {
  /** Мочь все и везде (администратор контекста) */
  DoEverything = 'DoEverything',

  /** Классификаторы. Видеть. Все */
  ViewClassifiers = 'ViewClassifiers',
  /** Классификаторы. Видеть. Список узлов-родителей */
  ViewClassifiersStarting = 'ViewClassifiersStarting',
  /**
   * Классификаторы. Создание, редактирование, архивирование, восстановление, смена связей внутри доступного графа,
   * но без права редактировать корни графов. Все
   */
  EditClassifiers = 'EditClassifiers',
  /**
   * Классификаторы. Создание, редактирование, архивирование, восстановление, смена связей внутри доступного графа,
   * но без права редактировать корни графов. Список узлов-родителей
   */
  EditClassifiersStarting = 'EditClassifiersStarting',

  /** Чтение данных по показателям (+ Погода, Погодные ограничения, HSE происшествия) на классификаторах. Все */
  ViewValues = 'ViewValues',
  /** Чтение данных по показателям (+ Погода, Погодные ограничения, HSE происшествия) на классификаторах. Список узлов-родителей */
  ViewValuesClassifiedStarting = 'ViewValuesClassifiedStarting',
  /** Ввод (редактирование, удаление) данных по показателям (+ Погода, Погодные ограничения, HSE происшествия) на классификаторах. Все */
  EditValues = 'EditValues',
  /** Ввод (редактирование, удаление) данных по показателям (+ Погода, Погодные ограничения, HSE происшествия) на классификаторах. Список узлов-родителей */
  EditValuesClassifiedStarting = 'EditValuesClassifiedStarting',

  /** Создание панелей (личных) */
  CreatePanels = 'CreatePanels',
  /** Просмотр панелей на классификаторах. Список узлов-родителей */
  ViewPanelsClassifiedStarting = 'ViewPanelsClassifiedStarting',
  /** Редактирование (удаление) панелей на классификаторах. Список узлов-родителей */
  EditPanelsClassifiedStarting = 'EditPanelsClassifiedStarting',
  /** Редактирование тегов панелей на классификаторах (для настройки общего доступа, шэринга). Список узлов-родителей */
  EditPanelTagsClassifiedStarting = 'EditPanelTagsClassifiedStarting',

  /** Создание личных оповещений */
  CreateOwnNotifications = 'CreateOwnNotifications',
  /** Создание оповещений для других. Список узлов-родителей */
  CreateNotifications = 'CreateNotifications',

  /** Просмотр логов системы */
  ViewLogs = 'ViewLogs',
}

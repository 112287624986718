import { createRouter, createWebHistory } from 'vue-router';

const mainView = () => import('../views/Index.vue');
const error404View = () => import('../views/NotFound.vue');

/**
 * Aplication routes.
 */
const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    component: error404View,
  },
  {
    path: '/contexts/:contextId/panels/:panelId',
    name: 'panel',
    component: mainView,
  },
  {
    path: '/contexts/:contextId',
    name: 'context',
    component: mainView,
  },
  {
    path: '/',
    component: mainView,
  },
];

export default function useRouter(app: any) {
  const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory('/app'),
    routes, // short for `routes: routes`
  });

  app.use(router);
}

import { getBaseUrl, getLocationOrigin } from '@/helpers/utils';

// https://cli.vuejs.org/guide/mode-and-env.html#environment-variables
// If project is build use 'serve' command (isDev), automatically will
// be used variables from .env file.
// You can locally override them in .env.local file, and this will be ignored by git.
const isDev = process.env.NODE_ENV === 'development';

export const remoteHost = isDev ? process.env.VUE_APP_REMOTE_HOST : getLocationOrigin();

export const applicationHost = getBaseUrl();

export const events = {
  signalRReconnectTimeout: 5 * 1000, // 5 seconds
  signalRestoreConnectionTimeout: 60000, // 1 minute
  countTryConnection: 3,
};

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62e69f1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Label = _resolveComponent("Label")!

  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: ["s-button", {
      's-button--clickable': !_ctx.icon,
      's-button--active': _ctx.isActive,
      's-button--active-text': !_ctx.icon && _ctx.isActive,
    }],
    title: _ctx.title,
    disabled: !_ctx.haveListeners
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            name: _ctx.icon
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      (_ctx.text)
        ? (_openBlock(), _createBlock(_component_Label, {
            key: 1,
            text: _ctx.text
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ], true)
  ], 16, _hoisted_1))
}
import Icon from './common/Icon.vue';
import Block from './block/Block.vue';
import Label from './common/Label.vue';
import Button from './common/Button.vue';

import InlineSvg from 'vue-inline-svg';

export function registerCommonControls(app: any) {
  app.component('Icon', Icon);
  app.component('Block', Block);
  app.component('Label', Label);
  app.component('Button', Button);
  app.component('iSVG', InlineSvg);
}

import { IWithGraphDataFilters, StatusTypeEnum } from '@/model';

/** Тип системных показателей как число */
export enum MtrItemTypeEnum {
  /** "Факт", вводится за сутки */
  CargoHandlingFact = 1,
  /** "План", вводится за месяц */
  CargoHandlingPlan = 2,
  /** "Скорректированный план", вводится за месяц */
  CargoHandlingAdjustedPlan = 3,
  /** "Суточный план", вводится за сутки */
  CargoHandlingDailyPlan = 4,
  /** "Выполнено, %". Не вводится, не существует на бэке, вычисляется на фронте */
  CargoHandlingPerformed = 9114,

  /** "Факт", вводится за сутки */
  FreightTurnoverFact = 5,
  /** "План", вводится за месяц */
  FreightTurnoverPlan = 6,
  /** "Скорректированный план", вводится за месяц */
  FreightTurnoverAdjustedPlan = 7,
  /** "Суточный план", вводится за сутки */
  FreightTurnoverDailyPlan = 8,
  /** "Выполнено, %". Не вводится, не существует на бэке, вычисляется на фронте */
  FreightTurnoverPerformed = 9118,

  /** "Факт", вводится за сутки */
  ArrivalFact = 300,
  /** "План", вводится за месяц */
  ArrivalPlan = 301,
  /** "Скорректированный план", вводится за месяц */
  ArrivalAdjustedPlan = 302,
  /** "Суточный план", вводится за сутки */
  ArrivalDailyPlan = 303,

  /** "Факт", вводится за сутки */
  ExpenditureFact = 304,
  /** "План", вводится за месяц */
  ExpenditurePlan = 305,
  /** "Скорректированный план", вводится за месяц */
  ExpenditureAdjustedPlan = 306,
  /** "Суточный план", вводится за сутки */
  ExpenditureDailyPlan = 307,

  /** "Факт", вводится за сутки. За месяц и за год суммируется. Накопительный фактический завоз на отчетную дату */
  SeasonalDeliveryFact = 9,
  /** "План завоза", вводится за месяц. План завоза за месяц */
  SeasonalDeliveryPlanForMonth = 11,
  /** "Суточный план", вводится за сутки. Суточный план за отчетные сутки */
  SeasonalDeliveryDailyPlan = 12,
  /** "Первоначальный план", вводится за год */
  SeasonalDeliveryInitialPlanForYear = 115,
  /** "Выполнено, %". Не вводится, не существует на бэке, вычисляется на фронте. Процент выполненного завоза от плана */
  SeasonalDeliveryPerformed = 9112,

  /** Не используется в таблице ввода. Используется в ответе данных для графика и на нем отображается. Для года суммируются месячные планы, в том числе будущие завозы */
  SeasonalDeliveryPlanForYear = 113,
  /** Не используется в таблице ввода. Используется в ответе данных для графика и на нем отображается. Фактический завоз на отчетную дату */
  SeasonalDeliveryDailyFact = 114,
  /** Не используется в таблице ввода. Используется в ответе данных для графика и на нем отображается. Накопительный суточный план */
  SeasonalDeliveryPlan = 116,

  /** "Итого поставок" (Учет МТР > Всего МТР) */
  AccountingMtrTotalSupplies = 15,
  /** "В работе" (Учет МТР > Не оприходовано) */
  AccountingMtrProcessing = 23,
  /** "0-14" (Учет МТР > Не оприходовано) */
  AccountingMtrUncapitalize0_14 = 24,
  /** "15-30" (Учет МТР > Не оприходовано) */
  AccountingMtrUncapitalize15_30 = 25,
  /** "31-60" (Учет МТР > Не оприходовано) */
  AccountingMtrUncapitalize31_60 = 26,
  /** "Более 60" (Учет МТР > Не оприходовано) */
  AccountingMtrUncapitalize61More = 27,
  /** "Поставлено на учет" (Учет МТР > Не оприходовано) */
  AccountingMtrRegistered = 28,
  /** "Неотработанные предыдущего периода" (Учет МТР > Всего МТР) */
  AccountingMtrUnusedPreviousPeriod = 29,

  /** "План" */
  BidPlan = 200,
  /** "Прогноз" */
  BidForecast = 201,
  /** "Факт всего" */
  BidTotalFact = 202,
  /** "Факт в срок" */
  BidScheduledFact = 203,
  /** "Не запланированные" */
  BidUnplanned = 204,
  /** "OTIF". Не вводится, не существует на бэке, вычисляется на фронте. */
  BidOTIF = 205,

  /** "План" (Транспорт), вводится за сутки */
  TransportPlan = 701,
  /** "План почасовая техника" (Транспорт), вводится за сутки */
  TransportPlanHours = 711,
  /** "План магистральная техника" (Транспорт), вводится за сутки */
  TransportPlanTrunk = 712,
  /** "Факт" (Транспорт), вводится за сутки */
  TransportFact = 702,
  /** "Факт почасовая техника" (Транспорт), вводится за сутки */
  TransportFactHours = 721,
  /** "Факт магистральная техника" (Транспорт), вводится за сутки */
  TransportFactTrunk = 722,

  /** "План" (ГПМ), вводится за сутки */
  GPMPlan = 703,
  /** "Факт" (ГПМ), вводится за сутки */
  GPMFact = 704,

  /** "План" (Трудовые ресурсы), вводится за сутки */
  LaborResourcesPlan = 705,
  /** "Факт" (Трудовые ресурсы), вводится за сутки */
  LaborResourcesFact = 706,

  /** "Общая площадь, Га", вводится за сутки - площадь всего */
  FullnessBasesTotalSquare = 801,
  /** "Загружено, Га", вводится за сутки - площадь занято */
  FullnessBasesLoaded = 802,

  /** "Загружено, млн. руб", вводится за сутки - рубли */
  FullnessBasesLoadedRubles = 803,

  /** "Загружено, тонн", вводится за сутки - тонны */
  FullnessBasesLoadedWeight = 804,

  /** "Смерть" (HSE происшествия), вводится за сутки */
  HSEIndicatorDeath = 901,
  /** "НС с ВПТ" (HSE происшествия), вводится за сутки */
  HSEIndicatorDisability = 902,
  /** "МП на производстве + ПП" (HSE происшествия), вводится за сутки */
  HSEIndicatorMedicalCare = 903,
  /** "ПбП + ДТП + Алко + Аварии" (HSE происшествия), вводится за сутки */
  HSEIndicatorNearMiss = 904,
  /** "Опасные случаи и действия" (HSE происшествия), вводится за сутки */
  HSEIndicatorDangerWarning = 905,

  /** Проектный показатель (людей) (План) */
  ProjectIndicatorPlan = 1001,
  /** Проектный показатель (людей) (Факт) */
  ProjectIndicatorFact = 1002,

  /** Количество продуктов (План) */
  ProductPlan = 1003,
  /** Количество продуктов (Факт) */
  ProductFact = 1004,
}

export interface IWithGraphsItemIds {
  /** Привязки значения по узлам графов. Вместо { customerId?: string; locationId?: string; mtrTypeId?: string; } */
  graphsItemIds: GraphItemId[];
}

export interface GraphItemId {
  /** InnerId (Guid) корня-классификатора для распределения по этому графу */
  rootId: string;
  /** InnerId (Guid) собственно узла-классификатора (внутри графа-корня) */
  itemId: string;
}

export interface IMtrItem extends IWithGraphsItemIds {
  startPeriod: string;
  /** Guid показателя */
  indicatorId: string;
  value?: number | null;
  // TODO: Actually "status" does not exist on type. Need to analyze
  status: StatusTypeEnum;
}

export interface IDataMtrItem extends IMtrItem {
  duration: string;
  inputType: string;
}

export interface IDataMtrRequest extends IWithGraphDataFilters {
  startPeriod: string;
  duration: string;
  /** Guid-ы показателей */
  indicatorIds: string[];
}

export interface IDataMtrSelfItemFromResponse extends IWithGraphsItemIds {
  /** Guid показателя */
  indicatorId: string;
  value?: number | null;
  /** Is there one original value in cell and it has a whole cell period */
  oneWholeValue?: boolean | null;
}

export interface IDataMtrSelfItem extends IDataMtrSelfItemFromResponse {
  startPeriod: string;
  duration: string;
  periodType: InputDataPeriodType;
}

export interface IDataMtrSumItemFromResponse extends IWithGraphsItemIds {
  /** Guid показателя */
  indicatorId: string;
  value?: number | null;
}

export interface IDataMtrSumItem extends IDataMtrSumItemFromResponse {
  startPeriod: string;
  duration: string;
  periodType: InputDataPeriodType;
}

export enum InputDataPeriodType {
  Year = 'Year',
  Month = 'Month',
  Day = 'Day',
}

export interface IDataMtrForPeriodRequest extends IWithGraphDataFilters {
  forPeriods: IDataMtrForPeriodRequestForPeriodsItem[];
  /** Guid-ы показателей */
  indicatorIds: string[];
  withChildrenSum?: boolean;
  withSourceData?: boolean;
}

export interface IDataMtrForPeriodRequestForPeriodsItem {
  /** Optional id-key to return back in the response */
  key?: string;
  /** Date period to get value for */
  requestedPeriod: IPeriodWithTimeDurationDto;
  /** Date period to check for one whole value. `requestedPeriod` is used if it is not specified */
  periodForWholeValue?: IPeriodWithTimeDurationDto;
}

export interface IDataMtrForPeriodResponse {
  forPeriods: IDataMtrForPeriodResponseForPeriodItem[];
  /** Original soure data items for correct values editing in table if requested */
  originalItems: IDataMtrItem[];
}

export interface IDataMtrForPeriodResponseForPeriodItem {
  /** Optional id-key to return back in the response if it has been specified in the request */
  key?: string;
  requestedPeriod: IPeriodWithTimeDurationDto;
  periodForWholeValue: IPeriodWithTimeDurationDto;
  /** Calculated self Classifier data to display in table */
  selfItems: IDataMtrSelfItemFromResponse[];
  /** Calculated data to display in table as children sum */
  sumForItems: IDataMtrSumItemFromResponse[];
}

export interface IPeriodWithTimeDurationDto {
  /** DateTime as string */
  start: string;
  /** TimeSpan or days as string */
  duration: string;
}

export interface IImportMtrItem extends IMtrItem {
  duration: string;
}

export interface IImportMtrResponse {
  added?: IImportMtrItem[];
  updated?: IImportMtrItem[];
  removed?: IImportMtrItem[];
  unchanged?: IImportMtrItem[];
}

export interface IOptionalDatePeriod {
  start?: Date;
  end?: Date;
}

import { LocalSettings } from "@/model";

export const LOCAL_SETTINGS_NAME = 'application_settings';

export function getLocalSettings(): LocalSettings {
  return (JSON.parse(localStorage[LOCAL_SETTINGS_NAME] ?? '{}') as LocalSettings) ?? {};
}

export function setLocalSettings(settings: LocalSettings): void {
  localStorage[LOCAL_SETTINGS_NAME] = JSON.stringify(settings);
}
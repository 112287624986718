import { InputDataTypeEnum } from '@/components/InputData/common/models';
import {
  AppDataTypeEnum,
  AppGroupByEnum,
  ChartColumnEnum,
  ChartPeriodEnum,
  GraphsFiltersModeEnum,
  IModuleItem,
  ModuleTypeEnum,
  MtrItemTypeEnum,
} from '@/model';
import { defaultUnifiedConfig } from '../constants/defaultUnifiedConfig';

export const FreightTurnoverIndicators = [
  MtrItemTypeEnum.FreightTurnoverPlan,
  MtrItemTypeEnum.FreightTurnoverFact,
  MtrItemTypeEnum.FreightTurnoverAdjustedPlan,
  MtrItemTypeEnum.FreightTurnoverDailyPlan,
];

export const CargoHandlingIndicators = [
  MtrItemTypeEnum.CargoHandlingPlan,
  MtrItemTypeEnum.CargoHandlingFact,
  MtrItemTypeEnum.CargoHandlingAdjustedPlan,
  MtrItemTypeEnum.CargoHandlingDailyPlan,
];

export const ArrivalIndicators = [
  MtrItemTypeEnum.ArrivalFact,
  MtrItemTypeEnum.ArrivalPlan,
  MtrItemTypeEnum.ArrivalAdjustedPlan,
  MtrItemTypeEnum.ArrivalDailyPlan,
];

export const ExpenditureIndicators = [
  MtrItemTypeEnum.ExpenditureFact,
  MtrItemTypeEnum.ExpenditurePlan,
  MtrItemTypeEnum.ExpenditureAdjustedPlan,
  MtrItemTypeEnum.ExpenditureDailyPlan,
];

export const SeasonalDeliveryIndicators = [
  MtrItemTypeEnum.SeasonalDeliveryFact,
  MtrItemTypeEnum.SeasonalDeliveryPlanForMonth,
  MtrItemTypeEnum.SeasonalDeliveryDailyPlan,
  MtrItemTypeEnum.SeasonalDeliveryInitialPlanForYear,
];

export const AccountingMtrIndicators = [
  MtrItemTypeEnum.AccountingMtrTotalSupplies,
  MtrItemTypeEnum.AccountingMtrProcessing,
  MtrItemTypeEnum.AccountingMtrUncapitalize0_14,
  MtrItemTypeEnum.AccountingMtrUncapitalize15_30,
  MtrItemTypeEnum.AccountingMtrUncapitalize31_60,
  MtrItemTypeEnum.AccountingMtrUncapitalize61More,
  MtrItemTypeEnum.AccountingMtrRegistered,
  MtrItemTypeEnum.AccountingMtrUnusedPreviousPeriod,
];

export const ExecutionOfOrdersIndicators = [
  MtrItemTypeEnum.BidPlan,
  MtrItemTypeEnum.BidForecast,
  MtrItemTypeEnum.BidTotalFact,
];

export const OTIFIndicators = [MtrItemTypeEnum.BidPlan, MtrItemTypeEnum.BidTotalFact, MtrItemTypeEnum.BidScheduledFact];

export const TransportIndicators = [
  MtrItemTypeEnum.TransportPlan,
  MtrItemTypeEnum.TransportPlanHours,
  MtrItemTypeEnum.TransportPlanTrunk,
  MtrItemTypeEnum.TransportFact,
  MtrItemTypeEnum.TransportFactHours,
  MtrItemTypeEnum.TransportFactTrunk,
];

export const GPMIndicators = [MtrItemTypeEnum.GPMPlan, MtrItemTypeEnum.GPMFact];

export const LaborResourcesIndicators = [MtrItemTypeEnum.LaborResourcesPlan, MtrItemTypeEnum.LaborResourcesFact];

export const FullnessBasesSquareIndicators = [
  MtrItemTypeEnum.FullnessBasesTotalSquare,
  MtrItemTypeEnum.FullnessBasesLoaded,
];

export const FullnessBasesRublesIndicators = [MtrItemTypeEnum.FullnessBasesLoadedRubles];

export const FullnessBasesWeightIndicators = [MtrItemTypeEnum.FullnessBasesLoadedWeight];

export const HSEIndicators = [
  MtrItemTypeEnum.HSEIndicatorDeath,
  MtrItemTypeEnum.HSEIndicatorDisability,
  MtrItemTypeEnum.HSEIndicatorMedicalCare,
  MtrItemTypeEnum.HSEIndicatorNearMiss,
  MtrItemTypeEnum.HSEIndicatorDangerWarning,
];

export const ProductIndicators = [MtrItemTypeEnum.ProductFact, MtrItemTypeEnum.ProductPlan];

export const ProjectIndicatorIndicators = [MtrItemTypeEnum.ProjectIndicatorFact, MtrItemTypeEnum.ProjectIndicatorPlan];

/**
 * v1 -> v2:
 * Модули по классификаторам
 */
const v1ToV2PanelModules: IModuleItem[] = [
  {
    id: 'InputData',
    name: 'Ввод данных по показателям',
    defaultHeight: 4,
    defaultWidth: 12,
    defaultMobileHeight: 7,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.InputDataComponent,
    defaultConfig: {
      storeIndicatorsSelection: true,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      inputTableConfig: {
        inputDataType: InputDataTypeEnum.CommonTableNavigatable,
      },
    },
    doNotRepaint: true,
  },
  {
    id: 'InputHSE',
    name: 'Ввод происшествий HSE',
    defaultHeight: 4,
    defaultWidth: 12,
    defaultMobileHeight: 7,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.InputDataComponent,
    defaultConfig: {
      inputTableConfig: {
        inputDataType: InputDataTypeEnum.HSE,
      },
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersWholeOrOptionalRoot,
    },
    doNotRepaint: true,
  },
  {
    id: 'InputWeatherRestrictions',
    name: 'Ввод погодных ограничений',
    defaultHeight: 4,
    defaultWidth: 12,
    defaultMobileHeight: 7,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.InputDataComponent,
    defaultConfig: {
      inputTableConfig: {
        inputDataType: InputDataTypeEnum.WeatherRestrictions,
      },
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersWholeOrOptionalRoot,
    },
    doNotRepaint: true,
  },
  {
    id: 'ProjectIndicator',
    name: 'Проектный показатель',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 2,
    defaultMobileWidth: 3,
    type: ModuleTypeEnum.ProjectIndicatorComponent,
    defaultConfig: {
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      systemIndicators: ProjectIndicatorIndicators,
    },
  },
  {
    id: 'ProjectIndicatorGroupByProjects',
    name: 'Группа проектных показателей (Продукты)',
    defaultHeight: 1,
    defaultWidth: 3,
    defaultMobileHeight: 1,
    defaultMobileWidth: 3,
    type: ModuleTypeEnum.ProjectIndicatorGroupProjects,
    defaultConfig: {
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      systemIndicators: ProductIndicators,
    },
  },
  {
    id: 'ProjectIndicatorGroupByProjectsTotal',
    name: 'Всего продуктов',
    defaultHeight: 1,
    defaultWidth: 1,
    defaultMobileHeight: 1,
    defaultMobileWidth: 1,
    type: ModuleTypeEnum.ProjectIndicatorGroupProjectsTotal,
    defaultConfig: {
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      systemIndicators: ProductIndicators,
    },
  },
  {
    id: 'ProjectIndicatorGroupByUsers',
    name: 'Группа проектных показателей (Пользователи)',
    defaultHeight: 1,
    defaultWidth: 3,
    defaultMobileHeight: 1,
    defaultMobileWidth: 3,
    type: ModuleTypeEnum.ProjectIndicatorGroupUsers,
    defaultConfig: {
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      systemIndicators: ProjectIndicatorIndicators,
    },
  },
  {
    id: 'ProjectIndicatorGroupByUsersTotal',
    name: 'Пользователи (проектные показатели)',
    defaultHeight: 1,
    defaultWidth: 4,
    defaultMobileHeight: 1,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.ProjectIndicatorGroupUsersTotal,
    defaultConfig: {
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      systemIndicators: ProjectIndicatorIndicators,
    },
  },
  {
    id: 'ProjectDynamicsUsersChart',
    name: 'Динамика пользователей (проектные показатели)',
    defaultHeight: 1,
    defaultWidth: 4,
    defaultMobileHeight: 1,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.ProjectDynamicsUsersChart,
    defaultConfig: {
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      systemIndicators: ProjectIndicatorIndicators,
    },
  },
  {
    id: 'FreightTurnover_Period',
    name: 'Грузооборот, по периодам',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.CargoChart,
    defaultSeries: [
      {
        id: 'plan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'adjustedPlan',
        name: 'Скорректированный план',
        color: '#071de0',
        value: false,
      },
      {
        id: 'dailyPlan',
        name: 'Суточный план',
        color: '#33176f',
        value: false,
      },
      {
        id: 'fact',
        name: 'Факт',
        color: '#8dc548',
        value: true,
      },
    ],
    defaultConfig: {
      columns: ChartColumnEnum.Period,
      type: AppDataTypeEnum.FreightTurnover,
      period: ChartPeriodEnum.Year,
      allowGroupBy: [AppGroupByEnum.GraphClassifier, AppGroupByEnum.GraphPeriod],
      systemIndicators: FreightTurnoverIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
  },
  {
    id: 'CargoHandling_Period',
    name: 'Грузопереработка, по периодам',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.CargoChart,
    defaultSeries: [
      {
        id: 'plan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'adjustedPlan',
        name: 'Скорректированный план',
        color: '#071de0',
        value: false,
      },
      {
        id: 'dailyPlan',
        name: 'Суточный план',
        color: '#33176f',
        value: false,
      },
      {
        id: 'fact',
        name: 'Факт',
        color: '#8dc548',
        value: true,
      },
    ],
    defaultConfig: {
      columns: ChartColumnEnum.Period,
      type: AppDataTypeEnum.CargoHandling,
      period: ChartPeriodEnum.Year,
      allowGroupBy: [AppGroupByEnum.GraphClassifier, AppGroupByEnum.GraphPeriod],
      systemIndicators: CargoHandlingIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
  },
  {
    id: 'HSE',
    name: 'HSE',
    defaultHeight: 3,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.HSEChart,
    defaultConfig: {
      period: ChartPeriodEnum.Year,
      systemIndicators: HSEIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersWholeOrOptionalRoot,
    },
  },
  {
    id: 'FreightTurnover_Locations',
    name: 'Грузооборот',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.CargoChart,
    defaultSeries: [
      {
        id: 'plan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'adjustedPlan',
        name: 'Скорректированный план',
        color: '#071de0',
        value: false,
      },
      {
        id: 'dailyPlan',
        name: 'Суточный план',
        color: '#33176f',
        value: false,
      },
      {
        id: 'fact',
        name: 'Факт',
        color: '#8dc548',
        value: true,
      },
    ],
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      type: AppDataTypeEnum.FreightTurnover,
      period: ChartPeriodEnum.Year,
      allowGroupBy: [AppGroupByEnum.GraphClassifier, AppGroupByEnum.GraphPeriod],
      systemIndicators: FreightTurnoverIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
  },
  {
    id: 'CargoHandling_Locations',
    name: 'Грузопереработка',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.CargoChart,
    defaultSeries: [
      {
        id: 'plan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'adjustedPlan',
        name: 'Скорректированный план',
        color: '#071de0',
        value: false,
      },
      {
        id: 'dailyPlan',
        name: 'Суточный план',
        color: '#33176f',
        value: false,
      },
      {
        id: 'fact',
        name: 'Факт',
        color: '#8dc548',
        value: true,
      },
    ],
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      type: AppDataTypeEnum.CargoHandling,
      period: ChartPeriodEnum.Year,
      allowGroupBy: [AppGroupByEnum.GraphClassifier, AppGroupByEnum.GraphPeriod],
      systemIndicators: CargoHandlingIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
  },
  {
    id: 'OTIF',
    name: 'OTIF',
    defaultHeight: 3,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.OTIFValues,
    defaultConfig: {
      period: ChartPeriodEnum.Month,
      allowPeriods: [ChartPeriodEnum.Day, ChartPeriodEnum.Month, ChartPeriodEnum.Year],
      systemIndicators: OTIFIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
  },
  {
    id: 'SeasonalDelivery',
    name: 'Сезонный завоз',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.SeasonalDeliveryChart,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: SeasonalDeliveryIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      allowFilters: ChartColumnEnum.Classifier,
    },
    defaultSeries: [
      {
        id: 'initialPlanForYear',
        name: 'Первоначальный план завоза',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'planForYear',
        name: 'План завоза',
        color: '#0026CF',
        value: false,
      },
      {
        id: 'planForMonth',
        name: 'План на конец месяца',
        color: '#FFD800',
        value: true,
      },
      {
        id: 'plan',
        name: 'Накопительный план',
        color: '#74468C',
        value: true,
      },
      {
        id: 'fact',
        name: 'Накопительный факт',
        color: '#8dc548',
        value: true,
      },
      {
        id: 'dailyPlan',
        name: 'Суточный план',
        color: '#00FFFF',
        value: false,
      },
      {
        id: 'dailyFact',
        name: 'Завоз за сутки',
        color: '#86FF90',
        value: true,
      },
    ],
  },
  {
    id: 'SeasonalDeliveryAnalytics',
    name: 'Сезонный завоз (по годам)',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.SeasonalDeliveryAnalyticsChart,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: SeasonalDeliveryIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
      allowFilters: ChartColumnEnum.Classifier,
    },
    defaultSeries: [],
  },
  {
    id: 'AccountingMtr',
    name: 'Учет МТР, по периодам',
    defaultHeight: 3,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.AccountingMtr,
    defaultConfig: {
      columns: ChartColumnEnum.Period,
      period: ChartPeriodEnum.Year,
      allowGroupBy: [AppGroupByEnum.GraphClassifier, AppGroupByEnum.GraphPeriod],
      systemIndicators: AccountingMtrIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'totalSupplies',
        name: 'Итого поставок',
        color: '#005FF9',
        value: true,
        stack: 'total',
        pane: 'topPane',
      },
      {
        id: 'unusedPreviousPeriod',
        name: 'Неотработанные МТР предыдущего периода',
        color: '#28AFF0',
        value: true,
        stack: 'total',
        pane: 'topPane',
      },
      {
        id: 'registered',
        name: 'Поставлено на учет',
        color: '#00AA5F',
        value: true,
        stack: 'uncapitalize',
        pane: 'topPane',
      },
      {
        id: 'processing',
        name: 'В работе',
        color: '#69C57F',
        value: true,
        stack: 'uncapitalize',
        pane: 'topPane',
      },
      {
        id: 'uncapitalize0_14',
        name: '0-14',
        color: '#FFDD6D',
        value: true,
        hideLabel: false,
        stack: 'uncapitalize',
        pane: 'topPane',
      },
      {
        id: 'uncapitalize15_30',
        name: '15-30',
        color: '#FFCA69',
        value: true,
        hideLabel: false,
        stack: 'uncapitalize',
        pane: 'topPane',
      },
      {
        id: 'uncapitalize31_60',
        name: '31-60',
        color: '#F9B461',
        value: true,
        hideLabel: false,
        stack: 'uncapitalize',
        pane: 'topPane',
      },
      {
        id: 'uncapitalize61More',
        name: '>60',
        color: '#ED9254',
        value: true,
        hideLabel: false,
        stack: 'uncapitalize',
        pane: 'topPane',
      },
      {
        id: 'uncapitalizeAll',
        name: 'Не оприходовано',
        color: '#F0C332',
        value: true,
        type: 'splinearea',
        pane: 'bottomPane',
        border: true,
        point: true,
      },
    ],
  },
  {
    id: 'AccountingMtr_Graph',
    name: 'Учет МТР',
    defaultHeight: 2,
    defaultWidth: 2,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.AccountingMtr,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      period: ChartPeriodEnum.Year,
      allowGroupBy: [AppGroupByEnum.GraphClassifier, AppGroupByEnum.GraphPeriod],
      systemIndicators: AccountingMtrIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'totalSupplies',
        name: 'Итого поставок',
        color: '#0000FF',
        value: true,
        stack: 'total',
      },
      {
        id: 'unusedPreviousPeriod',
        name: 'Неотработанные МТР предыдущего периода',
        color: '#00D4FF',
        value: true,
        stack: 'total',
      },
      {
        id: 'registered',
        name: 'Поставлено на учет',
        color: '#00BD13',
        value: true,
        stack: 'uncapitalize',
      },
      {
        id: 'processing',
        name: 'В работе',
        color: '#11FF55',
        value: true,
        stack: 'uncapitalize',
      },
      {
        id: 'uncapitalize0_14',
        name: '0-14',
        color: '#FBE5D6',
        value: true,
        hideLabel: false,
        stack: 'uncapitalize',
      },
      {
        id: 'uncapitalize15_30',
        name: '15-30',
        color: '#F8CBAD',
        value: true,
        hideLabel: false,
        stack: 'uncapitalize',
      },
      {
        id: 'uncapitalize31_60',
        name: '31-60',
        color: '#F4B183',
        value: true,
        hideLabel: false,
        stack: 'uncapitalize',
      },
      {
        id: 'uncapitalize61More',
        name: '>60',
        color: '#C55A11',
        value: true,
        hideLabel: false,
        stack: 'uncapitalize',
      },
    ],
  },
  {
    id: 'TransportResource',
    name: 'Транспортные ресурсы',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.TransportResourcesChart,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: TransportIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'transportPlan',
        name: 'План',
        color: '#42a2f6',
        value: true,
        stack: 'transportPlan',
      },
      {
        id: 'transportPlanHours',
        name: 'План Почасовая',
        color: '#0026FF',
        value: true,
        stack: 'transportPlan',
      },
      {
        id: 'transportPlanTrunk',
        name: 'План Магистральная',
        color: '#257C00',
        value: true,
        stack: 'transportPlan',
      },
      {
        id: 'transportFact',
        name: 'Факт',
        color: '#071de0',
        value: true,
        stack: 'transportFact',
      },
      {
        id: 'transportFactHours',
        name: 'Факт Почасовая',
        color: '#0094FF',
        value: true,
        stack: 'transportFact',
      },
      {
        id: 'transportFactTrunk',
        name: 'Факт Магистральная',
        color: '#7FDB6A',
        value: true,
        stack: 'transportFact',
      },
    ],
  },
  {
    id: 'GPMResource',
    name: 'ГПМ',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.GPMResourcesChart,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: GPMIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'GPMPlan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'GPMFact',
        name: 'Факт',
        color: '#071de0',
        value: true,
      },
    ],
  },
  {
    id: 'LaborResource',
    name: 'Трудовые ресурсы',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.LaborResourcesChart,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: LaborResourcesIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'laborResourcesPlan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'laborResourcesFact',
        name: 'Факт',
        color: '#071de0',
        value: true,
      },
    ],
  },
  {
    id: 'ExecutionOfOrders',
    name: 'Исполнение заявок',
    defaultHeight: 2,
    defaultWidth: 2,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.ExecutionOfOrdersChart,
    defaultConfig: {
      columns: ChartColumnEnum.Date,
      systemIndicators: ExecutionOfOrdersIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'forecast',
        name: 'Прогноз',
        color: '#f7c142',
        value: true,
      },
      {
        id: 'plan',
        name: 'Заявлено',
        color: '#58a2f1',
        value: true,
      },
      {
        id: 'totalFact',
        name: 'Факт на вчерашний день',
        color: '#97b763',
        value: true,
      },
    ],
  },
  {
    id: 'Arrival',
    name: 'Приход',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.ArrivalChart,
    defaultSeries: [
      {
        id: 'plan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'adjustedPlan',
        name: 'Скорректированный план',
        color: '#071de0',
        value: false,
      },
      {
        id: 'dailyPlan',
        name: 'Суточный план',
        color: '#33176f',
        value: false,
      },
      {
        id: 'fact',
        name: 'Факт',
        color: '#8dc548',
        value: true,
      },
    ],
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      period: ChartPeriodEnum.Day,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: ArrivalIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
  },
  {
    id: 'ArrivalRotated',
    name: 'Приход (Прототип)',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.ArrivalChartRotated,
    defaultSeries: [
      {
        id: 'plan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'adjustedPlan',
        name: 'Скорректированный план',
        color: '#071de0',
        value: false,
      },
      {
        id: 'dailyPlan',
        name: 'Суточный план',
        color: '#33176f',
        value: false,
      },
      {
        id: 'fact',
        name: 'Факт',
        color: '#8dc548',
        value: true,
      },
    ],
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      period: ChartPeriodEnum.Day,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: ArrivalIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
  },
  {
    id: 'Expenditure',
    name: 'Расход',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.ExpenditureChart,
    defaultSeries: [
      {
        id: 'plan',
        name: 'План',
        color: '#42a2f6',
        value: true,
      },
      {
        id: 'adjustedPlan',
        name: 'Скорректированный план',
        color: '#071de0',
        value: false,
      },
      {
        id: 'dailyPlan',
        name: 'Суточный план',
        color: '#33176f',
        value: false,
      },
      {
        id: 'fact',
        name: 'Факт',
        color: '#8dc548',
        value: true,
      },
    ],
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      period: ChartPeriodEnum.Day,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: ExpenditureIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
  },
  {
    id: 'FullnessBases',
    name: 'Загруженность баз, площадь',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.FullnessBasesBySquareChart,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: FullnessBasesSquareIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'loaded',
        name: 'Загружено',
        color: '#8dc548',
        value: true,
      },
      {
        id: 'free',
        name: 'Свободно',
        color: '#42a2f6',
        value: true,
      },
    ],
  },
  {
    id: 'FullnessBasesByCurrency',
    name: 'Загруженность баз, в валюте',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.FullnessBasesByCurrencyChart,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: FullnessBasesRublesIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'rubles',
        name: 'Загружено',
        color: '#8dc548',
        value: true,
      },
    ],
  },
  {
    id: 'FullnessBasesByWeight',
    name: 'Загруженность баз, в тоннах',
    defaultHeight: 2,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.FullnessBasesByWeightChart,
    defaultConfig: {
      columns: ChartColumnEnum.Classifier,
      allowGroupBy: [AppGroupByEnum.GraphClassifier],
      systemIndicators: FullnessBasesWeightIndicators,
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersRoots,
    },
    defaultSeries: [
      {
        id: 'weight',
        name: 'Загружено',
        color: '#8dc548',
        value: true,
      },
    ],
  },
  {
    id: 'WeatherRestrictions',
    name: 'Погодные ограничения',
    defaultHeight: 2,
    defaultWidth: 2,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.WeatherRestrictionsList,
    defaultConfig: {
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersWholeOrOptionalRoot,
    },
  },
  {
    id: 'WeatherParams',
    name: 'Погода',
    defaultHeight: 2,
    defaultWidth: 2,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.WeatherParamsList,
    defaultConfig: {
      graphsFiltersMode: GraphsFiltersModeEnum.ClassifiersWholeOrOptionalRoot,
    },
  },
];

/**
 * same for v1 and v2:
 * Модули без версии, т.е. которые одинаковы не по классификаторам и по классификаторам
 */
const noVersionPanelModules: IModuleItem[] = [
  {
    id: 'Separator',
    name: 'Разделитель',
    defaultHeight: 1,
    defaultWidth: 12,
    defaultMaxHeight: 0.5,
    noBackground: true,
    defaultMobileHeight: 0.5,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.Separator,
    defaultConfig: {
      projectIndicatorConfig: {
        separatorName: 'Разделитель',
      },
    },
    doNotRepaint: true,
  },
  {
    id: 'TextBlock',
    name: 'Текст',
    defaultHeight: 3,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.TextBlock,
    defaultConfig: {
      customParams: {
        text: '',
      },
    },
  },
  {
    id: 'DirectoryGraph',
    name: 'Справочники',
    defaultHeight: 3,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 3,
    type: ModuleTypeEnum.DirectoryGraph,
    defaultConfig: {},
  },
  {
    id: 'DirectoryGraphAsList',
    name: 'Справочники, элементы графа списком',
    defaultHeight: 3,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 3,
    type: ModuleTypeEnum.DirectoryGraphAsList,
    defaultConfig: {},
  },
  {
    id: 'UnifiedBlock',
    name: 'Карточка',
    defaultHeight: 3,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 4,
    type: ModuleTypeEnum.UnifiedBlock,
    defaultConfig: {
      unifiedConfig: defaultUnifiedConfig,
    },
  },
  {
    id: 'UnifiedBlockMini',
    name: 'Карточка мини',
    defaultHeight: 1,
    defaultWidth: 1,
    defaultMobileHeight: 1,
    defaultMobileWidth: 1,
    type: ModuleTypeEnum.UnifiedBlockMini,
    defaultConfig: {
      unifiedConfig: defaultUnifiedConfig,
    },
  },
  {
    id: 'PanelView',
    name: 'Папка',
    defaultHeight: 3,
    defaultWidth: 4,
    defaultMobileHeight: 1,
    defaultMobileWidth: 2,
    type: ModuleTypeEnum.PanelView,
  },
  {
    id: 'Divider',
    name: 'Разделитель 2',
    defaultHeight: 1,
    defaultWidth: 4,
    defaultMobileHeight: 1,
    defaultMobileWidth: 2,
    type: ModuleTypeEnum.Divider,
    defaultConfig: {
      dividerConfig: {
        text: 'Разделитель',
      },
    },
  },
  {
    id: 'Comments',
    name: 'Комментарии',
    defaultHeight: 1,
    defaultWidth: 1,
    defaultMobileHeight: 1,
    defaultMobileWidth: 1,
    type: ModuleTypeEnum.Comments,
    defaultConfig: {
      customParams: {},
    },
  },
];

/**
 * new for v2:
 * Новые модули по классификаторам, которых ранее не было
 */
const newV2PanelModules: IModuleItem[] = [
  {
    id: 'IndicatorClassifiers',
    name: 'Классификаторы для показателей',
    defaultHeight: 3,
    defaultWidth: 3,
    defaultMobileHeight: 3,
    defaultMobileWidth: 3,
    type: ModuleTypeEnum.IndicatorClassifiersComponent,
    defaultConfig: {},
  },
];

function getPanelModules(): IModuleItem[] {
  const panelModules: IModuleItem[] = [];

  // v1 -> v2:
  panelModules.push(...v1ToV2PanelModules);

  // same for v1 and v2:
  panelModules.push(...noVersionPanelModules);

  // new for v2:
  panelModules.push(...newV2PanelModules);

  return panelModules;
}

export const allPanelModules: IModuleItem[] = getPanelModules();

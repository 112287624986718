import notify from 'devextreme/ui/notify';

import api from '@/services/api';
import { ContextsState, IndicatorsState } from '@/store';
import {
  ClassifierLight,
  GraphsFiltersModeEnum,
  IModuleConfig,
  IndicatorClassifier,
  IndicatorClassifierAddEdit,
  IndicatorInfoLight,
  IndicatorWithClassifiers,
  MtrItemTypeEnum,
  RootClassifiersValidStateEnum,
} from '@/model';
import { cloneDeep } from '../utils';


/** Получение показателей контекста из БД, облегченные модели */
export async function getIndicatorsInfo(): Promise<IndicatorInfoLight[]> {
  const items = await api.get<IndicatorInfoLight[]>(
    `/api/contexts/${ContextsState.currentContextId}/classifiers/indicators/info`
  );
  return items ?? [];
}

/** Получение показателей с классификаторами или без них при отстутствии */
export async function getIndicatorsInfoWithClassifiers(): Promise<IndicatorWithClassifiers[]> {
  const items = await api.get<IndicatorWithClassifiers[]>(
    `/api/contexts/${ContextsState.currentContextId}/classifiers/indicators/info-with-classifiers`
  );
  return items ?? [];
}

/** Получение существующих привязок показатель-классификатор */
export async function getIndicatorsClassifiers(): Promise<IndicatorClassifier[]> {
  const items = await api.get<IndicatorClassifier[]>(
    `/api/contexts/${ContextsState.currentContextId}/classifiers/indicators/classifiers`
  );
  return items ?? [];
}

// Add, edit, delete indicator-classifier привязок (пока только системных) --

export async function addIndicatorClassifier(data: IndicatorClassifierAddEdit): Promise<IndicatorClassifierAddEdit> {
  const added = await api.post<IndicatorClassifierAddEdit>(
    `/api/contexts/${ContextsState.currentContextId}/classifiers/indicators/classifiers`,
    data
  );
  return added;
}

export async function editIndicatorClassifier(data: IndicatorClassifierAddEdit): Promise<IndicatorClassifierAddEdit> {
  const edited = await api.put<IndicatorClassifierAddEdit>(
    `/api/contexts/${ContextsState.currentContextId}/classifiers/indicators/classifiers/${data.id}`,
    data
  );
  return edited;
}

export async function deleteIndicatorClassifier(id: number): Promise<void> {
  await api.delete<void>(
    `/api/contexts/${ContextsState.currentContextId}/classifiers/indicators/classifiers/${id}`,
  );
}

// --

export function joinIndicatorTitles(titles: string[], separator = ", "): string {
  return titles.join(separator);
}

function strcmp(a: string, b: string): number {
  return (a < b ? -1 : ( a > b ? 1 : 0));
}

/**
 * Получить для указанного списка показателей список привязанных к ним классификаторов.
 * Ошибка, если привязанные классификаторы отличаются между указанными показателями.
*/
export function getSameRootClassifiersForAllIndicators(
  indicatorsWithClassifiers: IndicatorWithClassifiers[],
  systemIndicators: MtrItemTypeEnum[] | null,
  indicatorIds: string[] | null,
  notifyOnError = false,
  throwOnError = false
): {
  rootClassifiers: ClassifierLight[],
  isValid: boolean,
  validState: RootClassifiersValidStateEnum,
  errorMessage?: string,
} {
  const useSystemIndicators = Boolean(systemIndicators && systemIndicators.length > 0);
  const useIndicatorIds = Boolean(indicatorIds && indicatorIds.length > 0);

  if (!useSystemIndicators && !useIndicatorIds) {
    return {
      rootClassifiers: [],
      isValid: false,
      validState: RootClassifiersValidStateEnum.EmptyIndicators,
    };
  }

  let matchedIndicatorsWithClassifiers: IndicatorWithClassifiers[] = [];
  if (useSystemIndicators) {
    const systemIndicatorsAsStrings = systemIndicators!
      .map(x => MtrItemTypeEnum[x]);
    matchedIndicatorsWithClassifiers = indicatorsWithClassifiers
      .filter(x => systemIndicatorsAsStrings.findIndex(indicatorAsString => x.systemType === indicatorAsString) != -1);
  } else {
    matchedIndicatorsWithClassifiers = indicatorsWithClassifiers
      .filter(x => indicatorIds!.findIndex(indicatorId => x.indicatorId === indicatorId) != -1);
  }

  const joinedIndicatorsTitles = matchedIndicatorsWithClassifiers
    .map(x => `[${ joinIndicatorTitles(x.titles ?? ['-'], ', ') }]`)
    .join(',  ');

  let rootClassifiers: ClassifierLight[] = [];
  let prevClassifiersJoinedKeysString: string | null = null;
  let isFirstIteration = true;

  for (const indicatorsWithClassifiers of matchedIndicatorsWithClassifiers) {
    const tempRootClassifiers = indicatorsWithClassifiers.indicatorClassifiers
      .map(x => x.classifier);
    tempRootClassifiers.sort((a, b) => strcmp(a.innerId, b.innerId));
    const joinedKeysString = tempRootClassifiers.map(x => x.innerId).join('|');

    if (isFirstIteration) {
      rootClassifiers = cloneDeep(tempRootClassifiers);
      prevClassifiersJoinedKeysString = joinedKeysString;
      isFirstIteration = false;
    } else if (prevClassifiersJoinedKeysString !== joinedKeysString) {
      const errorMessage =
`Корневые классификаторы (графы) не одинаковы для показателей: ${joinedIndicatorsTitles}.\n
Проверьте конфигурацию показателей контекста в инфоблоке "Классификаторы для показателей", после этого обновите содержимое панели.\n
Показатели одного инфоблока должны иметь одинаковые классификаторы.\n
Работа инфоблока "Ввод данных по показателям" возможна одновременно на показателях с одинаковыми классификаторами.`;

      if (notifyOnError) {
        notify(errorMessage, 'error', 20000); // длительное время, чтобы успеть прочесть длинное сообщение
      }

      if (throwOnError) {
        throw new Error(errorMessage);
      }

      return {
        rootClassifiers: [],
        isValid: false,
        validState: RootClassifiersValidStateEnum.MixedRoots,
        errorMessage: errorMessage,
      };
    }
  }

  rootClassifiers = getOrderedRootClassifiers(rootClassifiers);

  if (rootClassifiers.length === 0) {
    const errorMessage =
`Корневые классификаторы (графы) не настроены для показателей: ${joinedIndicatorsTitles}.\n
Выполните настройку показателей контекста в инфоблоке "Классификаторы для показателей", после этого обновите содержимое панели.\n
Показатели одного инфоблока должны иметь одинаковые классификаторы.\n
Работа инфоблока "Ввод данных по показателям" возможна одновременно на показателях с одинаковыми классификаторами.`;
    return {
      rootClassifiers: rootClassifiers,
      isValid: false,
      validState: RootClassifiersValidStateEnum.EmptyRoots,
      errorMessage: errorMessage,
    };
  }

  return {
    rootClassifiers: rootClassifiers,
    isValid: true,
    validState: RootClassifiersValidStateEnum.Ok,
  };
}

/**
 * Функция сортировки корней распределения везде в едином порядке.
 * Саму логику можно обновить в любой момент.
 */
export function getOrderedRootClassifiers(roots: ClassifierLight[]): ClassifierLight[] {
  const locationStructureStrings = [
    'структура',
    'локаци',
    'географи',
  ];

  const directionStrings = [
    'направлени',
  ];

  const customerStrings = [
    'клиент',
    'заказчик',
  ];

  const rootLocationsStructureItems: ClassifierLight[] = [];
  const rootDirections: ClassifierLight[] = [];
  const rootCustomers: ClassifierLight[] = [];
  const others: ClassifierLight[] = [];

  // сначала сортируем по алфавиту
  const tempRoots = [...roots];
  tempRoots.sort((a, b) => strcmp(a.title, b.title));

  // затем проверяем на Локации, Структура, Направления, Клиенты, другие
  for (const root of tempRoots) {
    const title = root.title;
    if (checkClassifierNameMatched(title, locationStructureStrings)) {
      rootLocationsStructureItems.push(root);
    } else if (checkClassifierNameMatched(title, directionStrings)) {
      rootDirections.push(root);
    } else if (checkClassifierNameMatched(title, customerStrings)) {
      rootCustomers.push(root);
    } else {
      others.push(root);
    }
  }

  const resultRoots = [
    ...rootLocationsStructureItems,
    ...rootDirections,
    ...rootCustomers,
    ...others,
  ];

  return resultRoots;
}

function checkClassifierNameMatched(classifierName: string, expectedNames: string[]): boolean {
  if (classifierName) {
    const candidateName = classifierName.toLowerCase();
    for (const expectedName of expectedNames) {
      if (candidateName.startsWith(expectedName.toLowerCase())) {
        return true;
      }
    }
  }
  return false;
}

export function setModuleClassifiers(config: IModuleConfig): void {
  const graphsFiltersMode = config?.graphsFiltersMode;
  const systemIndicators = config?.systemIndicators ?? null; // показатели из статического дефолтного конфига инфоблока
  const indicatorIds = config?.indicatorIds ?? null; // показатели из динамического конфига инфоблока

  if (graphsFiltersMode === GraphsFiltersModeEnum.ClassifiersRoots) {
    const rootClassifiersResult = getSameRootClassifiersForAllIndicators(IndicatorsState.indicatorsWithClassifiers, systemIndicators, indicatorIds);
    config.rootClassifiers = rootClassifiersResult.rootClassifiers;
    config.rootClassifiersState = {
      isValid: rootClassifiersResult.isValid,
      state: rootClassifiersResult.validState,
      errorMessage: rootClassifiersResult.errorMessage,
    };
      rootClassifiersResult.validState;
  } else if (graphsFiltersMode === GraphsFiltersModeEnum.ClassifiersWholeOrOptionalRoot) {
    const rootClassifiersResult = getSameRootClassifiersForAllIndicators(IndicatorsState.indicatorsWithClassifiers, systemIndicators, indicatorIds);
    // NOTE: Поле config.rootClassifiers здесь не устанавливаем специально
    config.rootClassifiersState = {
      isValid: rootClassifiersResult.isValid,
      state: rootClassifiersResult.validState,
      errorMessage: rootClassifiersResult.errorMessage,
    };
  } else {
    // Do nothing.
  }
}

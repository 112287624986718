export enum ChartColumnEnum {
  Classifier = 'classifier',
  /** @deprecated Больше не использовать, может остаться в конфиге старых инфоблоков */
  Customer = 'customer',
  /** @deprecated Больше не использовать, может остаться в конфиге старых инфоблоков */
  Location = 'location',
  /** @deprecated Больше не использовать */
  MtrType = 'mtrType',

  Period = 'period',
  Date = 'date',
}

export enum AppGroupByEnum {
  GraphClassifier = 'GraphClassifier',
  GraphPeriod = 'GraphPeriod',
}

/** Вид режима работы конфига фильтра по графам в инфоблоке */
export enum GraphsFiltersModeEnum {
  /** Только от явных корней */
  ClassifiersRoots = 'ClassifiersRoots',
  /** Полный граф или от опционального корня */
  ClassifiersWholeOrOptionalRoot = 'ClassifiersWholeOrOptionalRoot',
  /** Графы отсутствуют */
  Disabled = 'Disabled',
}

export enum RootClassifiersValidStateEnum {
  /** Все корректно - валидно */
  Ok = 'Ok',
  /** У инфоблока не указаны показатели - не валидно */
  EmptyIndicators = 'EmptyIndicators',
  /** Для показателей инфоблока не настроены графы - не валидно */
  EmptyRoots = 'EmptyRoots',
  /** Для показателей инфоблока настроены графы, но рассогласованы - не валидно */
  MixedRoots = 'MixedRoots',
}

/**
 * Смешанный тип данных для уточнения типа графика в обобщенном api-запросе.
 * TODO: Использовать этот enum в графиках грузо-, ресурсов и хранения вместо хардкод строк.
 */
export enum AppDataTypeEnum {
  CargoHandling = 'CargoHandling',
  FreightTurnover = 'FreightTurnover',
  Transport = 'Transport',
  GPM = 'GPM',
  LaborResources = 'LaborResources',
  FullnessBasesSquare = 'FullnessBasesSquare',
  FullnessBasesCurrency = 'FullnessBasesCurrency',
  FullnessBasesWeight = 'FullnessBasesWeight',
  ProjectIndicator = 'ProjectIndicator',
  Product = 'Product',
}

/** @deprecated Будет удалено */
export enum ClassifierTypeEnum {
  Base = 'Base',
  Route = 'Route',
  Navigation = 'Navigation',
}

export enum StatusTypeEnum {
  Active = 'Active',
  Disable = 'Disable',
  Archived = 'Archived',
}

export interface IGenericItemResponse {
  itemId?: string | null;
  itemTitle?: string;
}

export enum SelectionType {
  Selected = 0,
  Deselected = 1,
  AllChildrenDeselected = 2,
  AllChildrenSelected = 3,
}

export enum ResultStatus {
  /** For 200-Ok usually */
  Success = 'Success',
  /** For 500-InternalServerError usually */
  UnknownError = 'UnknownError',
  /** For 400-BadRequest usually */
  BadData = 'BadData',
  /** For 404-NotFound usually */
  ItemNotFound = 'ItemNotFound',
  /** For 403-Forbidden usually */
  Forbidden = 'Forbidden',
  /** For 409-Conflict usually */
  Conflict = 'Conflict',
  /** Let it be also 400-BadRequest */
  DataValidationError = 'DataValidationError',
  /** For 501-NotImplemented usually */
  NotImplemented = 'NotImplemented',
}

import api from '@/services/api';
import { ContextsState } from '@/store';
import { IndicatorFull, StatusTypeEnum } from '@/model';


/** Получение показателей контекста из БД, полные модели. Только активные, если не указано иное */
export async function getIndicators(statuses?: StatusTypeEnum[]): Promise<IndicatorFull[]> {
  const items = await api.get<IndicatorFull[]>(
    `/api/contexts/${ContextsState.currentContextId}/indicators`,
    {
      statuses,
    }
  );
  return items;
}

/** Получение показателя контекста из БД, полная модель */
export async function getIndicator(indicatorId: string): Promise<IndicatorFull> {
  const item = await api.get<IndicatorFull>(
    `/api/contexts/${ContextsState.currentContextId}/indicators/${indicatorId}`,
  );
  return item;
}

/** Создание пользовательского показателя */
export async function addIndicator(data: IndicatorFull): Promise<IndicatorFull> {
  const added = await api.post<IndicatorFull>(
    `/api/contexts/${ContextsState.currentContextId}/indicators`,
    data
  );
  return added;
}

/**
 * Редактирование показателя (без смены статуса):
 * - пользовательского полностью (почти полностью, из полной модели возьмутся нужные поля);
 * - системного ограниченно - из полной модели возьмутся только определнные поля.
 */
export async function editIndicator(data: IndicatorFull): Promise<IndicatorFull> {
  const edited = await api.put<IndicatorFull>(
    `/api/contexts/${ContextsState.currentContextId}/indicators/${data.id}`,
    data
  );
  return edited;
}

/**
 * Редактирование статуса показателя (без смены остальных полей):
 * - пользовательского - да;
 * - системного - нет.
 */
export async function changeIndicatorStatus(data: IndicatorFull): Promise<IndicatorFull> {
  const edited = await api.put<IndicatorFull>(
    `/api/contexts/${ContextsState.currentContextId}/indicators/${data.id}/status`,
    data
  );
  return edited;
}

/**
 * Удаление показателя вместе со всеми связанными данными, полностью и безвозвратно:
 * - пользовательского - да;
 * - системного - нет.
 */
export async function deleteIndicatorCompletely(indicatorId: string): Promise<void> {
  await api.delete<void>(
    `/api/contexts/${ContextsState.currentContextId}/indicators/${indicatorId}`
  );
}

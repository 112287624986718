import { createStore } from 'vuex'
import { getModule as getDecoratedModule, VuexModule } from 'vuex-module-decorators';
import user from '@/store/user';
import contexts from '@/store/contexts';
import connection from '@/store/connection';
import device from '@/store/device';
import panels from '@/store/panels';
import indicators from '@/store/indicators';
import blockSettings from '@/store/blockSettings';

const store = createStore({
  modules: {
    user,
    contexts,
    connection,
    device,
    panels,
    indicators,
    blockSettings,
  },
});

export default store;
export const UserState = (() => getModule(user))();
export const ContextsState = (() => getModule(contexts))();
export const ConnectionState = (() => getModule(connection))();
export const DeviceState = (() => getModule(device))();
export const PanelsState = (() => getModule(panels))();
export const IndicatorsState = (() => getModule(indicators))();
export const BlockSettingsState = (() => getModule(blockSettings))();

declare type ConstructorOf<C> = new (...args: any[]) => C;
function getModule<M extends VuexModule>(moduleClass: ConstructorOf<M>): M {
  return getDecoratedModule(moduleClass, store);
}

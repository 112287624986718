import { RequestFilter, UnifiedResponse, Dictionary, DataResult } from '@/model';
import api from '@/services/api';
import { ContextsState } from '@/store';

/**
 * Запрос данных для множества карточек.
 * @param {Dictionary<RequestFilter>} payload Словарь фильтров карточек.
 * @returns {Promise<Dictionary<DataResult<UnifiedResponse>>>} Словарь результирующих данных карточек и/или сообщений об ошибках обработки.
 */
export async function getMultipleCardData(
  payload: Dictionary<RequestFilter>
): Promise<Dictionary<DataResult<UnifiedResponse>>> {
  return await api.post<Dictionary<DataResult<UnifiedResponse>>>(
    `/api/contexts/${ContextsState.currentContextId}/dashboards-v2/multi-card-data`,
    payload
  );
}

import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators';
import { ConnectionStatus } from '@/model';

@Module({ name: 'connection', namespaced: true })
export default class ConnectionModule extends VuexModule {
  // -1 индикация отсутствует
  // 1 соединение установлено
  // 0 соединение разорвано
  // 3 временная потеря соединения
  // 2 выполняется попытка восстановить соединение
  private _onlineStatus: ConnectionStatus = ConnectionStatus.NotSet;

  get onlineStatus() {
    return this._onlineStatus;
  }

  @Action
  public changeStatus(status: ConnectionStatus) {
    this.setStatus(status);
  }

  @Mutation
  private setStatus(status: ConnectionStatus) {
    this._onlineStatus = status;
  }
}

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// Themes
import {
  darkBlue,
  darkCyan,
  darkGreen,
  darkGrey,
  darkOrange,
  darkPurple,
  darkRed,
  darkTerracot,
  darkViolet,
  darkYellow,
  lightBlue,
  lightCyan,
  lightGreen,
  lightGrey,
  lightOrange,
  lightPurple,
  lightRed,
  lightTerracot,
  lightViolet,
  lightYellow,
} from './themes';

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    components,
    directives,
    theme: {
      defaultTheme: 'lightBlue',
      themes: {
        darkBlue,
        darkCyan,
        darkGreen,
        darkGrey,
        darkOrange,
        darkPurple,
        darkRed,
        darkTerracot,
        darkViolet,
        darkYellow,
        lightBlue,
        lightCyan,
        lightGreen,
        lightGrey,
        lightOrange,
        lightPurple,
        lightRed,
        lightTerracot,
        lightViolet,
        lightYellow,
      },
    },
    defaults: {
      VTextField: {
        density: 'compact',
        elevated: 0,
        bgColor: 'input-background',
        flat: true,
        hideDetails: true,
      },
      VCombobox: {
        density: 'compact',
        elevated: 0,
        bgColor: 'input-background',
        flat: true,
        hideDetails: true,
        returnObject: true,
        menuIcon: 'mdi-chevron-down',
      },
      VSelect: {
        density: 'compact',
        elevated: 0,
        bgColor: 'input-background',
        flat: true,
        hideDetails: true,
        returnObject: true,
        menuIcon: 'mdi-chevron-down',
      },
    },
  }
);

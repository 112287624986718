import { StatusTypeEnum } from '@/model';
import { addDurationToDate, roundDate } from './dateUtils';
export const ADD_THREE_DAYS = 3;

/**
 * Returns valid window.location.origin in IE11-.
 * @export
 * @returns {string}
 */
export function getLocationOrigin(): string {
  const location = window.location;
  let origin = location.origin;
  if (!origin) {
    origin = `${location.protocol}/${location.hostname}`;
    if (location.port) {
      origin += location.port;
    }
  }
  return origin;
}

/**
 * Returns application base url bases on BASE_URL from env.
 * @export
 * @returns {string}
 */
export function getBaseUrl(): string {
  const origin = getLocationOrigin();
  return `${origin}${process.env.BASE_URL}`;
}

/** определяет точность после запятой. TODO: Move it to numberUtils */
export function getPrecision(numbers: number[]) {
  if (numbers?.length > 0) {
    const avg = getAvg(numbers);
    if (typeof avg === 'number' && avg !== 0 && avg > Number.EPSILON) {
      const MAX_ITERATIONS = 20;
      let value = Math.abs(avg);
      let precision = 0;
      let iteration = 0;
      while (value < 10 && iteration < MAX_ITERATIONS) {
        precision += 1;
        value = value * 10;
        iteration += 1;
      }
      if (iteration < MAX_ITERATIONS) {
        return precision;
      }
    }
  }

  return 0;
}

/** считает среднее значение. TODO: Move it to numberUtils */
export function getAvg(numbers: number[]): number {
  if (numbers?.length > 0) {
    const sum = numbers.reduce((total, value) => total + value, 0);
    const avg = sum / numbers.length;
    return avg;
  }

  return 0;
}

/** формирует label. TODO: Move it to numberUtils */
export function getLabel(precision: number[]) {
  return {
    visible: true,
    backgroundColor: 'none',
    rotationAngle: -55,
    formatPrecision: getPrecision(precision),
    formatType: 'fixedPoint',
    fontColor: 'white',
  };
}

/**
 * Checks if client browser is running on a mobile device
 * @returns {boolean} Device is mobile
 */
export function clientDeviceIsMobile() {
  return isMobile.any();
}

const isMobile = {
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  any: function () {
    return Boolean(
      isMobile.Windows() || isMobile.Android() || isMobile.iOS() || isMobile.Opera() || isMobile.BlackBerry()
    );
  },
};

/** weather helpers */
export function getEndDayWeather(date: Date): Date {
  return roundDate(addDurationToDate(date.toString(), ADD_THREE_DAYS));
}

export function returnLastItem<T>(arr?: Array<T>): T | undefined {
  if (arr && arr.length > 0) {
    return arr[arr.length - 1];
  }
  return undefined;
}

export function cloneDeep<T>(data: T): T {
  return JSON.parse(JSON.stringify(data)) as T;
}

/**
 * Function to group array by property
 * @param array Array to group
 * @param key Property key to group by
 * @param reduceFn Or function to group by
 * @returns Dictionary of object arrays
 */
 export function groupBy<T>(array: Array<T>, key?: string, reduceFn?: (item: T) => string): { [key: string] : Array<T>; }
 {
   return array.reduce((acc: any, obj) => {
     let property = '';
     if (key) {
       property = String((obj as any)[key]);
     } else if (typeof reduceFn === 'function') {
       property = String(reduceFn(obj));
     } else {
       return acc;
     }
 
     acc[property] = acc[property] || [];
     acc[property].push(obj);
     return acc;
   }, {});
 }
 
 export function throttle(fn: any, wait = 300) {
  let inThrottle: boolean,
      lastFn: ReturnType<typeof setTimeout>,
      lastTime: number;

  return function (this: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this,
          // eslint-disable-next-line prefer-rest-params
          args = arguments;

    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
}

/**
 * Function to distinct array items by results of the specified function calls
 * @param array Array to distinct its items
 * @param distinctFn Function to get item value to check for distinct
 * @returns Array of distinct items by results of their caclulated values
 */
export function distinctBy<TItem, TValue>(array: Array<TItem>, distinctFn: (item: TItem) => TValue): Array<TItem> {
  const distinctItems: TItem[] = [];
  const distinctValues: TValue[] = [];

  array.forEach(item => {
    const itemValue = distinctFn(item);
    if (distinctValues.findIndex(x => x === itemValue) === -1) {
      distinctItems.push(item);
      distinctValues.push(itemValue);
    }
  });

  return distinctItems;
}

export function getStatusDisplayName(status: StatusTypeEnum): string {
  if (status === StatusTypeEnum.Active) {
    return 'Активно';
  } else {
    return 'Не активно (архив)';
  }
}

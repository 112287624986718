/**
 * Custom class that implements date period
 * @class DatePeriod
 */
export default class DatePeriod {
  private _start: Date | undefined;
  private _end: Date | undefined;

  /**
   * Empty constructor for DatePeriod class
   */
  constructor();
  /**
   * Represent start and end date as date period class
   * @param {Date} start Start date
   * @param {Date} end End date
   */
  constructor(start: Date | string, end: Date | string);
  constructor(start?: Date | string, end?: Date | string) {
    if (start) {
      this._start = typeof start === 'string' ? new Date(start) : (start as Date);
      this._end = typeof end === 'string' ? new Date(end) : (end as Date);
    } else {
      this._start = new Date();
      this._end = new Date();
    }
  }

  public clone(): DatePeriod {
    return new DatePeriod(new Date(this.start), new Date(this.end));
  }

  /**
   * Gets start date of period
   * @returns {Date} Start date
   */
  public get start() {
    return this._start!;
  }

  /**
   * Sets start date of period
   * @returns {Date} Start date
   */
  public set start(start: Date) {
    this._start = start;
  }

  /**
   * Gets end date of period
   * @returns {Date} End date
   */
  public get end() {
    return this._end!;
  }

  /**
   * Sets end date of period
   * @returns {Date} End date
   */
  public set end(end: Date) {
    this._end = end;
  }

  /**
   * Checks if period is valid (start date is less or equal than end date)(Period may be one day)
   * @returns {Boolean} Period is valid
   */
  public get isValidPeriod() {
    return this.start && this.end && this.start < this.end;
  }

  /**
   * Checks if period equals a day (start date equals end date)
   * @returns {Boolean} Period equals day
   */
  public get isDayPeriod() {
    return this.start?.getSeconds() === this.end?.getSeconds();
  }

  /**
   * Gets list of years as numbers in period, or undefined if period is invalid
   * @returns {Number[] | undefined} Years list
   */
  public get yearsList() {
    if (!this.isValidPeriod) {
      return undefined;
    }

    const years: number[] = [];

    let start = this._start!.getFullYear();
    const end = this._end!.getFullYear();

    while (start <= end) {
      years.push(start);
      start++;
    }

    return years;
  }

  /**
   * Gets period represented as structure of years and months
   * @returns {IPeriodStructured[] | undefined} Periods
   */
  public get getPeriodStructured() {
    const years = this.yearsList;
    if (!years) {
      return undefined;
    }

    const periods: IPeriodStructured[] = [];

    let startMonth = this._start!.getMonth();
    const endMonth = this._end!.getMonth();

    years.forEach((year) => {
      const currentMonth = year === years[years.length - 1] ? endMonth : 11;
      const period: IPeriodStructured = {
        text: year,
        expanded: true,
        items: [],
      };

      while (startMonth <= currentMonth) {
        const month: IMonth = {
          value: startMonth,
          text: getMonthName(startMonth)!,
          short: getShortMonthName(startMonth),
        };
        period.items.push(month);
        startMonth++;
      }

      periods.push(period);
      startMonth = 0;
    });

    return periods;
  }

  /**
   * Gets number of days in selected month, that are included in DatePeriod.
   * @param {Number} year Year, for whitch duration is calculated
   * @param {Number} month Munth, for whitch duration is calculated
   * @returns {Number | undefined}Number of days in selected month, that are included in DatePeriod or
   * undefined if date doesn't fits period or period is invalid.
   */
  public getIntervalMonthDuration(year: number, month: number): number | undefined {
    if (!this.dateFitsPeriod(year, month)) {
      return undefined;
    }

    let duration: number = daysRemainingInMonth(year, month);

    if (this._start?.getFullYear() === year) {
      if (this._start.getMonth() === month) {
        duration -= this.start.getDate();
      }
      if (this._end?.getMonth() === month) {
        duration -= this.end.getDate();
      }
    }

    return duration;
  }

  /**
   * Check if date fits period
   * @param {Date} date Date to check if it fits period
   * @returns {Boolean | undefined} If date fits period or undefined if period is invalid
   */
  public dateFitsPeriod(date: Date): boolean | undefined;
  /**
   * Check if year fits period
   * @param {Number} year Year to check if it fits period
   * @returns {Boolean | undefined} If year fits period or undefined if period is invalid
   */
  public dateFitsPeriod(year: number): boolean | undefined;
  /**
   * Check if year and month fits period
   * @param {Number} year Year to check if it fits period
   * @param {Number} month Month to check if it fits period
   * @returns {Boolean | undefined} If year and month fits period or undefined if period is invalid
   */
  public dateFitsPeriod(year: number, month: number): boolean | undefined;
  /**
   * Check if year, month and day fits period
   * @param {Number} year Year to check if it fits period
   * @param {Number} month Month to check if it fits period
   * @param {Number} day Day to check if it fits period
   * @returns {Boolean | undefined} If year, month and day fits period or undefined if period is invalid
   */
  public dateFitsPeriod(year: number, month: number, day: number): boolean | undefined;
  public dateFitsPeriod(yearOrDate: number | Date, month?: number, day?: number): boolean | undefined {
    if (!this.isValidPeriod) {
      return undefined;
    }

    if (yearOrDate instanceof Date) {
      return this._start! <= yearOrDate && yearOrDate <= this._end!;
    } else {
      let fits = true;
      fits = this._start!.getFullYear() <= yearOrDate && yearOrDate <= this._end!.getFullYear();

      if (month && fits) {
        fits =
          (this._start!.getMonth() <= month || this._start!.getFullYear() < yearOrDate) &&
          (month <= this._end!.getMonth() || this._end!.getFullYear() > yearOrDate);

        if (day && fits) {
          fits = this._start!.getDay() <= day && day <= this._end!.getDay();
        }
      }

      return fits;
    }
  }

  /**
   * Check if current DatePeriod has intersection with given period
   * @param {DatePeriod} period Date period
   * @returns {Boolean} If current DatePeriod has intersection with given period, or null if current period is invalid
   */
  public hasIntersection(period: DatePeriod): boolean | null;
  /**
   * Check if current DatePeriod has intersection with given period
   * @param {Date} start Start date
   * @param {Date} end End date
   * @returns {Boolean} If current DatePeriod has intersection with given period, or null if current period is invalid
   */
  public hasIntersection(start: Date, end: Date): boolean | null;
  public hasIntersection(periodOrStart: DatePeriod | Date, end?: Date): boolean | null {
    if (!this.isValidPeriod) {
      return null;
    }

    if (!end) {
      return this.getIntersection(periodOrStart as DatePeriod) !== undefined;
    } else {
      return this.getIntersection(periodOrStart! as Date, end!) !== undefined;
    }
  }

  /**
   * Get intersection with given period if any
   * @param {DatePeriod} period Date period
   * @returns {DatePeriod | undefined | null} Current DatePeriod intersection with given period if any,
   * undefined if there is no intercetions, or null if current period is invalid
   */
  public getIntersection(period: DatePeriod): DatePeriod | undefined | null;
  /**
   * Get intersection with given period if any
   * @param {Date} start Start date
   * @param {Date} end End date
   * @returns {DatePeriod | undefined | null} Current DatePeriod intersection with given period if any,
   * undefined if there is no intercetions, or null if current period is invalid
   */
  public getIntersection(start: Date, end: Date): DatePeriod | undefined | null;
  public getIntersection(periodOrStart: Date | DatePeriod, end?: Date): DatePeriod | undefined | null {
    if (!this.isValidPeriod) {
      return null;
    }

    let _start: Date | undefined;
    let _end: Date | undefined;

    if (end) {
      _start = periodOrStart as Date;
      _end = end as Date;
    } else {
      _start = (periodOrStart as DatePeriod).start;
      _end = (periodOrStart as DatePeriod).end;
    }

    const crossingStart = maxDate(this.start!, _start!);
    const crossingEnd = minDate(this.end!, _end!);
    return crossingStart < crossingEnd ? new DatePeriod(crossingStart, crossingEnd) : undefined;
  }

  /**
   * Check if current DatePeriod is equal to given period
   * @param {DatePeriod} period Date period
   * @returns {Boolean} If current DatePeriod is equal to given period
   */
  public equals(period: DatePeriod): boolean;
  /**
   * Check if current DatePeriod is equal to given period
   * @param {Date} start Start date
   * @param {Date} end End date
   * @returns {Boolean} If current DatePeriod is equal to given period
   */
  public equals(start: Date, end: Date): boolean;
  public equals(periodOrStart: DatePeriod | Date, end?: Date): boolean {
    if (!end) {
      return this.start === periodOrStart && this.end === end;
    } else {
      return this.start === (periodOrStart as DatePeriod).start && this.end === (periodOrStart as DatePeriod).end;
    }
  }

  /**
   * Validates date strings for period. If both are valid - checks that start date less or equal then end date.
   * If so - returns object of DatePeriod class with given start and end dates. If not - returns undefined.
   * @param {String} start Start date
   * @param {String} end End date
   * @returns {DatePeriod | undefined} Values are valid for period
   */
  public validateDate(start: string, end: string): DatePeriod | undefined;
  /**
   * Checks that start date less or equal then end date.
   * If so - returns object of DatePeriod class with given start and end dates. If not - returns undefined.
   * @param {String} start Start date
   * @param {String} end End date
   * @returns {DatePeriod | undefined} Values are valid for period
   */
  public validateDate(start: Date, end: Date): DatePeriod | undefined;
  public validateDate(start: Date | string, end: Date | string): DatePeriod | undefined {
    if (typeof start === 'string' && typeof end === 'string') {
      if (this.isValidDateString(start) && this.isValidDateString(end)) {
        const _start = new Date(start);
        const _end = new Date(end);
        if (_start < _end) {
          this._start = _start;
          this._end = _end;
          return this;
        } else {
          return undefined;
        }
      }
    } else {
      if (start < end) {
        this._start = start as Date;
        this._end = end as Date;
        return this;
      } else {
        return undefined;
      }
    }

    return undefined;
  }

  /**
   * Validates if date string represents valid date
   * @param date Date for validation
   * @returns {boolean} Date is valid
   */
  public isValidDateString(date: string): boolean {
    return typeof new Date(date).getTime() === 'number';
  }
}

// TODO: Интерфейс для формирования комплексного ответа о состоянии периода дат (дополнять по мере расширения класса)
export interface DatePeriodValidationResult {
  intersections: {
    hasIntersections: boolean;
    message: string;
  };
  validPeriod: {
    isValid: boolean;
    message: string;
  };
}

/**
 * Date period as list structures representation
 */
export interface IPeriodStructured {
  text: number;
  expanded: boolean;
  items: IMonth[];
}

/**
 * Month representation
 */
export interface IMonth {
  value: number;
  text: string;
  short: string;
}

/**
 * Months names list and values
 */
export const months: IMonth[] = [
  {
    value: 0,
    text: 'Январь',
    short: 'Янв',
  },
  {
    value: 1,
    text: 'Февраль',
    short: 'Фев',
  },
  {
    value: 2,
    text: 'Март',
    short: 'Мар',
  },
  {
    value: 3,
    text: 'Апрель',
    short: 'Апр',
  },
  {
    value: 4,
    text: 'Май',
    short: 'Май',
  },
  {
    value: 5,
    text: 'Июнь',
    short: 'Июн',
  },
  {
    value: 6,
    text: 'Июль',
    short: 'Июл',
  },
  {
    value: 7,
    text: 'Август',
    short: 'Авг',
  },
  {
    value: 8,
    text: 'Сентябрь',
    short: 'Сен',
  },
  {
    value: 9,
    text: 'Октябрь',
    short: 'Окт',
  },
  {
    value: 10,
    text: 'Ноябрь',
    short: 'Ноя',
  },
  {
    value: 11,
    text: 'Декабрь',
    short: 'Дек',
  },
];

export const MINUS_MONTH = -1;

/**
 * Get max value of two dates
 * @param date1 First date for comparation
 * @param date2 Second date for comparation
 * @returns {Date} Max date value
 */
export function maxDate(date1: Date, date2: Date) {
  return date1 > date2 ? date1 : date2;
}

/**
 * Get min value of two dates
 * @param date1 First date for comparation
 * @param date2 Second date for comparation
 * @returns {Date} Min date value
 */
export function minDate(date1: Date, date2: Date) {
  return date1 < date2 ? date1 : date2;
}

/**
 * Compares two dates
 * @param {Date} dateA Date A to compare
 * @param {Date} dateB Date B to compare
 */
export function compareDates(dateA: Date, dateB: Date) {
  return +new Date(dateA) - +new Date(dateB);
}

/**
 * Gets month name by it's number
 * @param month Month as number (values between 1 and 12)
 * @returns {number | undefined} Month name
 */
export function getMonthName(month: number) {
  if (month < 0 || month > 11) {
    return undefined;
  }

  return months.find((value) => value.value === month)?.text;
}

/**
 * Calculate days remaining in given month
 * @param {Date} date Date to calculate days remaining
 * @returns {Number} Number of days remaining in given month
 */
export function daysRemainingInMonth(date: Date): number;
/**
 * Calculate days remaining in given month
 * @param {Number} year Year to calculate days remaining
 * @param {Number} month Month to calculate days remaining
 * @returns {Number} Number of days remaining in given month
 */
export function daysRemainingInMonth(year: number, month: number): number;
export function daysRemainingInMonth(dateOrYear: number | Date, month?: number): number {
  if (typeof dateOrYear !== 'number') {
    const year = (dateOrYear as Date).getFullYear();
    const month = (dateOrYear as Date).getMonth();
    return new Date(year, month, 0).getDate();
  } else {
    return new Date(dateOrYear, month!, 0).getDate();
  }
}

/**
 * Gets month start date of current date month
 * @param {Date} curDate Current date
 * @returns {Date} Months start date
 */
export function getMonthStart(curDate?: Date): Date {
  const date = curDate ?? new Date();

  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1, 0, 0, 0));
}

/**
 * Gets next month start date of current date month
 * @param {Number} Months count since current date
 * @param {Date} curDate Current date
 * @returns {Date} Months start date
 */
export function getNextMonthStart(duration: number, curDate?: Date): Date {
  const date = curDate ?? new Date();

  return new Date(Date.UTC(date.getFullYear(), date.getMonth() + duration, 1, 0, 0, 0));
}

/**
 * Gets month end date of current date month
 * @param {Date} curDate Current date
 * @returns {Date} Months end date
 */
export function getMonthEnd(curDate?: Date): Date {
  const date = curDate ?? new Date();
  const result = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0));
  result.setDate(result.getDate() - 1);

  return result;
}

/**
 * Gets year start date of current date
 * @param {Date} curDate Current date
 * @returns {Date} Year start date
 */
export function getYearStart(curDate?: Date): Date {
  const date = curDate ?? new Date();
  return new Date(Date.UTC(date.getUTCFullYear(), 0, 1, 0, 0, 0));
}

/**
 * Gets year end date of current date
 * @param {Date} curDate Current date
 * @returns {Date} Year end date
 */
export function getYearEnd(curDate?: Date): Date {
  const date = curDate ?? new Date();
  const result = new Date(Date.UTC(date.getUTCFullYear() + 1, 0, 1, 0, 0, 0));

  result.setDate(result.getDate() - 1);

  return result;
}

/**
 * Get current year number
 * @returns {Number} Current year
 */
export function getCurrentYear(): number {
  return new Date().getFullYear();
}

/**
 * Gets short name of month
 * @param {Number} month Month number
 * @returns {String} Short month name
 */
export function getShortMonthName(month: number) {
  return months[month].short;
}

export function getShortDate(date: string) {
  const dateShortString = new Date(date);
  return `${getShortMonthName(dateShortString.getMonth())} ${dateShortString.getFullYear()}`;
}

/**
 * Adds months count to date
 * @param {Date} date Date to add months to
 * @param {Number} month Number of months to add
 * @returns {Date} Date plus months count as date
 */
export function addMonth(date: Date, month: number): Date {
  return new Date(date.setMonth(date.getMonth() + month));
}

/**
 * Get week number from year start
 * @param date Date to calculate week number
 * @returns {Number} Week number
 */
export function getNumberOfWeek(date: Date): number {
  const firstJan = new Date(date.getFullYear(), 0, 1);
  const week = Math.ceil(((date.getTime() - firstJan.getTime()) / 86400000 + firstJan.getDay() + 1) / 7);
  return week;
}

/**
 * Gets date from week number
 * @param {Number} week Week to calculate
 * @param {Number} year Year to calculate
 * @returns {Date} Date
 */
export function getDateFromWeekNumber(week: number, year: number): Date {
  const day = 1 + (week - 1) * 7;

  return new Date(year, 0, day);
}

/**
 * Get days number in month
 * @param month Month
 * @param year Year
 * @returns Days in month
 */
export function daysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

/**
 * Gets duration between dates
 * @param {String} start Start date
 * @param {String} end End date
 * @param {Boolean} includeCurrent Include current day in duration (+1 day) (Default: true)
 * @returns Duration
 */
export function getDuration(start: string, end: string, includeCurrent?: boolean): number;
/**
 * Gets duration between dates
 * @param {Date} start Start date
 * @param {Date} end End date
 * @param {Boolean} includeCurrent Include current day in duration (+1 day) (Default: true)
 * @returns Duration
 */
export function getDuration(start: Date, end: Date, includeCurrent?: boolean): number;
export function getDuration(start: Date | string, end: Date | string, includeCurrent = true): number {
  const endDate = includeCurrent ? addDurationToDate(new Date(end), 1) : new Date(end);
  return (new Date(endDate).valueOf() - new Date(start).valueOf()) / (60 * 60 * 24 * 1000);
}

/**
 * Rounds local date
 * @param dateTime Local date to round
 * @returns Rounded local date
 */
export function roundLocalDate(dateTime: Date): Date {
  const date = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), 0, 0, 0);

  return date;
}

/**
 * Rounds date
 * @param dateTime Date to round
 * @returns Rounded date
 */
export function roundDate(dateTime: Date): Date {
  const utc = Date.UTC(dateTime.getUTCFullYear(), dateTime.getUTCMonth(), dateTime.getUTCDate(), 0, 0, 0);

  return new Date(utc);
}

/**
 * Adds duration to date
 * @param {Date} dateTime Date
 * @param {Number} duration Duration
 * @returns {Date} Date
 */
export function addDurationToDate(dateTime: Date, duration: number): Date;
/**
 * Adds duration to date
 * @param {String} dateTime Date
 * @param {Number} duration Duration
 * @returns {Date} Date
 */
export function addDurationToDate(dateTime: string, duration: number): Date;
export function addDurationToDate(dateTime: string | Date, duration: number): Date {
  const date = new Date(dateTime);
  date.setDate(date.getDate() + duration);
  return date;
}

/**
 * Add duration in months to date
 * @param {Date} dateTime Date
 * @param {Number} duration Duration
 * @returns {Date} Date plus duration
 */
export function addDurationToDateMonth(dateTime: Date, duration: number): Date;
/**
 * Add duration in months to date
 * @param {String} dateTime Date
 * @param {Number} duration Duration
 * @returns {Date} Date plus duration
 */
export function addDurationToDateMonth(dateTime: string, duration: number): Date;
export function addDurationToDateMonth(dateTime: Date | string, duration: number): Date {
  const date = new Date(dateTime);
  date.setMonth(date.getMonth() + duration);
  return date;
}

/**
 * Gets yesterdays date for current date
 * @returns {Date} Yesterdays date
 */
export function yesterdayDate(): Date {
  return roundDate(addDurationToDate(new Date().toString(), -1));
}

/**
 * Gets previous month date
 * @param {Date} date Date
 * @returns {Date} Previous month date
 */
export function previousMonthDate(date: Date): Date {
  return addDurationToDateMonth(date.toString(), MINUS_MONTH);
}

/**
 * Gets UTC date from local date
 * @param {Date} localDate Local date
 * @returns {Date} UTC date
 */
export function utcFromLocalDate(localDate: Date): Date {
  const utc = Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), 0, 0, 0);
  return new Date(utc);
}

/**
 * Gets UTC DateTime from local DateTime
 * @param {Date} localDate Local DateTime
 * @returns {Date} UTC date
 */
export function utcFromLocalDateTime(localDate: Date): Date {
  const utc = Date.UTC(
    localDate.getFullYear(),
    localDate.getMonth(),
    localDate.getDate(),
    localDate.getHours(),
    localDate.getMinutes(),
    localDate.getSeconds()
  );
  return new Date(utc);
}

/**
 * Gets local DateTime from UTC DateTime
 * @param {Date} utcDate UTC DateTime
 * @returns {Date} Local date
 */
export function localFromUtcDateTime(date: Date): Date {
  const local = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return local;
}

/**
 * Renders local date
 * @param {Date | string} date Date to render
 * @returns {string} Rendered date
 */
export function renderLocalDate(date: Date | string) {
  return new Date(date).toLocaleDateString();
}

/**
 * Renders UTC date
 * @param {Date | string} date Date to render
 * @returns {string} Rendered date
 */
export function renderUtcDate(date: Date | string) {
  return renderLocalDate(localFromUtcDateTime(new Date(date)));
}

/**
 * Shifts end period date from internal date (next day 00:00:00) to user-friendly date
 * @param {Date} internalEndDate Internal end period date
 * @returns {Date} User-friendly end period date
 */
export function endPeriodInternalToDisplayed(internalEndDate: Date): Date {
  return addDurationToDate(internalEndDate, -1);
}

/**
 * Shifts end period date from user-friendly date to internal date (next day 00:00:00)
 * @param {Date} displayedEndDate User-friendly end period date
 * @returns {Date} Internal end period date
 */
export function endPeriodDisplayedToInternal(displayedEndDate: Date): Date {
  return addDurationToDate(displayedEndDate, 1);
}


import useAppUpdate from '@/hooks/useAppUpdate';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UpdateDialog',
  setup() {
    return useAppUpdate();
  },
});


import { defineComponent } from 'vue';

import { useDisplay } from 'vuetify';
import auth from '@/services/auth';
import events from '@/services/events';
import endpoint from '@/services/endpoint';
import { ContextsState, UserState } from './store';
import Spinner from '@/components/controls/common/Spinner.vue';
import UpdateDialog from './components/controls/pwa/UpdateDialog.vue';

enum LoadingState {
  Loading,
  ServerUnavailable,
  Error,
  Finished,
}

export default defineComponent({
  name: 'App',
  components: {
    Spinner,
    UpdateDialog,
  },
  setup() {
    const { mobile } = useDisplay();

    return { mobile };
  },
  data() {
    return {
      state: LoadingState.Loading,
    };
  },
  methods: {
    async initAuth(): Promise<boolean> {
      const settings = endpoint.config.Auth!;
      if (!settings) {
        throw new Error('No openid-connect settings.');
      }

      await auth.init(settings);
      const user = await auth.profile;

      if (!user) {
        void auth.login();
        return false;
      }

      return true;
    },
  },
  computed: {
    themeName(): string {
      return ContextsState.themeName;
    },
    loading(): boolean {
      return this.state === LoadingState.Loading;
    },
    serverUnavailable(): boolean {
      return this.state === LoadingState.ServerUnavailable;
    },
    hasError(): boolean {
      return this.state === LoadingState.Error;
    },
    initialized(): boolean {
      return this.state === LoadingState.Finished;
    },
  },
  async mounted() {
    // Начальная инициализация и получение настроек клиента
    try {
      await endpoint.startupAsync();
    } catch {
      this.state = LoadingState.ServerUnavailable;
      return;
    }

    // Инициализация сервисов
    try {
      if (await this.initAuth()) {
        await UserState.loadUser();
        await events.startupAsync();
        await ContextsState.loadContexts(this.mobile);
      }
    } catch (error) {
      console.log('Startup error:', error);
      this.state = LoadingState.Error;
      return;
    }

    this.state = LoadingState.Finished;
  },
});

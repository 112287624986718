
import { Options, Vue } from 'vue-class-component';
import DxButton from 'devextreme-vue/button';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';

import { BlockSettingType } from '../types';

@Options({
  components: {
    DxToolbarItem,
    DxButton,
    DxPopup,
  },
  props: {
    settings: {
      type: Array,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: 'Настройки',
    },
    height: {
      type: Number,
      required: false,
      default: 600,
    },
    width: {
      type: Number,
      required: false,
      default: 500,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
export default class Settings extends Vue {
  public settings!: BlockSettingType[] | null;
  public title!: string;

  public height!: number;
  public width!: number;
  public fullscreen!: boolean;

  public fs = false;

  public mounted() {
    this.fs = this.fullscreen;
  }

  public settingsVisible = false;

  public show() {
    this.settingsVisible = true;
  }

  public save() {
    this.$emit('onSettingsSave');
    this.settingsVisible = false;
  }

  public cancel() {
    this.$emit('onSettingsCancel');
    this.settingsVisible = false;
  }

  public get isFullscreen() {
    return this.$compactView || this.fs;
  }

  public setFullscreen() {
    this.fs = !this.fs;
    this.$emit('update:fullscreen', this.fs);
  }
}

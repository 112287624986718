import { Theme } from '@/plugins/types';
import { base } from './base';

export const lightCyan: Theme = {
  dark: false,
  light: true,
  colors: {
    ...base,
    primary: '#009688',
    'primary-darken-1': '#009688',
    secondary: '#009688',
    'secondary-darken-1': '#009688',
  },
};

/** Методика межузловой агрегации (группировки) значений - многих кортежей узлов в одно группирующее значение, например, по дочерним */
export enum NodesAggregationType {
  /** Сумма значений */
  Sum = 'Sum',
  /** Среднее арифметическое значений */
  Average = 'Average',
  /** Максимальное значение */
  Max = 'Max',
  /** Минимальное значение */
  Min = 'Min',
}

import { Theme } from '@/plugins/types';
import { base } from './base';

export const lightOrange: Theme = {
  dark: false,
  light: true,
  colors: {
    ...base,
    primary: '#F44336',
    'primary-darken-1': '#F44336',
    secondary: '#F44336',
    'secondary-darken-1': '#F44336',
  },
};

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_UpdateDialog = _resolveComponent("UpdateDialog")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { theme: _ctx.themeName }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass({ 'app-status': !_ctx.initialized })
      }, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_spinner, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.serverUnavailable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Сервер недоступен."))
          : _createCommentVNode("", true),
        (_ctx.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Ошибка при загрузке приложения."))
          : _createCommentVNode("", true),
        (_ctx.initialized)
          ? (_openBlock(), _createBlock(_component_router_view, { key: 3 }))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_UpdateDialog)
    ]),
    _: 1
  }, 8, ["theme"]))
}
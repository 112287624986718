import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-733b700e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "block-toolbar"
}
const _hoisted_2 = {
  key: 2,
  class: "block-content"
}
const _hoisted_3 = {
  key: 1,
  class: "block-center"
}
const _hoisted_4 = { class: "block-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "no-data-tooltip-content" }
const _hoisted_8 = {
  key: 4,
  class: "editing-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_DxDropDownButton = _resolveComponent("DxDropDownButton")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_DxTooltip = _resolveComponent("DxTooltip")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    style: _normalizeStyle(_ctx.type === 'Separator' || _ctx.inFolder || _ctx.noBordersStyle ? undefined : _ctx.borders),
    color: _ctx.getColor,
    class: _normalizeClass([
      'block-container',
      !_ctx.overridePadding ? 'padding-old' : 'padding-none',
      { repaint: _ctx.moving },
      { '!m-0 !py-1 !px-2': _ctx.type === 'Separator' || _ctx.inFolder || _ctx.smallOffset },
    ]),
    onClick: _ctx.pickModule
  }, {
    default: _withCtx(() => [
      (_ctx.showToolbar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "toolbar", {}, () => [
              _createVNode(_component_Toolbar, {
                title: _ctx.title,
                subtitle: _ctx.subtitle
              }, null, 8, ["title", "subtitle"])
            ], true),
            (_ctx.showDropDownButton)
              ? (_openBlock(), _createBlock(_component_DxDropDownButton, {
                  key: 0,
                  class: "settings-drop-down",
                  "show-arrow-icon": false,
                  "use-select-mode": false,
                  "active-state-enabled": false,
                  dropDownOptions: {
          width: 200,
        },
                  items: _ctx.dropDownItems,
                  icon: "more",
                  onItemClick: _ctx.dropDownItemClick,
                  "styling-mode": "text",
                  "display-expr": "name",
                  "key-expr": "id"
                }, null, 8, ["items", "onItemClick"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showContent)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            ref: "block",
            class: "block-content",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pickModule && _ctx.pickModule(...args)))
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 512))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.dataIsLoading)
              ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                  key: 0,
                  class: "block-center",
                  indeterminate: "",
                  color: "primary"
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.noDataText || 'Нет данных'), 1),
                  (_ctx.noDataDetails)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _createElementVNode("i", {
                          class: "dx-icon-info no-data-info-icon",
                          id: _ctx.noDataDetailsTooltipId,
                          onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeNoDataDetailsVisibility(true))),
                          onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeNoDataDetailsVisibility(false)))
                        }, null, 40, _hoisted_6),
                        _createVNode(_component_DxTooltip, {
                          "close-on-outside-click": false,
                          target: `#${_ctx.noDataDetailsTooltipId}`,
                          visible: _ctx.isNoDataDetailsVisible,
                          "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isNoDataDetailsVisible) = $event)),
                          position: "bottom",
                          width: "500px"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.noDataDetails), 1)
                          ]),
                          _: 1
                        }, 8, ["target", "visible"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
          ])),
      (_ctx.areSettingsVisible)
        ? (_openBlock(), _createBlock(_component_Settings, {
            key: 3,
            ref: "settings-popup",
            title: _ctx.settingsTitle,
            height: _ctx.settingsHeight,
            width: _ctx.settingsWidth,
            fullscreen: _ctx.settingsFullscreen,
            onOnSettingsSave: _ctx.onSettingsSave,
            onOnSettingsCancel: _ctx.onSettingsCancel
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "settings", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["title", "height", "width", "fullscreen", "onOnSettingsSave", "onOnSettingsCancel"]))
        : _createCommentVNode("", true),
      (_ctx.editMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (!_ctx.hideSettingsIcon)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  icon: "mdi-cog",
                  density: "compact",
                  flat: "",
                  onClick: _ctx.showChartSettings
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.panelsToCopy.length > 0 && _ctx.block)
              ? (_openBlock(), _createBlock(_component_v_menu, {
                  key: 1,
                  "close-on-content-click": true,
                  location: "bottom"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _mergeProps(props, {
                      icon: "mdi-content-duplicate",
                      density: "compact",
                      flat: ""
                    }), null, 16)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panelsToCopy, (panel) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: panel.id,
                            title: panel.name,
                            value: panel.id,
                            subtitle: _ctx.currentPanelSubtitle(panel.id),
                            onClick: ($event: any) => (_ctx.copyBlock(panel.id))
                          }, null, 8, ["title", "value", "subtitle", "onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_btn, {
              icon: "mdi-trash-can-outline",
              density: "compact",
              flat: "",
              onClick: _ctx.removePanelBlock
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["style", "color", "class", "onClick"]))
}
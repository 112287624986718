import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2167fe00"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", {
    class: "s-label",
    title: _ctx.text
  }, _toDisplayString(_ctx.text), 9, _hoisted_1))
}
import { IndicatorClassifier } from '@/model';
import { StatusTypeEnum } from '@/model/legacy/CommonData';

/**
 * Тип данных для выбора показателя из списка, с привязками к графам, облегченная модель.
 * Используется для новых универсальных блоков (карточек).
 * TODO: Поправить опечатку и дать лучшее имя позже, т.к. "IndicatorLight" уже используется.
 */
export interface IndicatorLite {
  id: string;
  title: string;
  classifiers: IndicatorClassifier[];
}

/** DTO. Показатель для контекста, хранится в БД, полная модель */
export interface IndicatorFull {
  /** Guid. Не указывается только при создании нового показатея */
  id?: string;
  title: string;
  status: StatusTypeEnum;
  /** Енум в случае системного показателя MtrItemTypeEnum as string; не указано для пользовательских показателей */
  systemType?: string;
  settings: IndicatorSettings;
}

/** DTO. Расширенные настройки показателя для хранения в JSON виде */
export interface IndicatorSettings {
  /** Пример: ["Ресурсы", "Транспорт"], а Title = "План почасовая". Порядок важен */
  grouping?: string[];
  /** Набор тегов-строк. Порядок неважен */
  tags?: string[];
  /** Методика расчет показателя */
  calculation: IndicatorCalculationEnum;
  /** Размерность (разрядность) ввода и хранения показателя: единиц, тыс., млн., млрд. ... */
  dimension: IndicatorDimensionEnum;
  /** Единицы измерения, текст, например: "шт.", "мин.", "ч.", "чел.", "кг" */
  unitName?: string;
  /** Необязательное текстовое описание показателя */
  description?: string;
  /** Разрешено вводить на год. true - да; false или null/undefined - нет */
  allowInputYear?: boolean;
  /** Разрешено вводить на месяц. true - да; false или null/undefined - нет */
  allowInputMonth?: boolean;
  /** Разрешено вводить на день. true - да; false или null/undefined - нет */
  allowInputDay?: boolean;
  /**
   * TODO: Подумать, надо оно или нет. Наверное, всегда должно быть разрешено, чтобы не ограничивать пользователя.
   * Т.е. наверное, не надо отдельным полем.
   * Разрешено планирование
   * */
  //allowPlanning?: boolean;
}

/** Методика расчет показателя */
export enum IndicatorCalculationEnum {
  /** Интервальный показатель (сумма за период) */
  Interval = 'Interval',
  /** Моментальный показатель (новое вытесняет старое) */
  Momentary = 'Momentary',
}

/** Размерность (разрядность) ввода и хранения показателя: единиц, тыс., млн., млрд. ... */
export enum IndicatorDimensionEnum {
  /** Единицы */
  Unit = 'Unit',
  /** Тысячи */
  Thousand = 'Thousand',
  /** Миллионы */
  Million = 'Million',
  /** Миллиарды */
  Billion = 'Billion',
}

import { ModuleTypeEnum } from '@/model';
import { Theme, ThemesEnum } from '@/model/system/themes';

export const systemThemes: Theme[] = [
  {
    id: 0,
    name: 'Logotech dark',
    colors: [
      {
        name: 'background',
        color: '#3a395b',
      },
      {
        name: 'defaultBlockBackgroundColor',
        color: '#3a395b',
      },
      {
        name: 'textColor',
        color: 'white',
      },
      {
        name: 'gridColor',
        color: '#B2B2B2',
      },
      {
        name: 'boxShadow1',
        color: '#333251',
      },
      {
        name: 'boxShadow2',
        color: '#302f4c',
      },
    ],
    blockRoundedCorners: false,
    blockShadows: true,
    textColor: 'white',
    typeName: ThemesEnum.LogotechDark,
    systemName: 'logotech.dark',
    paths: {
      primary: 'css/dx.logotech.dark.css',
      secondary: 'css/logotech-dark.css',
    },
    backgroundTooltip: 'black',
    backgroundImage: null,
    icons: {
      main: 'logotech',
      main_mobile: 'logotech_small',
      footer_icon: 'isource',
    },
    isDefault: true,
    blockBackgrounds: [
      {
        types: [ModuleTypeEnum.Separator],
        image: null,
      },
    ],
  },
  {
    id: 1,
    name: 'Logotech light',
    colors: [
      {
        name: 'background',
        color: '#fff',
      },
      {
        name: 'defaultBlockBackgroundColor',
        color: 'white',
      },
      {
        name: 'textColor',
        color: 'black',
      },
      {
        name: 'gridColor',
        color: '#D3D3D3',
      },
      {
        name: 'boxShadow1',
        color: '#f2f3f3',
      },
      {
        name: 'boxShadow2',
        color: '#f2f3f3',
      },
    ],
    blockRoundedCorners: false,
    blockShadows: true,
    textColor: 'black',
    typeName: ThemesEnum.LogotechLight,
    systemName: 'logotech.light',
    paths: {
      primary: 'css/dx.logotech.light.css',
      secondary: 'css/logotech-light.css',
    },
    backgroundImage: null,
    backgroundTooltip: 'white',
    icons: {
      main: 'logotech-light',
      main_mobile: 'logotech_small-light',
      footer_icon: 'isource-light',
    },
    isDefault: false,
    blockBackgrounds: [
      {
        types: [ModuleTypeEnum.Separator],
        image: null,
      },
    ],
  },
  {
    id: 3,
    name: 'Проектные показатели',
    colors: [
      {
        name: 'background',
        color: '#03438A',
      },
      {
        name: 'defaultBlockBackgroundColor',
        color: '#03438A',
      },
      {
        name: 'textColor',
        color: 'white',
      },
      {
        name: 'gridColor',
        color: '#B2B2B2',
      },
      {
        name: 'boxShadow1',
        color: '#333251',
      },
      {
        name: 'boxShadow2',
        color: '#302f4c',
      },
    ],
    blockRoundedCorners: true,
    blockShadows: false,
    blockBorderColor: '#071132',
    textColor: 'white',
    typeName: ThemesEnum.LogotechDark,
    systemName: 'logotech.dark',
    paths: {
      primary: 'css/dx.logotech.dark.css',
      secondary: 'css/logotech-dark.css',
    },
    backgroundTooltip: 'black',
    backgroundImage: null,
    icons: {
      main: 'ProMetrics',
      main_mobile: 'ProMetricsSmall',
      footer_icon: 'isource',
    },
    isDefault: false,
    blockBackgrounds: [
      {
        types: [
          ModuleTypeEnum.ProjectIndicatorComponent,
          ModuleTypeEnum.ProjectIndicatorGroupProjects,
          ModuleTypeEnum.ProjectIndicatorGroupProjectsTotal,
          ModuleTypeEnum.ProjectIndicatorGroupUsers,
          ModuleTypeEnum.ProjectIndicatorGroupUsersTotal,
          ModuleTypeEnum.ProjectDynamicsUsersChart,
          ModuleTypeEnum.TextBlock,
        ],
        image: () => import('@/assets/background-dark.png'),
      },
      {
        types: [ModuleTypeEnum.Separator],
        image: null,
      },
    ],
  },
];

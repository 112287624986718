import { ITreeListSelectedItem } from '@/components/controls/filterTree/types';
import { IIndicatorsTreeNode } from '@/components/InputData/common/models';
import { BlockConfig, ClassifierTypeEnum } from '@/model';
import {
  ChartPeriodEnum,
  GraphGroupByDataItem,
  PanelBlock,
  PanelBlockConfig,
  IModuleSeries,
  InputTableChildrenSumEnum,
  InputTableClassificationEnum,
} from '@/model';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

export enum SettingsType {
  None = 'none',
  Dashboard = 'dashboard',
  Chart = 'chart',
  Main = 'main',
  Input = 'input',
}

export interface ClassifierSelection {
  allTreeItems: ITreeListSelectedItem[];
  /** Для графов по классификаторам - string; для полного графа - null. */
  classifierRootId: string | null;
}

/** @deprecated Будет удалено */
export interface ClassifierTypesFilterPayload {
  /** Для графов по классификаторам - string; для полного графа - null. */
  classifierRootId: string | null;
  classifierTypes: (ClassifierTypeEnum | string)[];
}

export interface ClassifierTypesFilterResetPayload {
  /** Для графов по классификаторам - string; для полного графа - null. */
  classifierRootId: string | null;
  allTreeItems: ITreeListSelectedItem[];
}

@Module({ name: 'blockSettings', namespaced: true })
export default class BlockSettingsModule extends VuexModule {
  /** Активна ли сейчас настройка блока */
  private _isSettingMode = false;

  /** Тип текущего настраиваемого блока */
  private _settingsType: SettingsType = SettingsType.None;

  /** Инстанс параметров блока для настройки */
  private _pickedItem: PanelBlockConfig | null = null;

  /** Активна ли сейчас настройка блока */
  public get isSettingMode() {
    return this._isSettingMode;
  }

  /** Тип настраиваемого блока */
  public get settingType(): SettingsType {
    return this._settingsType;
  }

  /** Id инстанса текущего настраиваемого блока */
  public get pickedItemId(): string | null {
    return this._pickedItem?.panelBlockId ?? null;
  }

  /** Текущий настраиваемый блок. */
  public get pickedBlock(): PanelBlockConfig | undefined {
    return this._pickedItem ?? undefined;
  }

  public get selectedTimeSpans() {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      return pickedItem.config.selectedTimeSpans;
    }
  }

  @Mutation
  public setSettingMode(type: SettingsType) {
    const isTypeSpecified: boolean = type !== SettingsType.None;

    if (isTypeSpecified) {
      this._settingsType = type;
    } else {
      this._pickedItem = null;
      this._settingsType = SettingsType.None;
    }

    this._isSettingMode = isTypeSpecified;
  }

  /**
   * Установка текущего состояния фильтра по типам классификаторов.
   * @param payload Список типов для установки (именно точный включающий список вместо addedItems/removedItems,
   * как это было для фильтра по типам локаций)
   */
  @Mutation
  public setClassifierTypesFilter(payload: ClassifierTypesFilterPayload) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      const config = pickedItem.config;
      const { classifierRootId, classifierTypes } = payload;

      let byClassifiers = config.byClassifiers;
      let byRoot = byClassifiers?.byRoots?.find((x) => x.rootId === classifierRootId);

      byClassifiers = byClassifiers ?? {
        byRoots: [],
      };
      const byRoots = byClassifiers!.byRoots!;
      if (!byRoot) {
        byRoot = byRoot ?? {
          rootId: classifierRootId,
        };
        byRoots.push(byRoot);
      }

      if (classifierTypes?.length > 0) {
        byRoot!.filterByType = classifierTypes;
      } else {
        byRoot!.filterByType = undefined;
      }

      pickedItem.config.byClassifiers = byClassifiers;
    }
  }

  // Закомментировано, т.к. был баг циклических бесконечных апдейтов. supply#3980
  // /**
  //  * Проверка и при необходимости сброс фильтра по типам классификаторов,
  //  * если выбранные узлы не соответствуют выбранному фильтру по типам узла
  //  */
  // @Mutation
  // public resetClassifierTypesFilter(payload: ClassifierTypesFilterResetPayload) {
  //   const pickedItem = this._pickedItem;
  //   if (pickedItem?.config) {
  //     const config = pickedItem.config;
  //     const { classifierRootId } = payload;
  //     const selectedTreeItems = payload.allTreeItems.filter(x => x.selected);
  //     const byClassifiers = config.byClassifiers;
  //     const byRoot = config.byClassifiers?.byRoots
  //       ?.find(x => x.rootId === classifierRootId);
  //     const filterByType = byRoot?.filterByType;
  //     if (byRoot && filterByType) {
  //       const checkedClassifiers = selectedTreeItems.filter(x => !filterByType.includes(x.type!));
  //       if (checkedClassifiers.length > 0) {
  //         byRoot.filterByType = undefined;
  //         pickedItem.config.byClassifiers = byClassifiers;
  //       }
  //     }
  //   }
  // }

  @Mutation
  public setConfiguringItem(item: PanelBlock) {
    this._pickedItem = {
      panelBlockId: item.id,
      config: item.config ?? {},
      series: item.series,
    };
  }

  @Mutation
  public setSeries(series: IModuleSeries[]) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.series) {
      pickedItem.series = series;
    }
  }

  @Mutation setClassifierSelection(payload: ClassifierSelection) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      const config = pickedItem.config;
      const { allTreeItems, classifierRootId } = payload;

      // Exclusive filter
      const includeItems = allTreeItems?.filter((x) => x.selected && x.dbId) || [];
      let excludeItems = allTreeItems?.filter((x) => !x.selected && x.dbId) || [];
      if (includeItems.length === 0) {
        // All are deselected - interpret this case as deafult state and as the same as all are selected.
        // So, nothing to store as exclusive
        excludeItems = [];
      }

      let byClassifiers = config.byClassifiers;
      let byRoot = byClassifiers?.byRoots?.find((x) => x.rootId === classifierRootId);

      const pickedItemExcludeItems = byRoot?.excludeItems;

      if (excludeItems.length === 0 && pickedItemExcludeItems?.length === 0) {
        // Frequent case with no real changes, do nothing to avoid redundant api-calls
        return;
      }

      byClassifiers = byClassifiers ?? {
        byRoots: [],
      };
      const byRoots = byClassifiers!.byRoots!;

      if (byRoot) {
        byRoot.excludeItems = excludeItems;
      } else {
        byRoot = {
          rootId: classifierRootId,
          excludeItems: excludeItems,
        };
        byRoots.push(byRoot);
      }

      pickedItem.config.byClassifiers = byClassifiers;
    }
  }

  @Mutation
  public setSelectedDuration(payload: ChartPeriodEnum) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      pickedItem.config.period = payload;
    }
  }

  @Mutation
  public updateUnifiedBlockConfig(payload: BlockConfig) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      pickedItem.config.unifiedConfig = payload;
    }
  }

  /** TODO: Проверить, можно ли Удалить по переезду на классификаторы */
  @Mutation
  public setSelectedGraphGroupBy(payload: GraphGroupByDataItem) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      // Do nothing
    }
  }

  @Mutation
  public setSelectedTimeSpans(payload: string[]) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      pickedItem.config.selectedTimeSpans = payload;
    }
  }

  @Mutation
  public setSeparatorName(payload: string) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      if (pickedItem.config.projectIndicatorConfig) {
        pickedItem.config.projectIndicatorConfig.separatorName = payload;
      } else {
        pickedItem.config.projectIndicatorConfig = {
          separatorName: payload,
        };
      }
    }
  }

  @Mutation
  public setSelectedInputTableClassification(payload: InputTableClassificationEnum) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      const inputTableConfig = pickedItem.config.inputTableConfig || {};
      pickedItem.config.inputTableConfig = {
        ...inputTableConfig,
        classificationType: payload,
      };
    }
  }

  @Mutation
  public setSelectedInputTableChildrenSum(payload: InputTableChildrenSumEnum) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      const inputTableConfig = pickedItem.config.inputTableConfig || {};
      pickedItem.config.inputTableConfig = {
        ...inputTableConfig,
        childrenSum: payload,
      };
    }
  }

  @Mutation
  public setIndicatorsSelection(payload: IIndicatorsTreeNode[]) {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      pickedItem.config.indicatorsSelection = payload;
    }
  }

  @Mutation
  public resetFilterAndNavigationConfig() {
    const pickedItem = this._pickedItem;
    if (pickedItem?.config) {
      const byClassifiers = pickedItem.config.byClassifiers;
      if (byClassifiers) {
        byClassifiers.byRoots?.forEach((byRoot) => {
          // Clear an array without recreating its instance.
          byRoot.navigationHierarchy?.splice(0);
          byRoot.excludeItems = undefined;

          // Закомментировано, т.к. был баг циклических бесконечных апдейтов. supply#3980
          // byRoot.filterByType = undefined;
        });
      }
    }
  }
}


import { Options, Vue } from 'vue-class-component';
import InlineSvg from 'vue-inline-svg';

@Options({
  components: {
    InlineSvg,
  },
  props: {
    title: String,
    name: {
      type: String,
      reqired: true,
    },
    defaultStyling: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
})
export default class Icon extends Vue {
  public title?: string;
  public name!: string;
  public defaultStyling!: boolean;
}

import { DeviceState } from '@/store';
import devices from 'devextreme/core/devices';

export function configDevextreme(app: any) {
  devices.on('orientationChanged', () => updateMobileState());

  updateMobileState();

  app.mixin({
    computed: {
      $compactView: () => DeviceState.compactView,
    },
  });
}

function updateMobileState() {
  const orientation = devices.orientation();
  const deviceType = devices.real().deviceType;
  const compactView = deviceType !== 'desktop' && orientation === 'portrait';
  DeviceState.setCompactView(compactView);
}

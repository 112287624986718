import { haveChanges } from '@/helpers';
import { ContextRole, ContextUserSettings, EventToLog, Permission, User } from '@/model';
import api from '@/services/api';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ContextsState } from '.';

@Module({ name: 'user', namespaced: true })
export default class UserModule extends VuexModule {
  private _loading = true;
  private _role: ContextRole | null = null;
  private _user: User | null = null;
  private _settings: ContextUserSettings | null = null;

  public get loading(): boolean {
    return this._loading;
  }

  public get role(): ContextRole | null {
    return this._role;
  }

  public get isAdministrator(): boolean {
    // return this._role?.perms.canDoEverything || false; // TODO: if use `.../built-role` and BuiltContextRole
    return this._role?.isAdministrator || false;
  }

  public get user(): User | null {
    return this._user;
  }

  public get settings() {
    return this._settings;
  }

  @Action async loadUser() {
    this.setLoading(true);
    this.setUser(null);

    try {
      const user = await api.get<User | null>('/api/users/current'); // Логирование входа пользователя в систему (без указания контекста) (на сервере, автоматически)
      this.setUser(user);
    } finally {
      this.setLoading(false);
    }
  }

  @Action({ rawError: true })
  async loadContextRole(contextId: number | null) {
    if (contextId) {
      const role = await api.get<ContextRole>(`/api/contexts/${contextId}/role`);
      this.setRole(role);
    } else {
      this.setRole(null);
    }
  }

  @Mutation
  private setRole(role: ContextRole | null) {
    // TODO: Remove or refactor that code
    if (role) {
      const isAdministrator = role?.abilities?.find(x => x.ability === Permission.DoEverything) != undefined;
      role.isAdministrator = isAdministrator;
    }

    this._role = role;
  }

  // TODO: if use `.../built-role` and BuiltContextRole
  // @Action({ rawError: true })
  // async loadContextRole(contextId: number | null) {
  //   if (contextId) {
  //     const role = await api.get<BuiltContextRole>(`/api/contexts/${contextId}/built-role`);
  //     this.setRole(role);
  //   } else {
  //     this.setRole(null);
  //   }
  // }

  // TODO: if use `.../built-role` and BuiltContextRole
  // @Mutation
  // private setRole(role: BuiltContextRole | null) {
  //   this._role = role;
  // }

  @Action({ rawError: true })
  async loadContextSettings(contextId: number | null) {
    if (contextId) {
      const settings = await api.get<ContextUserSettings>(`/api/contexts/${contextId}/user-settings`);
      this.setSettings(settings);
    } else {
      this.setSettings(null);
    }
  }

  @Action
  async updatePanelsOrder(panels: number[]) {
    if (!haveChanges(this._settings?.panels ?? [], panels)) {
      return;
    }

    this.setSettings({ ...this._settings, panels });
    await this.saveContextSettings(ContextsState.currentContextId);
  }

  @Action({ rawError: true })
  async saveContextSettings(contextId: number | null) {
    if (contextId) {
      await api.post<ContextUserSettings>(`/api/contexts/${contextId}/user-settings`, this._settings);
    }
  }

  @Action
  async trySaveUserLogEvent({ logEvent, contextId }: { logEvent: EventToLog, contextId: number | null }): Promise<boolean> {
    try {
      await this.saveUserLogEvent({ logEvent, contextId });
      return true;
    } catch (errors: any) {
      console.error(`Ошибка логирования действия пользователя: ${errors}`, { logEvent, contextId });
      return false;
    }
  }

  @Action({ rawError: true })
  async saveUserLogEvent({ logEvent, contextId }: { logEvent: EventToLog, contextId: number | null }): Promise<void> {
    if (contextId) {
      await api.post<any>(`/api/contexts/${contextId}/event-log/add`, logEvent);
    } else {
      await api.post<any>(`/api/event-log/add`, logEvent);
    }
  }

  @Mutation
  public setSettings(settings: ContextUserSettings | null) {
    this._settings = settings;
  }

  @Mutation
  private setLoading(value: boolean) {
    this._loading = value;
  }

  @Mutation
  private setUser(user: User | null) {
    this._user = user;
  }
}

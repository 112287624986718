import { createApp } from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import useRouter from '@/services/router';
import store from './store';
import VueApexCharts from 'vue3-apexcharts';
import vuetify from './plugins/vuetify';
import { registerCommonControls } from '@/components/controls';
import { configDevextreme } from './config/dx';
// TODO:DatePicker (Вырезать, когда будет готова версия для Vuetify)
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import '@/styles/tailwind.css';
import '@/styles/vuetify-global-overrides.scss';
import '@fontsource/roboto';

const app = createApp(App).use(store).use(vuetify);

useRouter(app);
configDevextreme(app);
registerCommonControls(app);

app.use(VueApexCharts).component('Datepicker', Datepicker).mount('#app');
